import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit'
import userSlice from './features/userSlice'
import folderHeirarchySlice from './features/folderSlice'
import { folderSliceReducer } from './features/folderSlice'
import transcriptReducerSlice from './features/transcript/transcriptsSlice'
import paragraphSlice from './features/paragraphSlice'
import { transcript } from './features/transcript/transcriptsSlice'
import accountSlice from './features/accountSlice'
import planSlice from './features/planSlice'
import projectSlice from './features/projectSlice'
import inviteSlice from './features/inviteSlice'
import conversationSlice from './features/conversationSlice'
// import thunk, {ThunkMiddleware } from 'redux-thunk'

const appReducer = combineReducers({
  user: userSlice,
  folderHierarchy: folderHeirarchySlice,
  folder: folderSliceReducer,
  transcriptSlice: transcriptReducerSlice,
  paragraphsSlice: paragraphSlice,
  getTranscript: transcript,
  account: accountSlice,
  plan: planSlice,
  project: projectSlice,
  invite: inviteSlice,
  conversations: conversationSlice,
})

// Root reducer to handle logout action
const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'LOGOUT') {
    state = undefined
  }
  return appReducer(state, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default store
