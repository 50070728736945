import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  // Button,
  Input,
  List,
  ListItem,
  ListItemSuffix,
  Textarea,
} from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
// import { AddCircle } from '@material-ui/icons'
// import { CopyIcon, InfoIcon } from '../../SvgIcons'
import Typography from '../../Typography/Typography'
import { useAppSelector } from '../../../store/store'
import { ITranscript } from '../../../interfaces'
import { database } from '../../../firebaseApp'
import { IUser } from '../../../interfaces'
import { formateDuration } from '../../../utils/formateDurati0on'
import RenameSpeaker from '../RenameSpeaker'
import { setEditSpeakerState } from '../../../store/features/transcript/transcriptsSlice'
import { useAppDispatch } from '../../../store/store'
import AddSpeaker from '../AddSpeaker'
import { customEvent } from '../../../utils/customHooks'
import { UpdateSpeakerName } from '../../../store/features/paragraphSlice'
import { roleType } from '../../../enums'

interface ISpeakerData {
  name: string | undefined
  duration: string | undefined
  paragraphIndex?: number | undefined
}

const AboutTranscript = ({
  checkTranscriptRole,
}: {
  checkTranscriptRole: (role: string) => boolean
}) => {
  const [owner, setOwner] = useState<IUser>()
  const [speakersData, setSpeakersData] = useState<ITranscript[] | undefined>(
    undefined,
  )
  const [isSpeakerDialogOpen, setIsSpeakerDialogOpen] = useState<boolean>(false)
  const [isAddSpeakerDialogOpen, setIsAddSpeakerDialogOpen] =
    useState<boolean>(false)
  const [initialValue, setInitialValue] = useState<
    | {
        name: string
        index: number
        paragraphId?: string
      }
    | undefined
  >()

  const { transcript } = useAppSelector((store) => store.getTranscript)
  const paragrapgTranscript = useAppSelector((store) => store.paragraphsSlice)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { currentProject, roles } = useAppSelector((store) => store.project)
  const { user, accountRoles } = useAppSelector((store) => store.user)
  const { paragraphs } = paragrapgTranscript

  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  const transcriptData: ITranscript = transcript

  const {
    name,
    description,
    userId,
    createdAt,
    uploadedData,
    speakerNames,
    id,
  } = transcriptData

  const verifyProjectRole = (roleToSpecify: string) => {
    if (currentProject && currentProject.users && roles) {
      const currentUser = currentProject?.users[user.uid!]
      const projectRole = roles[currentUser?.role]?.[roleToSpecify]
      return projectRole
    }
  }
  const verifyAccountRole = useMemo(() => {
    if (accountRoles && currentAccount) {
      const currentAccountRole = currentAccount.users[user.uid!]
      const accRoles =
        accountRoles[currentAccountRole?.role]?.['accountSettings']
      return accRoles
    }
  }, [accountRoles, currentAccount])
  const mergeDuplicateSpeakers = (array: ITranscript[]) => {
    const speakerMap = {}
    for (const obj of array) {
      if (obj.speaker && obj.startTime !== undefined) {
        if (speakerMap[obj.speaker]) {
          speakerMap[obj.speaker].startTime += obj.startTime
          speakerMap[obj.speaker].duration = formateDuration(
            speakerMap[obj.speaker].startTime / 1000000000,
          )
        } else {
          speakerMap[obj.speaker] = { ...obj }
          speakerMap[obj.speaker].duration = formateDuration(
            obj.startTime / 1e9,
          )
        }
      }
    }
    const resultArray: ITranscript[] = Object.values(speakerMap)
    return resultArray
  }

  const _checkSpeakerssData = () => {
    let dataSpeakers: ITranscript[] = []
    let sidebarSpeakers: ISpeakerData[] = []

    for (const key in speakerNames) {
      sidebarSpeakers.push({ name: key, duration: '0 min 0 sec' })
    }

    if (paragraphs) {
      for (const [i, paragraph] of paragraphs.entries()) {
        const { speaker } = paragraph
        if (paragraphs[i + 1]?.startTime) {
          dataSpeakers.push({
            ...paragraph,
            paragraphIndex: i,
            name: speaker,
            startTime: paragraphs[i + 1]?.startTime - paragraphs[i]?.startTime,
          })
        } else {
          if (uploadedData) {
            const fileSeconds = uploadedData.duration * 1e9
            dataSpeakers.push({
              ...paragraph,
              paragraphIndex: i,
              name: speaker,
              startTime: fileSeconds - paragraphs[i]?.startTime,
            })
          }
        }
      }
    }

    const res = mergeDuplicateSpeakers(dataSpeakers)
    const resultedSpeakers = [...res]

    sidebarSpeakers.forEach((item) => {
      const foundSpeaker = dataSpeakers.find(
        (speaker) => speaker.name === item.name,
      )
      if (!foundSpeaker) {
        resultedSpeakers.push({ name: item.name, duration: '0 min 0 sec' })
      }
    })
    setSpeakersData(resultedSpeakers)
  }

  useEffect(() => {
    _checkSpeakerssData()
  }, [paragraphs, setSpeakersData])

  useEffect(() => {
    if (transcript && userId) {
      getUserDetails()
    }
  }, [userId])

  const getUserDetails = useCallback(async () => {
    const userDoc = await database.collection('users').doc(userId).get()
    if (userDoc.exists) {
      setOwner(userDoc.data() as IUser)
    }
  }, [userId])

  const transcriptDate = useMemo(() => {
    const millisec = createdAt.seconds * 1000
    const date = new Date(millisec)
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date)
    return formattedDate
  }, [createdAt])

  const handleDescription = useCallback(
    (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (checkTranscriptRole(roleType.EDIT)) {
        const data: ITranscript = { ...transcript }
        ev.target.style.height = 'auto'
        ev.target.style.height = ev.target.scrollHeight + 'px'
        data.description = ev.target.value
        if (data?.id) {
          updateDescription(data.id, data)
          customEvent('transcript_description_edited', {
            category: 'transcript',
            transcriptId: data.id,
            user_userId: data.userId,
            actionSource: 'from-sidepanel-info',
            projectId:
              currentProject && currentProject.id ? currentProject.id : '',
          })
        }
      }
    },
    [checkTranscriptRole],
  )

  const handleTitle = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      if (checkTranscriptRole(roleType.EDIT)) {
        // const transcriptId = props.match.params.id
        const data: ITranscript = { ...transcript }
        ev.target.style.height = 'auto'
        ev.target.style.height = ev.target.scrollHeight + 'px'
        data.name = ev.target.value
        if (data?.id) {
          updateDescription(data.id, data)
          customEvent('transcript_renamed', {
            category: 'transcript',
            transcriptId: id,
            user_userId: data.userId,
            accountId: currentAccount.id,
            actionSource: 'from-transc-info',
            projectId:
              currentProject && currentProject.id ? currentProject.id : '',
          })
        }
      }
    },
    [checkTranscriptRole],
  )

  const _handleSpeakerEditDialog = useCallback(
    (name: string | undefined, i: number, paragraphId: string | undefined) =>
      async () => {
        if (checkTranscriptRole(roleType.EDIT) && name) {
          setIsSpeakerDialogOpen(true)
          setInitialValue({ name: name, index: i, paragraphId: paragraphId })
        }
      },
    [setIsSpeakerDialogOpen, checkTranscriptRole, paragraphs],
  )

  const updateTranscriptSpeakers = async (value: string, newValue: string) => {
    const transcriptReference = database.doc(`transcripts/${id}/`)

    const updatedSpeaker = { ...speakerNames }

    for (const key in updatedSpeaker) {
      if (
        Object.hasOwnProperty.call(updatedSpeaker, key) &&
        updatedSpeaker[key] === value
      ) {
        delete updatedSpeaker[key]
        updatedSpeaker[newValue] = newValue
        break
      }
    }

    await transcriptReference.update({
      speakerNames: { ...updatedSpeaker },
    })
  }

  const handleSpeakerEdit = useCallback(
    async (value?: string) => {
      setIsSpeakerDialogOpen(false)
      try {
        if (id && value && initialValue && speakersData && paragraphs) {
          const speakersList = [...speakersData]
          const prev = initialValue?.name

          const index = speakersList?.findIndex((item) => item.name === prev)

          const updatedItem = {
            ...speakersList[index],
            name: value,
            speaker: value,
          }

          speakersList[index] = updatedItem
          setSpeakersData(speakersList)
          console.log(dispatch, setEditSpeakerState)
          await updateTranscriptSpeakers(prev, value)

          if (speakersList[index]?.duration !== '0 min 0 sec') {
            let indexesParagraph: number[] = []
            const indexParagraph = paragraphs?.findIndex(
              (item) => item.id === initialValue?.paragraphId,
            )
            const speakerNameParagraph = paragraphs[indexParagraph].speaker
            paragraphs.forEach((parag, i) => {
              if (parag.speaker === speakerNameParagraph) {
                indexesParagraph.push(i)
              }
            })
            if (indexesParagraph.length) {
              await dispatch(
                setEditSpeakerState({
                  isEdit: true,
                  indexesParagraph,
                  speakerName: value,
                }),
              )
            }
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    [setIsSpeakerDialogOpen, initialValue, setSpeakersData, speakersData],
  )

  const _handleSpeakerAddDialog = useCallback(() => {
    if (checkTranscriptRole(roleType.EDIT)) {
      setIsAddSpeakerDialogOpen(true)
    }
  }, [setIsAddSpeakerDialogOpen, checkTranscriptRole])

  const handleSpeakerAdd = useCallback(
    async (value?: string) => {
      setIsAddSpeakerDialogOpen(false)

      try {
        if (value && id) {
          setSpeakersData((prev) => {
            if (prev === undefined) {
              return [{ name: value, duration: '0 min 0 sec' }]
            } else {
              return [...prev, { name: value, duration: '0 min 0 sec' }]
            }
          })
          UpdateSpeakerName(value, id)
        }
      } catch (error) {
        console.error(error)
      }
    },
    [speakersData],
  )

  const onCloseSpeakerAddModal = useCallback(() => {
    setIsAddSpeakerDialogOpen(false)
  }, [setIsAddSpeakerDialogOpen])

  const onCloseSpeakerModal = useCallback(() => {
    setIsSpeakerDialogOpen(false)
  }, [setIsSpeakerDialogOpen])

  async function updateDescription(id: string, data: any) {
    try {
      const userRef = database.collection(`transcripts`).doc(id)
      await userRef.update({ ...data })
    } catch (error) {
      console.error('error', error)
    }
  }

  return (
    <>
      <div className="px-4 py-3 w-full">
        <h2 className="text-base font-sans font-medium text-blue-gray-900 mb-2">
          {t('infoSidebar.transcriptTitle')}
        </h2>
        <Input
          onChange={handleTitle}
          placeholder="Bruce Lee talkin about something..."
          className=" !border-blue-gray-100 focus:!border-gray-300 placeholder:text-blue-gray-800 placeholder:opacity-100"
          crossOrigin={undefined}
          labelProps={{
            className: 'hidden m-0 p-0',
          }}
          defaultValue={name}
          disabled={
            paragrapgTranscript.projectId == ''
              ? !checkTranscriptRole(roleType.EDIT)
              : !verifyProjectRole('transcript')?.includes(roleType.EDIT)
          }
        />
        <h3 className="text-base font-sans font-medium text-blue-gray-900 my-2">
          {t('infoSidebar.transcriptDescription')}
        </h3>
        <Textarea
          onChange={handleDescription}
          className="!border-blue-gray-100 focus:!border-gray-300  placeholder:text-blue-gray-300 placeholder:opacity-100"
          labelProps={{
            className: 'hidden',
          }}
          placeholder="Lorem ipsum dolor sit amet consectetur. Quis sagittis vitae ac sed pellentesque ipsum quam."
          value={description}
          disabled={
            paragrapgTranscript.projectId == ''
              ? !checkTranscriptRole(roleType.EDIT)
              : !verifyProjectRole('transcript')?.includes(roleType.EDIT)
          }
        />
        <div className="flex flex-row justify-between py-3">
          <h4 className="text-xs font-sans font-medium text-blue-gray-300">
            {t('infoSidebar.createdBy')} : {owner && owner.name}
          </h4>
          <h4 className="text-xs font-sans font-medium text-blue-gray-300">
            {transcriptDate}
          </h4>
        </div>
        <div className="-mx-4 border-b-2" />
        <h2 className="mt-3 font-sans font-bold text-lg text-blue-gray-800">
          {t('infoSidebar.speakers')}
        </h2>
        <List className="-mx-4 text-xs" placeholder={undefined}>
          {speakersData?.map(({ name, duration, id }, i) => {
            return (
              <ListItem
                onClick={_handleSpeakerEditDialog(name, i, id)}
                key={i}
                className="hover:bg-white py-1"
                placeholder={undefined}
              >
                {name}
                <ListItemSuffix placeholder={undefined}>
                  {duration}
                </ListItemSuffix>
              </ListItem>
            )
          })}
        </List>
        {verifyAccountRole?.includes(roleType.ADDCOMMENT) && (
          <Typography
            className="text-sm font-medium text-lstnBlue-500 cursor-pointer text-lstnBlueGray-500 flex items-center justify-start mt-3"
            onClick={_handleSpeakerAddDialog}
          >
            <div className="inline-block justify-center bg-lstnBlueGray-500 border-lstnBlueGray-500 text-white w-3 h-3 p-0 rounded-full text-center relative mr-1">
              <svg width="12" height="12" aria-hidden="true">
                <use xlinkHref="#icon-pluss" />
              </svg>
            </div>
            {t('infoSidebar.addNew')}
          </Typography>
        )}
        {/* <div className="-mx-4 border-b-2" />
        <div className="flex flex-row items-center h-9">
          <h2 className="mt-3 font-sans font-bold text-lg text-blue-gray-800 pr-1">
            AI Summary
          </h2>
          <InfoIcon />
        </div>
        <p className="text-base text-blue-gray-800">
          The video features Barack Obama discussing deepfake technology,
          demonstrating how it can make people appear to say things they
          haven&apos;t. He illustrates this with fabricated statements
          attributed to him, emphasizing the challenge of distinguishing real
          from fake.{' '}
        </p>
        <div className="flex justify-between mt-3">
          <p className="text-xs text-blue-gray-400">Generated By : LSTN AI</p>
          <Button variant="text" className="p-0" placeholder={undefined}>
            <CopyIcon />
          </Button>
        </div> */}
      </div>
      <RenameSpeaker
        initialValue={initialValue}
        open={isSpeakerDialogOpen}
        onSubmit={handleSpeakerEdit}
        onClose={onCloseSpeakerModal}
      />
      <AddSpeaker
        open={isAddSpeakerDialogOpen}
        onSubmit={handleSpeakerAdd}
        onClose={onCloseSpeakerAddModal}
      />
    </>
  )
}

export default AboutTranscript
