import * as React from 'react'
import Typography from '../Typography/Typography'
import { UnderlineTabs } from './Tabs'
interface IProps {
  titleText: string
  children?: React.ReactNode
  userSettings?: string
}

export default function settingsBox({
  titleText,
  children,
  userSettings,
}: IProps): JSX.Element {
  return (
    <div
      className="single-menu-container"
      data-cy="project-settings-containers"
    >
      {userSettings && (
        <>
          <Typography className="text-2xl font-medium text-lstnGray-900">
            {userSettings}
          </Typography>
          <UnderlineTabs />
        </>
      )}
      <Typography className="text-xl font-medium text-lstnGray-900 mb-2">
        <div>{titleText}</div>
      </Typography>
      <div className="settings-container w-[400px] md:w-[800px]">
        {children}
      </div>
    </div>
  )
}
