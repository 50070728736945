import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import firebase from 'firebase/compat/app'
// import ReactGA from "react-ga"
import axios from 'axios'
// import { withRouter, RouteComponentProps } from 'react-router'
import { useNavigate } from 'react-router-dom'

interface IPaymentProps {
  user?: firebase.User
  isEnterprise?: boolean
}
interface PageData {
  url?: string
}

const PaymentScreen = ({ user, isEnterprise }: IPaymentProps) => {
  const [hostedPageData, setHostedPageData] = useState<PageData>()
  const navigate = useNavigate()
  useEffect(() => {
    if (user && user.uid && !hostedPageData) {
      fetchHostedPage()
    }
    if (isEnterprise) {
      navigate('/transcripts')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isEnterprise])

  const fetchHostedPage = async () => {
    const clientId: string = ''
    // ReactGA.ga((tracker) => {
    //   clientId = tracker.get("clientId");
    // })

    if (user) {
      try {
        // @ts-ignore
        const token = await firebase.auth().currentUser.getIdToken()
        const data = await axios.post(
          `${process.env.REACT_APP_GCP}/payment/hostedPage`,
          {
            data: {
              clientId,
              userId: user.uid,
            },
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              'Access-Control-Expose-Headers': 'Content-Disposition',
            },
          },
        )
        if (data.data) {
          setHostedPageData(data.data.response.hosted_page)
        }
      } catch (error) {
        console.error('Error on hosted page function', error)
      }
    }
  }

  return (
    <div className="payment-container">
      {!hostedPageData ? (
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      ) : (
        <iframe src={hostedPageData ? hostedPageData.url : ''} />
      )}
    </div>
  )
}

export default PaymentScreen
