import firebase from 'firebase/compat/app'
import React, { useEffect } from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

// interface IInvitationProceedProps extends RouteComponentProps {}

const InvitationProceed: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const url = location.pathname
    const inviteId = url.substring(url.lastIndexOf('/') + 1)
    const queryParams = new URLSearchParams(location.search)
    const validityCode = queryParams.get('validityCode')
    // get validity code from url param ?validityCode=${invite.validityCode}

    console.log('inviteId', inviteId)
    console.log('validityCode', validityCode)
    if (inviteId && validityCode) {
      localStorage.setItem('inviteId', inviteId)
      localStorage.setItem('inviteValidityCode', validityCode)
    }

    if (!inviteId) {
      navigate('/')
    }

    const checkAuthAndNavigate = async () => {
      const isAuthenticated = await firebase.auth().currentUser
      if (isAuthenticated) {
        navigate('/transcripts')
      } else {
        navigate('/')
      }
    }
    checkAuthAndNavigate()
  }, [history])

  return <div>Proceeding ...</div>
}

export default InvitationProceed
