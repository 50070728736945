import React, { useContext, useEffect, useState } from 'react'
import { RolesContext } from './../../context/ContextProvider'
import UploadButton from '../UploadButton'
import { roleType } from '../../enums'
import { IUserStatus } from '../../interfaces/IUser'
import { IFolders, IUser } from '../../interfaces'

interface UpdateUserData {
  showWarning?: boolean
  // Add other properties as needed
}
interface IProps {
  userStatus: IUserStatus
  userId: string
  userData: IUser
  fileSelected: (file: File) => void
  updateUser: (id: string, data: UpdateUserData) => Promise<void>
  uploadref: React.RefObject<HTMLElement>
  folderData: IFolders | undefined
  checkTranscriptDuration?: () => void
}
type RolesContextType = {
  createTranscript?: string[] | null
  // Define other context values here if needed
}
const UploadButtonCheck = (props: IProps) => {
  const [create, setCreate] = useState(false)
  const { createTranscript }: RolesContextType = useContext(RolesContext)

  useEffect(() => {
    if (
      createTranscript &&
      !(
        createTranscript?.includes(roleType.ALL) ||
        createTranscript?.includes(roleType.CREATE)
      )
    ) {
      setCreate(true)
    }
  }, [createTranscript])
  //@ts-ignore
  return <UploadButton {...props} createTranscript={create} />
}

export default UploadButtonCheck
