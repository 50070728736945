export function list_to_tree(list: any) {
  let map = {},
    node,
    roots = [],
    i

  for (i = 0; i < list.length; i += 1) {
    const originalItem = list[i]
    const copyItem = { ...originalItem, children: [] }
    map[list[i].id] = i // initialize the map
    // list[i].children = [] // initialize the children
    list[i] = copyItem
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i]
    if (node.archive) {
      // Handle archived nodes if needed
    } else {
      if (node.parentFolderId !== '') {
        const parentIndex = map[node.parentFolderId]
        if (parentIndex !== undefined) {
          const parent = list[parentIndex]
          if (parent && parent.children) {
            parent.children.push(node)
          }
        } else {
          roots.push(node)
        }
      } else {
        roots.push(node)
      }
    }
  }
  return roots
}

export const logoutStore = () => {
  return {
    type: 'LOGOUT',
  }
}
