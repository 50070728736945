import React from 'react'

import { formatBytes, formatDuration } from './utils'
import { IPreviewProps, IFileWithMeta } from './Dropzone'

//@ts-ignore
import removeImg from './assets/remove.svg'

import '../../css/uploader.css'
import Progress from '../TranscriptDetails/Progress'

const iconByFn = {
  remove: { backgroundImage: `url(${removeImg})` },
}

interface IProps {
  renderDrop: (file: IFileWithMeta) => JSX.Element
}

const Preview: React.FC<IPreviewProps & IProps> = ({
  className,
  imageClassName,
  style,
  imageStyle,
  fileWithMeta: { remove },
  fileWithMeta,
  meta: {
    name = '',
    percent = 0,
    size = 0,
    previewUrl,
    status,
    duration,
    validationError,
  },
  isUpload,
  // canCancel,
  canRemove,
  // canRestart,
  extra: { minSizeBytes },
  renderDrop,
}) => {
  // let title = `${name || '?'}, ${formatBytes(size)}`
  // if (duration) title = `${title}, ${formatDuration(duration)}`
  const BytesAndDuration = `${formatBytes(size)}, ${
    duration ? formatDuration(duration) : ''
  }`
  if (status === 'error_file_size' || status === 'error_validation') {
    return (
      <div className={className} style={style}>
        <span className="dzu-previewFileNameError">
          <span className="text-black">{name || '?'}</span>{' '}
          <span className="text-blue-gray-400">{`(${BytesAndDuration})`}</span>
        </span>
        {status === 'error_file_size' && (
          <span>{size < minSizeBytes ? 'File too small' : 'File too big'}</span>
        )}
        {status === 'error_validation' && (
          <span>{String(validationError)}</span>
        )}
        {canRemove && (
          <span
            className="dzu-previewButton"
            style={iconByFn.remove}
            onClick={remove}
          />
        )}
      </div>
    )
  }

  let errorType
  if (status === 'error_upload_params' || status === 'exception_upload') {
    errorType = `failed`
  } else if (status === 'error_upload' || status === 'rejected_file_type') {
    errorType = 'file not supported'
  }
  if (typeof size === 'number' && size === 0) {
    errorType = `file is empty`
  }
  if (status === 'aborted') errorType = `cancelled`
  return (
    <div className={`${className} flex-wrap gap-5 px-7 py-3`} style={style}>
      {previewUrl && (
        <img
          className={imageClassName}
          style={imageStyle}
          src={previewUrl}
          alt={name || ''}
          title={name || ''}
        />
      )}
      {!previewUrl && (
        <span className="dzu-previewFileName text-sm font-normal">
          <span className="text-black">{name || '?'}</span>{' '}
          <span className="text-blue-gray-400">{`(${BytesAndDuration})`}</span>
          {errorType && (
            <span style={{ color: 'red' }}> (upload error: {errorType})</span>
          )}
        </span>
      )}

      <div className="dzu-previewStatusContainer flex-wrap gap-2">
        {renderDrop(fileWithMeta)}
        <div className="flex items-center">
          {isUpload && (
            <Progress
              value={
                status === 'done' || status === 'headers_received'
                  ? 100
                  : Number(percent)
              }
              color="green"
              variant="filled"
              size="sm"
              className="uploader-preview-progress w-32 border border-blue-gray-50"
            />
          )}
          {status !== 'preparing' &&
            status !== 'getting_upload_params' &&
            status !== 'uploading' &&
            canRemove && (
              <span
                data-cy="data-project-upload-cancel"
                className="dzu-previewButton"
                style={iconByFn.remove}
                onClick={remove}
              />
            )}
        </div>
      </div>
    </div>
  )
}

export default Preview
