import React, { ChangeEventHandler, useEffect, useState } from 'react'
import '../css/Settings.css'
import { useTranslation } from 'react-i18next'
import firebase from 'firebase/compat/app'
import SplashScreen from '../components/SplashScreen'
import { useAppSelector, useAppDispatch } from '../store/store'
import { customEvent } from '../utils/customHooks'
import 'firebase/compat/auth'
import Typography from '../components/Typography/Typography'
import { Input } from '@material-tailwind/react'
import Button from '../components/Button/TailwindButton'
import { ThemeProvider } from '@material-tailwind/react'
import UserProfileAvatar from '../components/UserProfileAvatar'
import ImagePlaceholderSkeleton from '../components/ImagePlaceholderSkeleton'
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react'
import {
  updateAccountDescription,
  updateAccountLogo,
  updateAccountName,
} from '../store/features/accountSlice'
import AvatarLogo from '../icons/AccountLogo.svg'
import UpgradeModal from '../components/UpgradeModal'
import DialogComponent from '../components/Dialog'
import { functionsV2 } from '../firebaseApp'
// import Selector from '../components/Account/Select'
// import { fetchAccounts } from '../store/features/accountSlice'
import MembersTable from '../components/Account/Table'
import NoAccess from '../components/NoAccess/NoAccess'
import { IPlanData } from '../store/features/planSlice'
import { IStripePrice } from '../interfaces'
import { fetchInvites } from '../store/features/inviteSlice'
import { roleType } from '../enums'

interface IStateProps {
  user: firebase.UserInfo
}

interface Payload {
  accountId: string
  returnUrl: string
}
interface ICurrentPlan {
  name: string
  prices: IStripePrice[]
  planID?: string
}

const AccountSettings = ({ user }: IStateProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [UpgradeOpen, setUpgradeOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [currentPlan, setCurrentPlan] = useState<ICurrentPlan>()
  const [isBtn, setIsBtn] = useState(false)

  const { userData } = useAppSelector((store) => store.user)
  const { currentAccount, members } = useAppSelector((store) => store.account)
  const { plans } = useAppSelector((store) => store.plan)
  const { invites } = useAppSelector((store) => store.invite)
  const pendingFilter = invites.filter((invite) => invite.status === 'pending')
  // const uniquePendingInvites = [
  //   ...new Map(pendingFilter.map((invite) => [invite.email, invite])).values(),
  // ]
  const dispatch = useAppDispatch()
  const [rolesValue, setRolesValue] = useState<string[]>([])
  const { accountRoles } = useAppSelector((store) => store.user)
  const currentAccountRole = currentAccount.users[user.uid!]
  useEffect(() => {
    if (accountRoles) {
      setRolesValue(accountRoles[currentAccountRole?.role]?.['accountSettings'])
    }
  }, [accountRoles, currentAccountRole])
  const checkRole = (role: string) => rolesValue?.includes(role)
  const verifyAccountRole = (roleToSpecify: string) => {
    if (accountRoles && currentAccount) {
      const currentAccountRole = currentAccount.users[user.uid!]
      const accRoles = accountRoles[currentAccountRole?.role]?.[roleToSpecify]
      return accRoles
    }
  }
  useEffect(() => {
    if (currentAccount) {
      dispatch(fetchInvites({ dispatch, id: currentAccount.id }))
    }
  }, [currentAccount, dispatch])
  const handlePictureChange = async (event: {
    target: { files: File[] }
  }): Promise<void> => {
    setIsLoading(true)
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = async () => {
      const accountLogo = reader.result
      await updateAccountLogo(currentAccount.id, accountLogo)
    }
    reader.readAsDataURL(file)
    customEvent('account_info_avatar_uploaded', {
      accountId: currentAccount.id,
      user_userId: user.uid,
    })
    setIsLoading(false)
  }
  useEffect(() => {
    setName(currentAccount.name)
    setDescription(currentAccount.description)
  }, [currentAccount.name, currentAccount.description])

  const userAccount = currentAccount.users
    ? currentAccount.users[String(userData.id)]
    : null

  const { t } = useTranslation()

  const handleUploadButtonClick = (): void => {
    document.getElementById('contained-button-file')?.click()
  }
  // console.log('Accounts Id', currentAccount.id)

  const handleAccountName = async (
    event: React.ChangeEvent<{ value: string }>,
  ): Promise<void> => {
    const accountName: string = event.target.value
    if (currentAccount.id) {
      await updateAccountName(currentAccount.id, accountName)
      customEvent('account_info_title_edited', {
        accountId: currentAccount.id,
        user_userId: user.uid,
      })
    }
  }

  const handleAccountDescription = async (
    event: React.ChangeEvent<{ value: string }>,
  ): Promise<void> => {
    const accountDescription: string = event.target.value
    if (currentAccount.id) {
      await updateAccountDescription(currentAccount.id, accountDescription)
      customEvent('account_info_description_edited', {
        accountId: currentAccount.id,
        user_userId: user.uid,
      })
    }
  }
  const handleOpen = (): void => setOpen(!open)
  const handleUpgrade = (): void => {
    if (!UpgradeOpen) {
      customEvent('billing_manage_sub_opened', {
        category: 'accounts',
        accountId: currentAccount.id,
        user_userId: user.uid,
        planId: currentPlan?.planID,
      })
    }
    setUpgradeOpen(!UpgradeOpen)
  }
  useEffect(() => {
    if (plans.length > 0 && currentAccount) {
      plans?.map((plan: IPlanData) => {
        if (
          currentAccount &&
          currentAccount?.stripeSubscriptionData?.plan &&
          currentAccount?.stripeSubscriptionData?.plan?.product === plan.planID
        ) {
          setCurrentPlan(plan)
        }
      })
    }
  }, [plans, currentAccount])
  const handlePortal = async (): Promise<void> => {
    try {
      setIsBtn(true)
      const payload: Payload = {
        accountId: currentAccount.id,
        returnUrl: window.location.origin + '/transcripts',
      }
      const getCustomerPortalSessionUrl = functionsV2.httpsCallable(
        'getCustomerPortalSessionUrl',
      )
      const response = await getCustomerPortalSessionUrl(payload)
      const url: string = response.data.url
      window.location.href = url
      setIsBtn(false)
    } catch (error) {
      console.error(error)
    }
  }

  const queryParams = new URLSearchParams(location.search)
  const tab = queryParams.get('tab')

  const [activeTab, setActiveTab] = React.useState(tab || 'general')

  const data = [
    {
      label: 'General',
      value: 'general',
      desc: checkRole(roleType.VIEW) && (
        <>
          <Typography className="text-xl font-medium text-lstnGray-900 mb-3">
            {t('accountSettings.info')}
          </Typography>
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-[400px] md:w-[880px] h-auto mx-auto mt-1 pb-8">
            <div className="w-full md:w-1/2">
              <div className="max-w-sm">
                <Typography className="text-base font-medium text-lstnBlueGray-900 mb-2">
                  {t && t('accountSettings.name')}
                </Typography>
                <Input
                  id="standard-basic"
                  className="!border !border-blue-gray-100 bg-white text-blue-gray-900 placeholder:text-blue-gray-500 focus:!border-blue-gray-800 focus:!border-t-blue-gray-900 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  placeholder="Bruce Lee"
                  defaultValue={name}
                  onChange={handleAccountName}
                  disabled={!checkRole(roleType.EDIT)}
                  variant="outlined"
                  crossOrigin={undefined}
                />
              </div>
              <div className="mt-4 max-w-sm">
                <div className="flex justify-between mb-2 items-center">
                  <Typography className="text-base font-medium text-lstnBlueGray-900 mb-0">
                    {t('accountSettings.description')}
                  </Typography>
                </div>
                <Input
                  id="standard-basic"
                  className="!border !border-blue-gray-100 bg-white text-blue-gray-900 placeholder:text-blue-gray-500 focus:!border-blue-gray-800 focus:!border-t-blue-gray-900 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  placeholder={t && t('accountSettings.descriptionPlaceholder')}
                  defaultValue={description}
                  onChange={handleAccountDescription}
                  disabled={!checkRole(roleType.EDIT)}
                  variant="outlined"
                  crossOrigin={undefined}
                />
              </div>
              {checkRole(roleType.MANAGEBILLING) && (
                <div className="mt-8">
                  <div className="mb-2">
                    <Typography className="text-xl font-medium text-lstnBlueGray-900 mb-3">
                      {t && t('accountSettings.billingPreferences')}
                    </Typography>
                    <Typography className="text-sm font-normal text-lstnBlueGray-900 mb-4">
                      {t('accountSettings.plan', { plan: currentPlan?.name })}
                    </Typography>
                  </div>
                  {currentPlan && currentPlan?.name === 'Free' ? (
                    <Button
                      variant="filled"
                      size="md"
                      onClick={handleUpgrade}
                      loading={UpgradeOpen}
                      className="bg-lstnGreen-500 uppercase text-xs font-bold text-white shadow-green-500/20 py-2.5 px-4 rounded-lg"
                    >
                      {t && t('accountSettings.upgrade')}
                    </Button>
                  ) : (currentPlan &&
                      currentPlan?.name === 'Enterprise' &&
                      currentPlan?.prices[0]?.unit_amount == 0) ||
                    (currentAccount && currentAccount?.features?.enterprise) ? (
                    <Button
                      variant="filled"
                      size="md"
                      onClick={handleOpen}
                      loading={open}
                      className="bg-lstnGreen-500 uppercase text-xs font-bold text-white shadow-green-500/20 py-2.5 px-4 rounded-lg"
                    >
                      {t && t('accountSettings.scheduleAcall')}
                    </Button>
                  ) : (
                    <Button
                      variant="filled"
                      size="md"
                      onClick={handlePortal}
                      loading={isBtn}
                      className="bg-lstnGreen-500 uppercase text-xs font-bold text-white shadow-green-500/20 py-2.5 px-4 rounded-lg"
                    >
                      {t && t('accountSettings.manageBilling')}
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className="flex-1 w-full md:w-2/5 md:pl-6">
              <Typography className="text-base font-medium text-lstnBlueGray-900 mb-2">
                {t('accountSettings.logo')}
              </Typography>
              <div className="mb-3">
                {!currentAccount ? (
                  <div style={{ width: '74px', height: '74px' }}>
                    <ImagePlaceholderSkeleton />
                  </div>
                ) : !currentAccount.accountLogo ? (
                  <img src={AvatarLogo} width="74px" height="74px" />
                ) : (
                  <UserProfileAvatar
                    userName={
                      currentAccount.name ? currentAccount.name : 'Account Logo'
                    }
                    avatarUrl={String(currentAccount.accountLogo)}
                    width="74px"
                    height="74px"
                    font="44px"
                  />
                )}
              </div>
              <input
                accept="image/*"
                id="contained-button-file"
                type="file"
                hidden
                disabled={!checkRole(roleType.EDIT)}
                onChange={
                  handlePictureChange as unknown as ChangeEventHandler<HTMLInputElement>
                }
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={handleUploadButtonClick}
                  loading={isLoading ? isLoading : !userData}
                >
                  {t('uploadButton.uploadAvatar')}
                </Button>
              </label>
            </div>
          </div>
        </>
      ),
    },
    {
      label: 'Users',
      value: 'members',
      desc: verifyAccountRole('member')?.includes(roleType.VIEW) && (
        <div className="flex flex-col min-w-[400px] md:w-[880px] h-auto mx-auto mt-1 pb-8">
          <div className="w-full">
            <div>
              <MembersTable
                members={[...members, ...pendingFilter]}
                currentAccount={currentAccount}
                user={user}
              />
            </div>
          </div>
        </div>
      ),
    },
  ]

  return (
    <ThemeProvider>
      {userData && userData.email ? (
        <div
          className="flex flex-col mt-6 pl-4 sm:pl-0 single-menu-container justify-center items-center"
          data-cy="project-settings-page"
        >
          <div className="w-[400px] md:w-[880px]">
            <Typography className="text-2xl font-medium text-lstnGray-900 my-5">
              {t('accountSettings.title')}
            </Typography>
          </div>
          {userAccount?.role === 'owner' || userAccount?.role === 'admin' ? (
            <Tabs value={activeTab} className="w-[400px] md:w-[880px]">
              <TabsHeader
                placeholder={undefined}
                className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
                indicatorProps={{
                  className:
                    'bg-transparent border-b-2 border-lstnBlueGray-500 shadow-none rounded-none my-3',
                }}
              >
                {data.map(({ label, value }) => (
                  <Tab
                    placeholder={undefined}
                    key={value}
                    value={value}
                    onClick={() => setActiveTab(value)}
                    className="text-lstnBlueGray-500 my-3 mt-8 w-fit px-3"
                  >
                    {label}
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody placeholder={undefined}>
                {data.map(({ value, desc }) => (
                  <TabPanel className="p-0 mt-8" key={value} value={value}>
                    {desc}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
          ) : (
            <NoAccess />
          )}
        </div>
      ) : (
        <SplashScreen />
      )}
      <DialogComponent open={open} handleClose={handleOpen} />
      <UpgradeModal UpgradeOpen={UpgradeOpen} handleUpgrade={handleUpgrade} />
    </ThemeProvider>
  )
}
export default AccountSettings
