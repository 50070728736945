import React from 'react'
import NoAccess from '../../icons/NoAccess.svg'
import { Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'

const NoAccessPage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col h-96 justify-center items-center border border-lstnBlueGray-50 rounded-md mt-16">
      <img src={NoAccess} alt="No access" />
      <Typography
        placeholder={undefined}
        className="text-2xl font-medium mb-4 mt-8"
      >
        {t('accountSettings.noAccess')}
      </Typography>
      <Typography
        placeholder={undefined}
        className="text-base font-normal text-lstnBlueGray-500 px-16 text-center"
      >
        {t('accountSettings.missingPermission')}
      </Typography>
      <Typography
        placeholder={undefined}
        className="text-base font-normal text-lstnBlueGray-500 px-16 text-center"
      >
        {t('accountSettings.contactOwner')}
      </Typography>
    </div>
  )
}

export default NoAccessPage
