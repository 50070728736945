import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Card,
  CardBody,
  CardFooter,
  Button,
  Typography,
  Chip,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { setCurrentProject, addMember } from '../../store/features/projectSlice'
import { IProject } from '../../interfaces'
import Toast from '../../components/Toast/Toast'
import UserProfileAvatar from '../UserProfileAvatar'
import AddDialog from './AddDialog'
import { useNavigate } from 'react-router-dom'
import CustomTooltip from '../Tooltip/Tooltip'
import ArchiveConfirmDialog from './ArchiveConfirmDialog'
import { customEvent } from '../../utils/customHooks'
// import { roleType } from '../../enums'

interface IProps {
  project: IProject
  handleEdit: (id: string, name: string, description?: string) => void
  handleArchive: (id: string) => void
  roles: { [key: string]: string[] }
}

export default function ProjectCard({
  project,
  handleEdit,
  handleArchive,
  roles,
}: IProps): React.JSX.Element {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { members, currentAccount } = useAppSelector((store) => store.account)
  const { user, userData } = useAppSelector((store) => store.user)
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false)
  const [projectSettingRole, setProjectSettingRole] = useState<string[]>([])
  const [projectMemberRole, setProjectMemberRole] = useState<string[]>([])

  // const userAccount = currentAccount.users
  //   ? currentAccount.users[String(userData.id)]
  //   : null

  const navigate = useNavigate()

  const handleOpenAdd = (): void => {
    setOpenAddDialog(!openAddDialog)
  }

  const handleOpenArchive = (): void => {
    setOpenArchiveDialog(!openArchiveDialog)
  }

  const filteredMembers = members.filter((member) => {
    return project.users[member.id!]
  })

  const detailedFilteredMembers = filteredMembers.map((member) => {
    return {
      id: member.id!,
      email: member.email!,
      name: member.name!,
      role: project.users[member.id!].role,
    }
  })

  const unfilteredMembers = members.filter((member) => {
    return !project.users[member.id!]
  })

  useEffect(() => {
    const currentUser = project.users[user.uid!]
    const settingRole = roles[currentUser.role]?.['projectSetting']
    const memberRole = roles[currentUser.role]?.['member']
    setProjectSettingRole(settingRole)
    setProjectMemberRole(memberRole)
  }, [project, roles, user])

  const handleCopy = (id: string): void => {
    const domain = window.location.origin
    navigator.clipboard.writeText(`${domain}/project/${id}`)
    Toast({ text: t && t('transcripts.copyConfirm'), variant: 'success' })
    customEvent('project_link_copied', {
      accountId: currentAccount.id,
      projectId: project.id,
      actionSource: 'project-card',
      user_userId: userData.id,
    })
  }

  const handleAddMember = async (
    projectId: string,
    memberId: string,
    role: string,
  ): Promise<void> => {
    await addMember(projectId, memberId, role)
    customEvent('project_collaborator_invited', {
      accountId: currentAccount.id,
      projectId: projectId,
      userRole: role,
      user_userId: userData.id,
    })
  }

  return (
    <>
      <Card
        placeholder={undefined}
        className="mt-7 w-[30rem] border h-64 hover:shadow-lg"
        onClick={() => {
          dispatch(setCurrentProject(project))
          navigate(`/project/${project.id}`)
        }}
      >
        <CardBody placeholder={undefined} className="h-44">
          <div className="flex justify-between items-center">
            <Typography
              placeholder="Title"
              className="text-2xl text-blue-gray-900 font-medium"
            >
              {project.name}
            </Typography>
            {/* {projectSettingRole?.includes(roleType.VIEW) && ( */}
            <Menu placement="bottom-end" allowHover={true}>
              <MenuHandler>
                <Button
                  placeholder={undefined}
                  variant="text"
                  className="p-2 min-w-0 h-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-more-vertical"
                  >
                    <circle cx="12" cy="11" r="1"></circle>
                    <circle cx="12" cy="6" r="1"></circle>
                    <circle cx="12" cy="16" r="1"></circle>
                  </svg>
                </Button>
              </MenuHandler>
              <MenuList placeholder={undefined}>
                {projectSettingRole?.includes('edit') && (
                  <MenuItem
                    placeholder={undefined}
                    onClick={(event) => {
                      event.stopPropagation()
                      handleEdit(project.id, project.name, project.description)
                    }}
                  >
                    <Typography
                      placeholder="Edit"
                      className="text-lstnBlueGray-500 font-medium text-sm"
                    >
                      {t('projects.edit')}
                    </Typography>
                  </MenuItem>
                )}
                {projectSettingRole?.includes('archive') &&
                  project.status !== 'archived' && (
                    <MenuItem
                      placeholder={undefined}
                      onClick={(event) => {
                        event.stopPropagation()
                        handleOpenArchive()
                      }}
                    >
                      <Typography
                        placeholder="Archive"
                        className="text-lstnBlueGray-500 font-medium text-sm"
                      >
                        {t('projects.archive')}
                      </Typography>
                    </MenuItem>
                  )}
                <MenuItem
                  placeholder={undefined}
                  onClick={(event) => {
                    event.stopPropagation()
                    handleCopy(project.id)
                  }}
                >
                  <Typography
                    placeholder="CopyLink"
                    className="text-lstnBlueGray-500 font-medium text-sm"
                  >
                    {t('projects.copyLink')}
                  </Typography>
                </MenuItem>
              </MenuList>
            </Menu>
            {/* )} */}
          </div>
          <div className="flex gap-2 items-center mt-3 mb-3">
            {project.status === 'active' ? (
              <Chip
                value={t('projects.active')}
                variant="outlined"
                size="sm"
                className="text-xs text-blue-gray-900"
              />
            ) : project.status === 'archived' ? (
              <Chip
                value={t('projects.archived')}
                variant="outlined"
                size="sm"
                className="text-xs text-blue-gray-400 border-blue-gray-300"
              />
            ) : null}
          </div>
          <Typography
            placeholder="Add Description"
            className="text-base font-normal text-lstnBlueGray-500 text-start truncate-lines"
          >
            {project.description}
          </Typography>
        </CardBody>
        <CardFooter placeholder={undefined} className="py-0">
          <div className="flex">
            <div className="flex items-center -space-x-4">
              {filteredMembers.map((member) => {
                if (member.profilePicture) {
                  return (
                    <CustomTooltip
                      key={member.id}
                      content={
                        <div className="items-center justify-center flex flex-col">
                          <Typography
                            color="white"
                            className="font-medium"
                            placeholder={undefined}
                          >
                            {member.name}
                          </Typography>
                          <Typography
                            variant="small"
                            color="white"
                            className="font-normal opacity-80"
                            placeholder={undefined}
                          >
                            {member.email}
                          </Typography>
                        </div>
                      }
                      className="bg-lstnBlueGray-400"
                    >
                      <Avatar
                        key={member.id}
                        placeholder={undefined}
                        variant="circular"
                        alt={member.name}
                        className="ring-2 ring-white hover:z-10 focus:z-10"
                        src={member.profilePicture}
                      />
                    </CustomTooltip>
                  )
                } else {
                  return (
                    <CustomTooltip
                      key={member.id}
                      content={
                        <div className="items-center justify-center flex flex-col">
                          <Typography
                            color="white"
                            className="font-medium"
                            placeholder={undefined}
                          >
                            {member.name}
                          </Typography>
                          <Typography
                            variant="small"
                            color="white"
                            className="font-normal opacity-80"
                            placeholder={undefined}
                          >
                            {member.email}
                          </Typography>
                        </div>
                      }
                      className="bg-lstnBlueGray-400"
                    >
                      <div
                        key={member.id}
                        className="ring-2 ring-white hover:z-10 focus:z-10 rounded-full"
                      >
                        <UserProfileAvatar
                          key={member.id}
                          userName={member.name!}
                          avatarUrl={member.profilePicture}
                          width="48px"
                          height="48px"
                        />
                      </div>
                    </CustomTooltip>
                  )
                }
              })}
            </div>
            {projectMemberRole?.includes('add') && (
              <Typography
                className="text-sm font-medium text-lstnBlue-500 cursor-pointer text-lstnBlueGray-900 flex items-center justify-start"
                placeholder="Add New"
                onClick={(event) => {
                  event.stopPropagation()
                  handleOpenAdd()
                }}
              >
                <div className="inline-block justify-center ml-5 bg-lstnBlueGray-500 border-lstnBlueGray-500 text-white w-3 h-3 p-0 rounded-full text-center relative mr-1">
                  <svg width="12" height="12" aria-hidden="true">
                    <use xlinkHref="#icon-pluss" />
                  </svg>
                </div>
                {t('projects.addNew')}
              </Typography>
            )}
          </div>
        </CardFooter>
      </Card>
      <AddDialog
        open={openAddDialog}
        handleOpen={handleOpenAdd}
        project={project}
        members={unfilteredMembers}
        joinedMembers={detailedFilteredMembers}
        addMember={handleAddMember}
        source="project-card"
        userId={userData.id!}
      />
      <ArchiveConfirmDialog
        projectName={project.name}
        projectId={project.id}
        handleArchive={handleArchive}
        handleOpenArchive={handleOpenArchive}
        openArchiveDialog={openArchiveDialog}
        accountId={project.accountId}
        source="project-card"
        userId={userData.id!}
      />
    </>
  )
}
