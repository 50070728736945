import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TourGuide from './UploadScreenTour'
import '../../css/OnBoarding.css'
import { tourDialogTheme } from '../../theme/lstn-theme-basic'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

interface IProps {
  open: boolean
  close: () => Promise<void>
}

export default function AlertDialog({ open, close }: IProps) {
  const [openTourGuide, setOpenTourGuide] = React.useState(false)
  const { t } = useTranslation()

  const handleOpenTour = () => {
    setOpenTourGuide(true)
  }

  const handleCloseTour = async () => {
    await close()
    setOpenTourGuide(false)
  }

  if (openTourGuide) {
    return (
      <TourGuide
        openTourGuide={openTourGuide}
        handleCloseTour={handleCloseTour}
      />
    )
  }

  return (
    <div className="start-tour-dialog">
      <Dialog open={open} onClose={close}>
        <MuiThemeProvider theme={tourDialogTheme}>
          <DialogTitle className="onBoarding-dialog-title">
            {t('startTourDialog.dialogTitle')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className="onBoarding-dialog-description">
              <div>{t('startTourDialog.dialogContent')}</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="onBoarding-dialog-actions">
            <Button onClick={close} color="primary">
              {t('startTourDialog.cancel')}
            </Button>
            <Button
              className="tour-start-btn"
              onClick={handleOpenTour}
              color="primary"
            >
              {t('startTourDialog.start')}
            </Button>
          </DialogActions>
        </MuiThemeProvider>
      </Dialog>
    </div>
  )
}
