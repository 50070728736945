import React from 'react'
// import { makeStyles } from "@material-ui/core/styles"
import { Tooltip } from '@material-tailwind/react'
import type { TooltipProps } from '@material-tailwind/react'

interface IProps {
  // children: React.ReactChildren | React.ReactElement
}

function CustomTooltip(props: IProps & TooltipProps) {
  // const { color, ...other } = props // use others instead in Button props so that we preserve the prop and modify the behaviour
  return <Tooltip {...props} />
}

export default CustomTooltip
