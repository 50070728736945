import React from 'react'
import { Dialog, DialogBody } from '@material-tailwind/react'
import TidycalIframe from '../TidyCal'

interface DialogComponentProps {
  open: boolean
  handleClose: () => void
}

const DialogComponent: React.FC<DialogComponentProps> = ({
  open,
  handleClose,
}) => (
  <Dialog open={open} handler={handleClose} size={'xl'} placeholder={undefined}>
    <DialogBody placeholder={undefined}>
      <TidycalIframe />
    </DialogBody>
  </Dialog>
)

export default DialogComponent
