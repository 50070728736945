import React, { Fragment } from 'react'
// import { MTableToolbar } from 'material-table';
// import { Delete } from '@material-ui/icons';
import FolderMove from '../../icons/FolderMove.comp.svg'
import ExportMenu, { ExportValues } from '../ExportMenu/ExportMenu'
import shareIcon from '../../icons/share.svg'
import renameIcon from '../../icons/rename.svg'
import searchIcon from '../../icons/search.svg'
import renameColor from '../../icons/renameColor.png'
import shareColor from '../../icons/shareColor.png'
import delColor from '../../icons/delColor.svg'
import del from '../../icons/del.png'
import { ITranscriptRow, IUserFeatures } from '../../interfaces'
// import { IUserStatusState } from '../../store/reducers/userStatus'
// import PopupContainer from "../SharedList/PopupContainer";
import { roleType } from '../../enums'
import { IconButton, Input } from '@material-tailwind/react'
import { CreateNewFolder } from '@material-ui/icons'
// import ShareTranscript from "../ShareFile/ShareTranscript";
import CustomTooltip from '../Tooltip/Tooltip'
import { useLocation } from 'react-router-dom'
import { IUserStatus } from '../../interfaces/IUser'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../store/store'

interface IProps {
  checkedRows: boolean
  checked: boolean
  onDelete: () => void
  onExport: (exportData: ExportValues) => void
  onRename: () => void
  onNewFolder: () => void
  userStatus: { userStatus: IUserStatus }
  transcriptIds: string[]
  userFeatures?: IUserFeatures
  handleMoveFolder: () => void
  checkedRowsData: ITranscriptRow[]
  getItemsIds: (folderId: string) => void
  userId: string
  getFolderHeirarchy: (userId: string) => void
  openPopup?: (data: ITranscriptRow[]) => void
  edit?: boolean
  move?: boolean
  deleteCheck?: boolean
  share?: boolean
  folderCreateRole?: string[]
  handleSearchChange: () => void
  transcriptRoles?: string[]
}

const ActionPanelItems = ({
  checkedRows,
  checked,
  onDelete,
  onExport,
  transcriptIds,
  onRename,
  onNewFolder,
  handleMoveFolder,
  checkedRowsData,
  userStatus: { userStatus },
  userFeatures,
  edit,
  move,
  deleteCheck,
  share,
  folderCreateRole,
  openPopup,
  handleSearchChange,
  transcriptRoles,
}: IProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { currentProject, roles } = useAppSelector((store) => store.project)
  const { user } = useAppSelector((store) => store.user)
  const verifyProjectRole = (roleToSpecify: string) => {
    if (Object.keys(currentProject).length > 0 && roles) {
      const currentUser = currentProject?.users[user.uid!]
      const projectRole = roles[currentUser?.role]?.[roleToSpecify]
      return projectRole
    }
  }

  return (
    <div
      className={`transcripts-action-items-container mt-2 ${
        checked ? 'transcripts-action-items-highlighted' : ''
      }`}
    >
      <div className="transcripts-action-items-btns space-x-2 items-center">
        {!location.pathname.includes('/project/') && (
          <Fragment>
            {checkedRowsData.length > 0 && share ? (
              <Fragment>
                <CustomTooltip
                  content={t('folder.share')}
                  className="bg-lstnBlueGray-400"
                >
                  {/* @ts-ignore */}
                  <IconButton
                    size="md"
                    variant="outlined"
                    onClick={() => openPopup && openPopup(checkedRowsData)}
                    data-cy="data-project-share"
                  >
                    <img src={shareColor} className="object-cover" alt="icon" />
                  </IconButton>
                </CustomTooltip>
                {/* <PopupContainer {...{folderId:checkedRowsData[0] ? checkedRowsData[0].id : '', getItemsIds:getItemsIds, getFolderHeirarchy, userId,rowsData:checkedRowsData}} /> */}
              </Fragment>
            ) : (
              //  @ts-ignore
              <IconButton
                size="md"
                variant="outlined"
                className="border border-blue-gray-300"
                disabled
              >
                <img src={shareIcon} alt="icon" className="object-cover" />
              </IconButton>
            )}
          </Fragment>
        )}

        <Fragment>
          {checkedRows || checked ? (
            (location.pathname.includes('/project/') &&
              verifyProjectRole('transcript').includes(roleType.MOVE)) ||
            !location.pathname.includes('/project/') ? (
              <CustomTooltip
                content={t('folder.moveTooltip')}
                className="bg-lstnBlueGray-400"
              >
                {/* @ts-ignore */}
                <IconButton
                  size="md"
                  variant="outlined"
                  onClick={handleMoveFolder}
                  disabled={!move}
                  data-cy="data-project-move"
                >
                  <img src={FolderMove} width={18} alt="img" />
                </IconButton>
              </CustomTooltip>
            ) : null
          ) : (location.pathname.includes('/project/') &&
              verifyProjectRole('folder').includes(roleType.CREATE)) ||
            !location.pathname.includes('/project/') ? (
            <CustomTooltip
              content={t('folder.title')}
              className="bg-lstnBlueGray-400"
            >
              {/* @ts-ignore */}
              <IconButton
                size="md"
                variant="outlined"
                onClick={onNewFolder}
                disabled={
                  !folderCreateRole?.includes(roleType.CREATE) &&
                  !verifyProjectRole('transcript')?.includes(roleType.FILEEDIT)
                }
                data-cy="data-project-create-folder"
              >
                {/* <img src={folderIcon} width={18} alt="img" style={{ filter: ' invert(16%) sepia(14%) saturate(844%) hue-rotate(155deg) brightness(94%) contrast(91%)' }} /> */}
                <CreateNewFolder width={18} />
              </IconButton>
            </CustomTooltip>
          ) : null}
        </Fragment>
        {(location.pathname.includes('/project/') &&
          verifyProjectRole('transcript')?.includes(roleType.EDIT)) ||
        !location.pathname.includes('/project/') ? (
          <CustomTooltip
            content={t('folder.rename')}
            className="bg-lstnBlueGray-400"
          >
            {/* @ts-ignore */}
            <IconButton
              size="md"
              variant="outlined"
              disabled={checkedRows || !checked || !edit}
              onClick={onRename}
              data-cy="data-project-rename"
            >
              <img
                src={
                  checkedRows || !checked || !edit ? renameIcon : renameColor
                }
                alt="img"
                width={18}
              />
            </IconButton>
          </CustomTooltip>
        ) : null}
        <div
          className={`${
            !checked ||
            (checkedRowsData[0] && checkedRowsData[0].status == 'Folder')
              ? 'transcripts-export-btn-disabled'
              : ''
          }`}
        >
          {/* <ExportMenu onSave={onExport} disabled={!checked || (checkedRowsData[0] && checkedRowsData[0].status == 'Folder')} userStatus={userStatus} transcriptIds={transcriptIds} timeCodePreferrence={false} userFeatures={userFeatures} /> */}
          <ExportMenu
            onSave={onExport}
            disabled={
              !checked ||
              (checkedRowsData[0] && checkedRowsData[0].status == 'Folder') ||
              (currentProject?.id
                ? !verifyProjectRole('transcript')?.includes(roleType.EXPORT)
                : !transcriptRoles?.includes(roleType.EXPORT))
            }
            userStatus={userStatus}
            transcriptIds={transcriptIds}
            timeCodePreferrence={false}
            userFeatures={userFeatures}
          />
        </div>
        {(location.pathname.includes('/project/') &&
          verifyProjectRole('transcript').includes(roleType.DELETE)) ||
        !location.pathname.includes('/project/') ? (
          <CustomTooltip
            content={t('folder.delete')}
            className="bg-lstnBlueGray-400"
          >
            {/* @ts-ignore */}
            <IconButton
              size="md"
              variant="outlined"
              onClick={onDelete}
              disabled={!deleteCheck}
              data-cy="data-project-delete"
            >
              <img src={!deleteCheck ? del : delColor} alt="img" width={18} />
            </IconButton>
          </CustomTooltip>
        ) : null}
      </div>
      {/* <div data-cy="data-project-transcripts-search" className='flex items-end ml-2 searchbar'> */}
      <div
        data-cy="data-project-transcripts-search"
        className="actionPanelSearch flex ml-2"
      >
        <Input
          placeholder="Search"
          labelProps={{
            className: 'hidden',
          }}
          className=" !border-lstnBlueGray-100 focus:!border-gray-900 placeholder:text-blue-gray-500 placeholder:opacity-100"
          onChange={handleSearchChange}
          icon={<img src={searchIcon} className="h-5 w-5" />}
          onResize={undefined}
          onResizeCapture={undefined}
          crossOrigin={undefined}
          enterKeyHint={undefined}
        />
      </div>
    </div>
  )
}

export default ActionPanelItems
