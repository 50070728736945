// tslint:disable: ordered-imports
// @ts-nocheck
import React, { useState, useCallback, useEffect } from 'react'
// import MaterialTable from "material-table"
// import { createMuiTheme } from "@material-ui/core/styles"
// import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"
// // import ReactGA from 'react-ga'
// // import ActionItems from '../Transcripts/ActionPanelItems'
import { IFolders, ITranscriptRow, IUserFeatures } from '../../interfaces'
import { ExportValues } from '../ExportMenu/ExportMenu'
// import { IUserStatusState } from '../../store/reducers/userStatus'
import { useTranslation } from 'react-i18next'
import ActionArrange from '../RolesAccessComponents/ActionsArrange'
// // import { compose } from "recompose"
// // import { withFirestore } from "react-redux-firebase"
// // import { connect } from "react-redux"
import { customEvent } from '../../utils/customHooks'
import { useAppSelector } from '../../store/store'
import { Checkbox } from '@material-tailwind/react'
import { Card, Typography, Tooltip } from '@material-tailwind/react'
import { CheckCircle, Folder } from '@material-ui/icons'
import ErrorIcon from '@material-ui/icons/Error'
import File from '../../icons/tableFile.svg'
import Transcribing from '../../icons/Transcribing.svg'
import { Location } from 'history'
import CustomTooltip from '../Tooltip/Tooltip'
import { roleType } from '../../enums'

interface IColumn {
  title: string
  field: string
}
interface IProps {
  columnMappings: IColumn[]
  dataMappings: ITranscriptRow[]
  onDelete: (transcriptLists: ITranscriptRow[]) => void
  onExport: (transcriptIds: string[], data: ExportValues) => void
  onBulkExport: (transcriptIds: string[], data: ExportValues) => void
  onRename: (renameData: { data: ITranscriptRow[] }) => void
  onNewFolder: (newfolderData: { id: string; name: string }) => void
  moveFolderBox: (status: boolean, rows?: ITranscriptRow[]) => void
  onClick: (
    transcriptId: string,
    status?: 'completed' | 'inprogress' | 'Folder',
  ) => void
  userStatus: IUserStatus
  userFeatures?: IUserFeatures
  folders?: IFolders
  getItemsIds: (folderId: string) => void
  userId: string
  getFolderHeirarchy: (userId: string) => void
  loading: boolean
  location: Location
  dataLoaded?: boolean
  openPopup: (data: ITranscriptRow[]) => void
  accountId?: string
  uploadref: React.RefObject<HTMLElement>
}

// const theme = createMuiTheme({
//   overrides: {
//     MuiTableRow: {
//       root: {
//         "&:hover": {
//           backgroundColor: "rgb(37, 2000, 10, 0.35)",
//         },
//       },
//     },
//     MuiPaper: {
//       root: {
//         boxShadow: "none !important",
//         width: "100%",
//       },
//     },
//   },
//   palette: {
//     primary: {
//       main: "#35da3c",
//     },
//     secondary: {
//       main: "#25da3c",
//       light: "#25da3c",
//       dark: "#25da3c",
//     },
//   },
// })

const StyledTable = ({
  columnMappings,
  dataMappings,
  onDelete,
  onExport,
  onRename,
  onNewFolder,
  onClick,
  getItemsIds,
  userStatus,
  userFeatures,
  moveFolderBox,
  folders,
  userId,
  location,
  accountId,
  uploadref,
  getFolderHeirarchy,
  // // dataLoaded,
  openPopup,
}: IProps): React.JSX.Element => {
  const [checkedRows, setCheckedRows] = useState<boolean>(false)
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [checkedRowsData, setCheckedRowsData] = useState<ITranscriptRow[]>([])
  // // const [ checkedTranscript, setCheckedTranscript ] = useState<ITranscriptRow>();
  const { t } = useTranslation()
  const [checkedItems, setCheckedItems] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [isdisabled, setIsdisabled] = useState(false)

  const { transcriptList, transcriptListShareUserId, foldersShareUserId } =
    useAppSelector((store) => store.transcriptSlice)
  const { currentProject, roles } = useAppSelector((store) => store.project)
  const { user } = useAppSelector((store) => store.user)

  useEffect(() => {
    setCheckedRows(false)
    setCheckedRowsData([])
    setCheckedIds([])
  }, [folders, location?.url])

  const changeCheckStatusData = async (
    item: string | ITranscriptRow,
  ): Promise<void> => {
    const isChecked = checkedItems.some(
      (checkedItem) => checkedItem.id === item.id,
    )

    if (isChecked) {
      // Item is checked, remove it from the array
      await setCheckedItems((prev) => prev.filter((v) => v.id !== item.id))
      setSelectAllChecked(false)
    } else {
      // Item is not checked, add it to the array
      await setCheckedItems((prev) => [...prev, item])
    }
  }
  const changeCheckStatus = (rows: ITranscriptRow[]): void => {
    rows.length > 1 ? setCheckedRows(true) : setCheckedRows(false)
    const ids = rows.map((row) => row.id)
    setCheckedRowsData(rows)
    setCheckedIds(ids)
    if (rows.length === 1) {
      // setCheckedTranscript(rows[0])
    }
    const row = rows.map((v) =>
      v.status === 'DONE' ? 'transcript-selected' : 'folder-selected',
    )
    if (rows.length === 1) {
      customEvent('file_selected', {
        category: 'transcript',
        actionMetadata: row,
        user_userId: userId,
        accountId: accountId,
        projectId: currentProject && currentProject.id ? currentProject.id : '',
      })
    }
  }
  useEffect(() => {
    if (checkedItems) {
      changeCheckStatus(checkedItems)
    }
    if (
      checkedItems.length > 0 &&
      filteredRows.length === checkedItems.length
    ) {
      setSelectAllChecked(true)
    }
  }, [checkedItems])
  const handleDelete = (): void => {
    onDelete(checkedRowsData)
    // ReactGA.event({
    //   action: "delete button pressed",
    //   category: "transcript",
    // })

    customEvent('transcript_delete_requested', {
      category: 'transcript',
      transcriptId: checkedIds,
      user_userId: userId,
      accountId: accountId,
      projectId: currentProject && currentProject.id ? currentProject.id : '',
    })
  }

  const handleBulkExport = (data: ExportValues): void => {
    onExport(checkedIds, data)
    // ReactGA.event({
    //   action: "export button pressed",
    //   category: "transcript",
    // })
    customEvent('transcript_export_requested', {
      category: 'transcript',
      transcriptId: checkedIds,
      fileType: data.fileType,
      user_userId: userId,
      accountId: accountId,
      projectId: currentProject && currentProject.id ? currentProject.id : '',
    })
    setCheckedItems([])
  }

  const handleExport = (data: ExportValues): void => {
    onExport(checkedIds, data)

    customEvent('transcript_export_requested', {
      category: 'transcript',
      transcriptId: checkedIds,
      fileType: data.fileType,
      user_userId: userId,
      accountId: accountId,
      projectId: currentProject && currentProject.id ? currentProject.id : '',
    })
    // ReactGA.event({
    //   action: "export button pressed",
    //   category: "transcript",
    // })
    setCheckedItems([])
  }

  const handleRename = (): void => {
    if (checkedRowsData) {
      onRename({ data: checkedRowsData })
      // ReactGA.event({
      //   action: "Rename button pressed",
      //   category: "transcript",
      // })

      customEvent(
        checkedRowsData[0].status == 'Folder'
          ? 'folder_rename_requested'
          : 'transcript_rename_requested',
        {
          category: 'transcript',
          transcriptId: checkedIds,
          user_userId: userId,
          accountId: accountId,
          projectId:
            currentProject && currentProject.id ? currentProject.id : '',
        },
      )
      setCheckedItems([])
    }
  }
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(e.target.value)
  }

  const filteredRows =
    transcriptList && transcriptListShareUserId && foldersShareUserId
      ? dataMappings.filter(({ name }) =>
          name.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      : []
  const handleNewFolder = (): void => {
    onNewFolder({ id: '', name: t('folder.untitled') })
    // ReactGA.event({
    //   action: "create NewFolder button pressed",
    //   category: "folders",
    // })
  }
  const handleSelectAll = (): void => {
    const newCheckedItems = selectAllChecked ? [] : [...filteredRows]
    setCheckedItems(newCheckedItems)
    setSelectAllChecked(!selectAllChecked)
  }

  const handleClick = (e: React.MouseEvent, rowData?: ITranscriptRow): void => {
    if (document.querySelector('.share-dialog') !== null) {
      return
    }
    if (rowData) {
      const generalStatus = (() => {
        if (rowData.status === 'DONE' || rowData.status === 'EDITED') {
          return 'completed'
        } else if (rowData.status !== 'FAILED') {
          return 'inprogress'
        }
        return
      })()

      if (rowData.status == 'Folder') {
        onClick(rowData.id, rowData.status)
      } else {
        onClick(rowData.id, generalStatus)
      }
    }
  }

  const handleInput = (): void => {
    uploadref.current?.click()
  }

  const handleMoveFolder = (): void => {
    getFolderHeirarchy(userId)
    moveFolderBox(true, checkedRowsData)
    // ReactGA.event({
    //   action: "Move button pressed",
    //   category: "folders",
    // })
    customEvent('file_move_modal_opened', {
      category: 'transcript',
      user_userId: userId,
      accountId: accountId,
      projectId: currentProject && currentProject.id ? currentProject.id : '',
    })
    setCheckedItems([])
  }

  const verifyProjectRole = (roleToSpecify: string) => {
    if (currentProject && roles) {
      const currentUser = currentProject.users[user.uid!]
      const projectRole = roles[currentUser?.role]?.[roleToSpecify]
      return projectRole
    }
  }

  const ActionArrangeToolbar = useCallback(
    (props: IProps) => {
      if (
        !(transcriptList && transcriptListShareUserId && foldersShareUserId)
      ) {
        return null
      }

      return (
        <ActionArrange
          {...props}
          openPopup={openPopup}
          location={location.url}
          checkedRows={checkedRows}
          checked={checkedIds.length > 0}
          onDelete={handleDelete}
          onExport={handleExport}
          onBulkExport={handleBulkExport}
          onRename={handleRename}
          onNewFolder={handleNewFolder}
          userStatus={userStatus}
          transcriptIds={checkedIds}
          userFeatures={userFeatures}
          handleMoveFolder={handleMoveFolder}
          checkedRowsData={checkedRowsData}
          getItemsIds={getItemsIds}
          userId={userId}
          getFolderHeirarchy={getFolderHeirarchy}
          handleSearchChange={handleSearchChange}
        />
      )
    },
    [
      transcriptList && transcriptListShareUserId && foldersShareUserId,
      location,
      userStatus,
      userFeatures,
      userId,
      checkedRows,
      checkedIds,
      checkedRowsData,
      openPopup,
      getItemsIds,
      getFolderHeirarchy,
    ],
  )

  const renderStatus = useCallback(
    (status: string) => {
      switch (status) {
        case 'DONE':
          return t('transcripts.statuses.done')
        case 'EDITED':
          return t('transcripts.statuses.edited')
        case 'FAILED':
          return t('transcripts.statuses.failed')
        case 'UPLOADING':
          return t('transcripts.statuses.uploading')
        case 'ANALYSING':
          return t('transcripts.statuses.analysing')
        case 'TRANSCRIBING':
          return t('transcripts.statuses.transcribing')
        case 'SAVING':
          return t('transcripts.statuses.saving')
        case 'PROCESSING LARGE FILE':
          return t('transcripts.statuses.processing')
        case 'Extracting Metadata':
          return t('transcripts.statuses.extracting metadata')
        case 'Preparing for Transcoding':
          return t('transcripts.statuses.preparing for transcoding')
        case 'Transcoding':
          return t('transcripts.statuses.transcoding')
        default:
          return '--'
      }
    },
    [t],
  )

  useEffect(() => {
    if (
      (userStatus &&
        userStatus.userStatus &&
        userStatus.userStatus.status !== 'active') ||
      (userStatus && userStatus.userStatus.remainingSeconds <= 0) ||
      (userStatus && userStatus.userStatus.additionalSeconds <= 0)
    ) {
      setIsdisabled(true)
    } else {
      setIsdisabled(false)
    }
  }, [userStatus, userStatus.remainingSeconds])
  const renderStatusIcon = useCallback((status: string) => {
    switch (status) {
      case 'Folder':
        return null
      case 'DONE':
        return <CheckCircle className="text-lstnGreen-500 iconSize20" />
      case 'EDITED':
        return (
          <img
            src={require('../../icons/edit.png')}
            className="w-5 object-cover"
            alt="img"
          />
        )
      case 'FAILED':
        return <ErrorIcon className="text-red-500 iconSize20" />
      default:
        return (
          <div className="w-5">
            <img src={Transcribing} className="w-5 object-cover" alt="img" />
          </div>
        )
    }
  }, [])

  const renderErrorMessage = useCallback(
    (message: string) => {
      switch (message) {
        case 'Audio Duration exceeds limit allowed':
          return t('transcripts.errors.audioExceedsLimit')
        case 'Subscription is not active':
          return t('transcripts.errors.subscriptionNotActive')
        case 'Value for argument "data" is not a valid Firestore':
          return t('transcripts.errors.invalidData')
        case 'Multiple errors occurred during the request':
          return t('transcripts.errors.multipleErrors')
        case 'null':
          return t('transcripts.errors.null')
        case 'connection error while making request.':
          return t('transcripts.errors.connectionError')
        case 'Fant ingen ord i lydfilen':
          return t('transcripts.errors.noWordsFoundInFile')
        case "Cannot read property 'startTime' Of undefined":
          return t('transcripts.errors.startTimeUndefined')
        default:
          return message
      }
    },
    [t],
  )

  return (
    // <div data-tut="tour-table">
    //   <MuiThemeProvider theme={theme}>
    //      {/* @ts-ignore */}
    //     <MaterialTable
    //       title="Transcripts"
    //       columns={columnMappings}
    //       data={!(transcriptList  && transcriptListShareUserId  && foldersShareUserId) ? [] : dataMappings}
    //       components={{
    //         Toolbar: ActionArrangeToolbar,
    //       }}
    //       options={{
    //         selection: true,
    //         paging: false,
    //         rowStyle: (rowData) => ({ backgroundColor: rowData.tableData.checked ? "rgb(37, 2000, 10, 0.35)" : "" }),
    //       }}
    //       onSelectionChange={changeCheckStatus}
    //       onRowClick={handleClick}
    //     />
    //   </MuiThemeProvider>
    //   <div style={{ maxWidth: "100%" }} />
    // </div>
    <div data-tut="tour-table">
      <ActionArrangeToolbar />
      <Card
        className="h-full w-full mt-6 overflow-x-scroll scrollbarNone "
        placeholder={undefined}
      >
        <table className="text-left min-w-[768px]">
          <thead>
            <tr>
              <th
                className={`border-b border-blue-gray-100 bg-[#F9FAFB] gap-2 h-10`}
                style={{
                  width: '6%',
                }}
              >
                <Checkbox
                  onClick={(e) => {
                    e.stopPropagation()
                    handleSelectAll()
                  }}
                  checked={selectAllChecked}
                />
              </th>
              {columnMappings.map((val, index) => (
                <th
                  key={index}
                  className="border-b border-blue-gray-100 bg-[#F9FAFB] gap-2 h-10 text-sm text-blue-gray-900 font-semibold leading-none"
                  style={{
                    width: val.width, // Set max-width to avoid columns extending beyond container
                  }}
                >
                  <Typography
                    variant="small"
                    className="font-medium text-lstnBlueGray-900 text-base"
                  >
                    {val.title}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredRows.length === 0 ? (
              <tr>
                <td
                  colSpan={columnMappings.length + 1}
                  className="text-lstnBlueGray-500 text-center h-[350px]"
                >
                  <div className="w-auto md:w-96 mx-auto">
                    <div className="flex justify-center mb-4">
                      <img src="/emptyglass.svg" alt="img" />
                    </div>
                    <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                      {t('table.emptyMessage')}
                    </Typography>
                    <Typography
                      variant="small"
                      className="text-base font-normal"
                    >
                      {t('table.content')}
                    </Typography>
                    {(location.pathname.includes('/project/') &&
                      verifyProjectRole('transcript').includes(roleType.ADD)) ||
                    !location.pathname.includes('/project/') ? (
                      isdisabled ? (
                        <Tooltip
                          className="bg-lstnGreen-100 text-blue-gray-400"
                          content={t('uploadIcon.disableTooltip')}
                        >
                          <Typography
                            className={`text-base mt-3 font-medium text-lstnBlue-500 flex items-center justify-center ${
                              isdisabled
                                ? 'text-lstnGreen-300 cursor-not-allowed'
                                : 'cursor-pointer text-lstnGreen-500'
                            }`}
                            onClick={handleInput}
                            aria-disabled="true"
                          >
                            <span
                              className="inline-block lstn-btn ml-0 w-3 h-3 p-0 rounded-full text-center relative mr-1 bg-lstnGreen-300"
                              aria-disabled="true"
                            >
                              <svg width="12" height="12" aria-hidden="true">
                                <use xlinkHref="#icon-pluss" />
                              </svg>
                            </span>
                            {t('table.Upload')}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          className="text-base mt-3 font-medium text-lstnBlue-500 cursor-pointer text-lstnGreen-500 flex items-center justify-center"
                          onClick={handleInput}
                        >
                          <span className="inline-block lstn-btn ml-0 w-3 h-3 p-0 rounded-full text-center relative mr-1">
                            <svg width="12" height="12" aria-hidden="true">
                              <use xlinkHref="#icon-pluss" />
                            </svg>
                          </span>
                          {t('table.Upload')}
                        </Typography>
                      )
                    ) : null}
                  </div>
                </td>
              </tr>
            ) : (
              filteredRows.map((value, index) => {
                const isLast = index === dataMappings.length - 1
                const classes = isLast
                  ? 'h-10'
                  : 'h-10 border-b border-blue-gray-50'

                const isChecked = checkedItems.some(
                  (checkedItem) => checkedItem.id === value.id,
                )
                return (
                  <tr
                    key={value.id}
                    className="cursor-pointer hover:bg-[#F9FAFB]"
                    onClick={(e) => handleClick(e, value)}
                  >
                    <td className={`${classes} pr-0`}>
                      <Checkbox
                        onClick={(e) => {
                          e.stopPropagation()
                          changeCheckStatusData(value)
                        }}
                        checked={isChecked}
                      />
                    </td>
                    <td className={`${classes} pl-0`}>
                      <div className="flex items-center gap-2">
                        {value.status == 'Folder' ? (
                          <div>
                            <Folder className="text-blue-gray-300 iconSize20" />
                          </div>
                        ) : (
                          <div
                            style={{
                              width: '20px',
                              height: '20px',
                              paddingLeft: '2px',
                              paddingRight: '2px',
                            }}
                          >
                            <img
                              src={File}
                              className="w-full h-full object-contain"
                              alt="img"
                            />
                          </div>
                        )}
                        <div className="w-80">
                          <Typography className="text-base text-lstnBlueGray-900 font-normal">
                            {value.name}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      {value.status === 'FAILED' ? (
                        <CustomTooltip
                          content={`${renderErrorMessage(value.errorMessage)}`}
                          className="bg-lstnBlueGray-400"
                        >
                          <div className="flex items-center gap-2">
                            <div>{renderStatusIcon(value.status)}</div>
                            <Typography className="text-base text-lstnBlueGray-900 font-normal">
                              {renderStatus(value.status)}
                            </Typography>
                          </div>
                        </CustomTooltip>
                      ) : (
                        <div className="flex items-center gap-2">
                          <div>{renderStatusIcon(value.status)}</div>
                          <Typography className="text-base text-lstnBlueGray-900 font-normal">
                            {renderStatus(value.status)}
                          </Typography>
                        </div>
                      )}
                    </td>
                    <td className={classes}>
                      <Typography className="text-base text-lstnBlueGray-900 font-normal">
                        {value.duration}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography className="text-base text-lstnBlueGray-900 font-normal">
                        {value.uploadedOn}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography className="text-base text-lstnBlueGray-900 font-normal">
                        {value.lastModified}
                      </Typography>
                    </td>
                  </tr>
                )
              })
            )}
          </tbody>
        </table>
      </Card>
    </div>
  )
}

// const mapStateToProps = (state: ) => {
//   const status = state.firestore.status.requested
//   return {
//     dataLoaded: status && status.folders && status.foldersShareUserId && status.roles && status.transcriptList && status.transcriptListNoFolder && status.transcriptListShareUserId && status.transcriptListShareUserIdAlone,
//     users: state.firestore.data.users,
//   }
// }

// const enhance:  = compose(withFirestore, connect(mapStateToProps))

export default StyledTable
