import ReactGA from 'react-ga4'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import '../../css/SignUp.css'
import Typography from '../Typography/Typography'
import LanguageSelector from './Select'
import { Formik } from 'formik'
import * as Yup from 'yup'
import firebase, { database } from '../../firebaseApp'
import { functionsV2 } from '../../firebaseApp'
import { useLocation, useNavigate } from 'react-router-dom'
import i18n from '../../i18n'
import Toast from '../Toast/Toast'
import { useTranslation } from 'react-i18next'
import { customEvent, getCountryCode } from '../../utils/customHooks'
import Sidebar from './Sidebar'
import Button from '../Button/TailwindButton'
import SplashScreen from '../SplashScreen'
import SignUpForm from './SignUpForm'
import getProvider from '../../utils/provider'
import { useAppDispatch } from '../../store/store'
import { fetchUserById } from '../../store/features/userSlice'
import * as QueryString from 'query-string'
// import { database } from '../../firebaseApp'
import moment from 'moment'

const trackingCode: string | undefined = process.env.REACT_APP_GA4_PROPERTY_ID

if (trackingCode) {
  ReactGA.initialize([
    {
      trackingId: trackingCode,
    },
  ])
}
type Language = 'en' | 'it' | 'es'

const SignUp: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [inviteEmail, setInviteEmail] = useState<string>('')
  const { t } = useTranslation()
  const navigate = useNavigate()
  const countryCode = getCountryCode()
  const location = useLocation()
  const queries = QueryString.parse(location?.search)
  const emailInputRef = useRef<HTMLInputElement | null>(null)
  const passwordInputRef = useRef<HTMLInputElement | null>(null)

  const dispatch = useAppDispatch()
  const searchParams = new URLSearchParams(location.search)
  const lng = searchParams.get('lng')

  useEffect(() => {
    if (lng) {
      i18n.changeLanguage(lng as Language)
      localStorage.setItem('lstn-display-lng', lng as Language)
    }
  }, [lng])

  const SignUpFormSchema = Yup.object().shape({
    email:
      inviteEmail !== ''
        ? Yup.string()
            .email('Invalid email')
            .test(
              'email-match',
              'Oops! email address does not match',
              function (value) {
                return value === inviteEmail
              },
            )
            .required('Email is required')
        : Yup.string().email().required('email is required'),
    username: Yup.string().required().min(2, 'username is required'),
    password: Yup.string()
      .required()
      .min(8, 'Your password has to have at least 8 character'),
  })

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.uid) {
        setLoading(true)
      }
    })
  }, [])

  const handleLanguageSelect = async (value: string) => {
    const ln = value || ''
    // setDisplayLanguage(ln )
    i18n.changeLanguage(ln as Language)
    localStorage.setItem('lstn-display-lng', ln as Language)
  }

  const inviteId = localStorage.getItem('inviteId')

  const createUserWithSubscription = async ({ user }: any) => {
    console.log('function start', moment().format('HH:mm:ss'))
    if (user) {
      const providerLabel =
        localStorage.getItem('lstn-auth-provider') ||
        (user.providerData[0] !== null
          ? getProvider(user.providerData[0].providerId)
          : '')

      customEvent('sign_up', {
        category: 'auth',
        authAttribute: providerLabel,
        user_userId: user.uid,
      })

      const selectedPlan =
        localStorage.getItem('lstn-plan') !== null
          ? // @ts-ignore
            planData[localStorage.getItem('lstn-plan')]
          : ''
      const createdAt = user && user.metadata ? user.metadata.creationTime : ''
      const validityCode = localStorage.getItem('inviteValidityCode')
      console.log('API call', moment().format('HH:mm:ss'))
      const subscription = functionsV2.httpsCallable('addSubscription')
      try {
        await subscription({
          ...JSON.parse(JSON.stringify(user)),
          country_code: countryCode,
          preferredLanguage: queries.lng || 'en',
          selectedPlan: selectedPlan ? selectedPlan : '',
          createdAt,
          inviteId,
          validityCode: validityCode ? parseInt(validityCode, 10) : null,
          filterProfanity: false,
        })
        if (inviteId && validityCode) {
          localStorage.removeItem('inviteId')
          localStorage.removeItem('inviteValidityCode')
        }
        // fetchStatus(user.uid)
        dispatch(fetchUserById(user.uid))
        console.log('function end', moment().format('HH:mm:ss'))
        console.log('&&&&')
      } catch (err) {
        console.error(err)
      }
    }
  }

  const handleSign = useCallback(
    async (email: string, password: string, username: string) => {
      console.log('handleSign', moment().format('HH:mm:ss'))
      if (email && password && username) {
        setLoading(true)
        try {
          const methods = await firebase
            .auth()
            .fetchSignInMethodsForEmail(email)
          // If the array of methods is not empty, the email is already registered
          if (methods && methods.length > 0) {
            Toast({
              title: t && t('auth.toast.title'),
              text: t && t('auth.toast.text'),
              variant: 'error',
            })
            setLoading(false)
            return
          }
          customEvent('sign_up_requested', {
            category: 'auth',
            authAttribute: 'email_auth',
          })
          console.log('authentication', moment().format('HH:mm:ss'))
          firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(async (userCredential) => {
              // Signed in
              const user = userCredential.user
              if (user) {
                // Update user profile with the desired username
                await user.updateProfile({
                  displayName: username,
                })
                customEvent('sign_up', {
                  category: 'auth',
                  authAttribute: 'email_auth',
                  user_userId: user.uid,
                })

                localStorage.setItem('lstn-auth-provider', 'email_auth')
                console.log(
                  'createUserWithSubscription',
                  moment().format('HH:mm:ss'),
                )
                await createUserWithSubscription({ user })
              }
              // localStorage.setItem('lstn-init-user', 'true')
              if (!inviteId) {
                navigate('/create-account')
              }
              // ...
            })
            .catch((error) => {
              const errorMessage = error.message
              console.log(errorMessage)
              setLoading(false)
            })
        } catch (error) {
          console.log(error)
          setLoading(false)
        }
      }
    },
    [],
  )
  const handleSignGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    provider.setCustomParameters({ prompt: 'select_account' })
    customEvent('sign_up_requested', {
      category: 'auth',
      authAttribute: 'google_auth',
    })
    try {
      const result = await firebase.auth().signInWithPopup(provider)
      const user = result.user
      if (user) {
        customEvent('sign_up', {
          category: 'auth',
          authAttribute: 'google_auth',
          user_userId: user.uid,
        })
        const querySnapshot = await database
          .collection('users')
          .where('email', '==', user.email)
          .get()

        if (!querySnapshot.empty) {
          console.log('User already exists', querySnapshot.docs[0].data().email)
        } else {
          // localStorage.setItem('lstn-init-user', 'true')
          localStorage.setItem('lstn-auth-provider', 'google.com')
          await createUserWithSubscription({ user })
          if (!inviteId) {
            navigate('/create-account')
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <div className="flex ">
          <div className="hidden h-screen bg-gradient-to-t from-[#dcfcdfd7] to-[#F3F8FF] md:flex lg:w-1/3 md:w-2/5 flex-col justify-between">
            <Sidebar />
          </div>
          <div className="w-full pt-20 lg:w-3/4 md:w-3/5 p-6 h-auto md:pt-4 lg:pt-4 xl:pt-4 flex justify-center items-center relative ">
            <div className="absolute right-10 top-8">
              <LanguageSelector onSelect={handleLanguageSelect} />
            </div>
            <div className="md:w-1/2 mx-auto sm:w-96 lg:w-2/5 xl:w-2/5 md:h-auto ">
              <Typography className="2xl:p-2 text-center text-3xl font-semibold text-lstnBlueGray-900">
                {t && t('auth.signUp')}
              </Typography>
              <Typography className="p-2 2xl:py-4 text-center text-base font-normal text-lstnBlueGray-700">
                {t && t('auth.signUpContent')}
              </Typography>
              <div className="flex flex-col items-center gap-1 2xl:gap-3">
                {/* @ts-ignore */}
                <Button
                  color="white"
                  className="!border !border-blue-gray-50 bg-white flex items-center text-sm font-bold text-lstnBlueGray-900 gap-3 w-full justify-center my-2"
                  onClick={handleSignGoogle}
                >
                  <img
                    src="https://docs.material-tailwind.com/icons/google.svg"
                    alt="metamask"
                    className="h-4 w-4"
                  />
                  {t && t('auth.signUPwithGoogle')}
                </Button>
                {t('auth.or')}
              </div>
              <Formik
                initialValues={{ email: '', password: '', username: '' }}
                onSubmit={(values) =>
                  handleSign(values.email, values.password, values.username)
                }
                validationSchema={SignUpFormSchema}
                validateOnMount={true}
              >
                {({
                  values,
                  errors,
                  // isValid,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <SignUpForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      handleSubmit={handleSubmit}
                      setFieldValue={setFieldValue}
                      inviteId={inviteId}
                      setInviteEmail={setInviteEmail}
                      emailInputRef={emailInputRef}
                      passwordInputRef={passwordInputRef}
                    />
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SignUp
