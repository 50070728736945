import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import type { PayloadAction } from '@reduxjs/toolkit'
import { database } from '../../firebaseApp'
import { IStripePrice, IStripeProduct } from '../../interfaces'
import { AppDispatch } from '../store'

export interface IPlanData extends IStripeProduct {
  planID?: string
  prices: IStripePrice[]
  stripe_metadata_allowedMinutes: string
  stripe_metadata_allowedSeconds: string
  stripe_metadata_bookACall: string
  stripe_metadata_isFreePlan: string
  stripe_metadata_isVisible: string
  stripe_metadata_portalAccess: string
}
interface IPlanState {
  plans: [] | IPlanData[]
  loading: string
  error: Error | null
}

const initialState: IPlanState = {
  plans: [],
  loading: 'idle',
  error: null,
}

const planSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlans: (state, action) => {
      state.plans = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlanData.pending, (state) => {
        state.loading = 'pending'
        state.error = null
      })
      .addCase(fetchPlanData.fulfilled, (state) => {
        state.loading = 'succeeded'
        state.error = null
      })
      .addCase(fetchPlanData.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = new Error(action.error.message)
      })
  },
})

export const { setPlans } = planSlice.actions
export default planSlice.reducer

const fetchPlanData = createAsyncThunk(
  'plans/fetchData',
  async (data: IPlanData[], { dispatch }) => {
    try {
      dispatch(setPlans(data))
    } catch (error) {
      console.log(error)
    }
  },
)

export const helperPlanData = ({ dispatch }: { dispatch: AppDispatch }) => {
  const stripe_productsQuery = database.collection('stripe_products')
  const unsubscribe = stripe_productsQuery.onSnapshot(
    (stripe_productsQuerySnapShot) => {
      if (!stripe_productsQuerySnapShot.empty) {
        const allstripe_products = stripe_productsQuerySnapShot.docs.map(
          async (doc) => {
            const stripeData = doc.data()
            const userPriceQuery = stripe_productsQuery
              .doc(doc.id)
              .collection('prices') // Replace 'YOUR_DOCUMENT_ID' with the actual document ID
            const pricesSnapShot = await userPriceQuery.get()
            const pricesData = pricesSnapShot.docs.map((priceDoc) => ({
              id: priceDoc.id,
              ...priceDoc.data(),
            }))

            // Merge the stripeData with pricesData
            const mergedstripeData = {
              ...stripeData,
              planID: doc.id,
              prices: pricesData,
            }
            return mergedstripeData
          },
        )

        // If you want to log all merged data for the entire collection
        Promise.all(allstripe_products).then((mergedDataArray) => {
          // console.log(mergedDataArray, 'all data');
          dispatch(fetchPlanData(mergedDataArray as IPlanData[]))
        })
      }
    },
  )
  return () => unsubscribe()
}
