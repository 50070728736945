const getProvider = (provider: string): string => {
  if (provider === 'password') {
    return 'email_auth'
  } else if (provider === 'google.com') {
    return 'google_auth'
  } else if (provider === 'facebook.com') {
    return 'facebook_auth'
  }

  return 'email_auth'
}

export default getProvider
