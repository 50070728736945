import React, { useRef, useState, useContext } from 'react'
import { TFunction, useTranslation } from 'react-i18next'

// import { compose } from "recompose"
// import { connect } from "react-redux"
// import { withFirestore } from "react-redux-firebase"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'
import Button from '../Button/Button'
import { RolesContext } from '../../context/ContextProvider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import copy from 'copy-to-clipboard'
import { ThemeProvider } from '@material-ui/styles'
import { lstnBasictheme } from '../../theme/lstn-theme-basic'
import { useAppSelector } from '../../store/store'

import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { roleType } from '../../enums'
import { MenuItem, Select } from '@material-ui/core'
import { ITranscriptRow, IUser } from '../../interfaces'
import { IUserEmails, IshareList, saveData } from './PopupContainer'

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    // @ts-ignore
    return <Slide direction="up" ref={ref} {...props} />
  },
)
interface RolesContextType {
  selectedRole: string
  setSelectedRole: (value: string) => void
}

interface IPopup {
  checkChildFoldersAccess: (folderId: string, name: string) => boolean
  closePopup: () => void
  isOpen: boolean
  rowsData: ITranscriptRow[] | null
  userId: string
  role: string
  setRole: React.Dispatch<React.SetStateAction<string>>
  setIsOpen: () => void
  roleType: string
  defaultSelect: string
  onSave: (data: saveData) => void
  onUpdate: (data: {
    emails: {
      [key: string]: string
    }
  }) => Promise<void>
  shareList: IshareList[]
  transcriptId?: string
}
interface ITypeAhead {
  setEmails: React.Dispatch<React.SetStateAction<IUserEmails[]>>
  emails: IUserEmails[]
  typeaheadInput: React.MutableRefObject<null>
  setOptions: React.Dispatch<React.SetStateAction<IUserEmails[]>>
  options: IUserEmails[]
  t: TFunction<'translation', undefined>
}

const TypeAhead = (props: ITypeAhead): React.JSX.Element => {
  const [isLoading, setIsLoading] = useState(false)
  const { selectedRole, setSelectedRole } = useContext(
    RolesContext,
  ) as RolesContextType
  const { users } = useAppSelector((store) => store.user)

  const getUserData = (query: string): IUserEmails[] => {
    const options: IUserEmails[] = []

    if (users)
      Object.keys(users).map((d) =>
        users[d].email.includes(query)
          ? options.push({ ...users[d], userId: d })
          : '',
      )
    return options
  }

  const handleSearch = async (query: string): Promise<void> => {
    try {
      setIsLoading(true)
      const options = getUserData(query)
      props.setOptions(options)
      setIsLoading(false)
    } catch (error) {
      console.error('Error fetching users: ', error)
    }
  }
  const filterBy = (): boolean => true

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    const roleSelected = event.target.value as string
    setSelectedRole(roleSelected)
  }

  return (
    <div className="typeahead-input-group" data-cy="data-project-share-name">
      <AsyncTypeahead
        ref={props.typeaheadInput}
        filterBy={filterBy}
        id="share-input"
        isLoading={isLoading}
        labelKey="email"
        minLength={3}
        selected={props.emails}
        onSearch={handleSearch}
        options={props.options}
        className="typeahead"
        placeholder="Add People"
        multiple
        onChange={(selected) => {
          props.setEmails(selected as IUserEmails[])
        }}
        renderMenuItemChildren={(options) => {
          const option = options as IUser
          return (
            <List>
              <ListItem
                alignItems={'flex-start'}
                data-cy="data-project-share-list"
              >
                <ListItemText
                  primary={option.name}
                  secondary={option.email}
                  className="list-item-text"
                />
              </ListItem>
            </List>
          )
        }}
      />
      <div className="role-group" data-cy="data-project-share-role">
        <ThemeProvider theme={lstnBasictheme}>
          <Select
            value={selectedRole}
            style={{ width: 100 }}
            onChange={(e) => handleChange(e)}
          >
            {['sharedRead', 'sharedEdit'].map((list, index: number) => {
              return (
                <MenuItem
                  value={list}
                  key={index}
                  data-cy={`data-project-share-role-${list}`}
                >
                  {props.t && props.t('roles.' + list)}
                </MenuItem>
              )
            })}
          </Select>
        </ThemeProvider>
      </div>
    </div>
  )
}

const PopupUI = (props: IPopup): React.JSX.Element => {
  const typeaheadInput = useRef(null)
  const { t } = useTranslation()
  const [options, setOptions] = useState<IUserEmails[]>([])
  const [update, setUpdate] = useState(false)
  const { emails, setEmails, loader, emailValue, setEmailValue } =
    useContext(RolesContext)

  const handleClose = (status: boolean) => () => {
    if (status) {
      setEmailValue({})
      setEmails([])
      setUpdate(false)
    }
    props.setIsOpen()
  }

  const handleChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    email: IshareList,
  ): void => {
    const roleSelected = event.target.value as string
    if (email.role === roleSelected) {
      const uemail = { ...emailValue }
      delete uemail[email.user.userId]
      setEmailValue(uemail)
      if (Object.keys(uemail).length < 1) {
        setUpdate(false)
      }
    } else {
      setEmailValue({ ...emailValue, [email.user.userId]: roleSelected })
      setUpdate(true)
    }
  }

  const shareFile = async (): Promise<void> => {
    setOptions([])
    setEmails([])
    //@ts-ignore
    typeaheadInput.current.clear()
    await props.onSave({ emails })
    if (update) {
      await props.onUpdate({ emails: emailValue })
      setEmailValue({})
      setUpdate(false)
    }
    props.setIsOpen()
  }

  const copytoclipboard = (): void => {
    if (props.transcriptId) {
      const url = window.location.origin + '/transcript/' + props.transcriptId
      copy(url)
      alert('URL Copied')
    }
  }

  return (
    <div className="ConfirmationDialogue">
      <Dialog
        open={props.isOpen}
        TransitionComponent={Transition}
        onClose={handleClose(true)}
        maxWidth="md"
        fullWidth={true}
        className={'share-dialog'}
        data-cy="data-project-share-dialog"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t('shareTranscript.popupHeader')}
        </DialogTitle>
        <DialogContent dividers className="share-content-area">
          <TypeAhead
            {...props}
            t={t}
            emails={emails}
            setEmails={setEmails}
            setOptions={setOptions}
            options={options}
            typeaheadInput={typeaheadInput}
          />
          <List>
            {
              //@ts-ignore
              props.shareList &&
                props.shareList.map((list, index) => {
                  return (
                    <ListItem
                      alignItems={'flex-start'}
                      className={'email-list'}
                      key={index}
                    >
                      <ListItemText
                        primary={list.user.name}
                        secondary={list.user.email}
                      />
                      {list.role == 'owner' ? (
                        <div className="owner-type">Owner</div>
                      ) : (
                        <>
                          <ThemeProvider theme={lstnBasictheme}>
                            <Select
                              disabled={
                                props &&
                                list &&
                                list.user &&
                                list.user.userId === props.userId
                              }
                              value={
                                emailValue && emailValue[list.user.userId]
                                  ? emailValue[list.user.userId]
                                  : list.role
                              }
                              onChange={(e) => handleChange(e, list)}
                            >
                              {[
                                'sharedEdit',
                                'sharedRead',
                                ...(list.role === roleType.VARIES
                                  ? ['varies']
                                  : []),
                              ].map((role: string, index: number) => {
                                return (
                                  <MenuItem
                                    disabled={role === roleType.VARIES}
                                    value={role}
                                    key={index}
                                  >
                                    {t('roles.' + role)}
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </ThemeProvider>
                        </>
                      )}
                    </ListItem>
                  )
                })
            }
          </List>
        </DialogContent>
        <DialogActions>
          {props.roleType == 'transcripts' && (
            <div className="send-to-right">
              <Button
                onClick={() => copytoclipboard()}
                color="secondary"
                variant="outlined"
              >
                {t('renameDialog.copytoclipboard')}
              </Button>
            </div>
          )}
          <Button
            onClick={handleClose(true)}
            color="secondary"
            variant="outlined"
            data-cy="data-project-share-cancel"
          >
            {t('renameDialog.cancel')}
          </Button>
          <Button
            onClick={() => shareFile()}
            color="primary"
            variant="contained"
            disabled={!((emails && emails.length > 0) || update)}
            data-cy="data-project-share-submit"
          >
            <span style={{ marginRight: loader ? 15 : 0 }}>Share</span>{' '}
            {loader ? <CircularProgress color="secondary" size={15} /> : null}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

// const mapStateToProps = (state: ) => {
// 	return {
// 	  	userData: state.firestore.ordered.users,
// 		users:state.firestore.data.users,
// 	}
// }

// const mapDispatchToProps = () => {
// 	return{

// 	}
// }

// const enhance = compose(withFirestore, connect(mapStateToProps, mapDispatchToProps))

// @ts-ignore
export default PopupUI
