import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react'
import ReactGA from 'react-ga4'
import { storage } from '../../firebaseApp'
import nanoSecondsToFormattedTime from '../../nanoSecondsToFormattedTime'
// import Dropdown, { IDropdown } from '../Dropdown/Dropdown'
import Extension from '@material-ui/icons/Extension'
import ShortcutDialog from './ShortcutDialog'
import { useTranslation } from 'react-i18next'
// import Switch from '@material-ui/core/Switch'
// import { withStyles } from '@material-ui/styles'
// import FormGroup from '@material-ui/core/FormGroup'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import { SetCurrentTimeState } from "../../store/actions/transcriptActions"
// import { connect } from "react-redux"
import { customEvent } from '../../utils/customHooks'
import { setCurrentTime } from '../../store/features/transcript/transcriptsSlice'
import { useAppDispatch, useAppSelector } from '../../store/store'
import Button from '../Button/TailwindButton'
import { IconButton, Slider } from '@material-tailwind/react'
import Typography from '../Typography/Typography'
import Volume from '../../icons/volume.svg'
import Selector from '../SelectInput/Select'
import Progress from './Progress'
import { CustomSwitch } from '../Switch/Switch'
import { ITranscript } from '../../interfaces'
import { roleType } from '../../enums'

interface userData {
  transcript: ITranscript
}
interface IProps {
  playbackGsUrl: string
  timecode: string
  timeCodePreferrence: boolean
  handleTimeToggle: (hide?: boolean) => void
  onPlay: (val?: boolean) => void
  onEditBtnClick: () => void
  onPause: (val?: boolean) => void
  handleTimeUpdate(currentTime: number): void
  handlePlayerSpeedFromAudio(val: string): void
  // videoPlayCurrentTime:
  // SetCurrentTimeState:
  userData: userData
  userDataId: string
  verifyProjectRole: (roleToSpecify: string) => any
}

export interface audioPlayerMethods {
  setTime: (time: number) => void
  togglePlay: (key: string) => void
  isPlaying: boolean
}

const Player = forwardRef<audioPlayerMethods, IProps>(
  (
    {
      playbackGsUrl,
      userDataId,
      userData,
      timecode,
      timeCodePreferrence,
      handleTimeToggle,
      onPlay,
      onEditBtnClick,
      onPause,
      handlePlayerSpeedFromAudio,
      verifyProjectRole,
    },
    ref,
  ) => {
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)
    const audioRef = useRef<HTMLAudioElement>(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [playbackUrl, setPlaybackUrl] = useState<string | undefined>('')
    const [playerSpeed, setPlayerSpeed] = useState<string>('1')
    const [setOpenShortcut, setSetOpenShortcut] = useState(false)
    const [visibleToggle, setVisibleToggle] = useState(true)
    const [volume, sevolume] = useState(50)
    const [showVolumebar, setShowVolumebar] = useState(false)
    const dispatch = useAppDispatch()
    // const currentTime = videoPlayCurrentTime.currentTime;
    const { currentTime } = useAppSelector((store) => store.getTranscript)
    const { currentAccount } = useAppSelector((store) => store.account)
    const transcript = useAppSelector((store) => store.paragraphsSlice)
    const { t } = useTranslation()

    useEffect(() => {
      fetchPlaybackUrl()
    }, [playbackGsUrl])

    useEffect(() => {
      const parsed = timecode.split(':').map((el) => parseInt(el))
      const showToggle =
        (parsed[0] * 3600 + parsed[1] * 60 + parsed[2] + parsed[3] * 1e-3) * 1e9
      if (showToggle === 0 && visibleToggle) {
        setVisibleToggle(false)
        handleTimeToggle(false)
      } else if (showToggle !== 0 && !visibleToggle) {
        setVisibleToggle(true)
      }
    }, [timecode, visibleToggle, handleTimeToggle])

    useEffect(() => {
      if (timer) {
        clearInterval(timer)
      }
      // SetCurrentTimeState(0);
      dispatch(setCurrentTime(0))
    }, [])

    const pause = () => {
      if (isPlaying) {
        if (audioRef.current) {
          audioRef.current.pause()
          if (timer) {
            clearInterval(timer)
          }
          setIsPlaying(false)
        }
      }
    }

    const togglePlay = (key?: string) => {
      if (isPlaying) {
        onPause(false)
        pause()
        // ReactGA.event({
        //   action: "pause button pressed",
        //   category: "player",
        // })
        customEvent('player_pause', {
          category: 'player',
          transcriptId: userData.transcript.id,
          user_userId: userDataId,
          actionSource: key ? 'tab-keyboard' : 'play-button',
          accountId: currentAccount.id,
        })
        setIsPlaying(false)
      } else {
        onPlay(true)
        play()
        // ReactGA.event({
        //   action: "play button pressed",
        //   category: "player",
        // })
        customEvent('player_play', {
          category: 'player',
          transcriptId: userData.transcript.id,
          user_userId: userDataId,
          actionSource: key ? 'tab-keyboard' : 'play-button',
          accountId: currentAccount.id,
        })
        setIsPlaying(true)
      }
    }

    const handleVolume = (event: React.ChangeEvent<HTMLInputElement>) => {
      sevolume(Number(event.target.value))
      const originalValue = parseFloat(event.target.value)
      const convertedValue = originalValue / 100 // Convert to the range of 0 to 1
      audioRef.current!.volume = Number(convertedValue.toFixed(1))
      // ReactGA.event({
      //   action: "volume changed",
      //   category: "player",
      // })
      customEvent('player_volume_changed', {
        category: 'player',
        transcriptId: userData.transcript.id,
        user_userId: userDataId,
        accountId: currentAccount.id,
      })
    }
    const handleShowBar = () => {
      setShowVolumebar(!showVolumebar)
    }
    useEffect(() => {
      // Set a timeout to hide the volume bar after 500 milliseconds
      const timeoutId = setTimeout(() => {
        setShowVolumebar(false)
      }, 2000)
      // Uncomment the line below to clear the timeout
      return () => clearTimeout(timeoutId)
    }, [showVolumebar, volume])

    const setTime = (time: number) => {
      audioRef.current!.currentTime = time
      // SetCurrentTimeState(time);
      dispatch(setCurrentTime(time))
      // ReactGA.event({
      //   action: "word selected",
      //   category: "editor",
      // })
    }
    // useEffect(() => {
    //   // console.log(audioRef.current && Math.round(audioRef.current.currentTime), Math.round(currentTimeRe.currentTimeRe));
    //   if (audioRef.current && Math.abs(Math.round(audioRef.current.currentTime) - Math.round(currentTimeRe.currentTimeRe)) > 1) {
    //     setTime(currentTimeRe.currentTimeRe);
    //   }
    // }, [currentTimeRe]);

    useImperativeHandle(ref, () => ({
      setTime,
      togglePlay,
      isPlaying,
      pause,
      play,
    }))

    const duration = (audioRef.current && audioRef.current.duration) || 0
    const progress = duration !== 0 ? currentTime / duration : 0
    const playerSpeedOptions = [
      { label: '0.5x', value: '0.5' },
      { label: '0.75x', value: '0.75' },
      { label: '1x', value: '1' },
      { label: '1.25x', value: '1.25' },
      { label: '1.5x', value: '1.5' },
      { label: '1.75x', value: '1.75' },
      { label: '2x', value: '2' },
    ]

    const setPlayerSpeedfun = (value: string | undefined) => {
      if (value) {
        if (audioRef.current) {
          audioRef.current.playbackRate = parseFloat(value)
          handlePlayerSpeedFromAudio(value)
        }
        setPlayerSpeed(value)
        customEvent('player_speed_changed', {
          category: 'player',
          transcriptId: userData.transcript.id,
          user_userId: userDataId,
          actionDestination: value + 'x',
          accountId: currentAccount.id,
        })
      }
    }

    const handleToggleTimecode = () => {
      handleTimeToggle()
    }

    const handleEditBtn = (
      ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      ev.preventDefault()
      onEditBtnClick()
    }

    const handleTogglePlay = (
      ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      ev.preventDefault()
      togglePlay()
    }

    const handleClickOpen = () => {
      setSetOpenShortcut(!setOpenShortcut)
      customEvent('editor_shortcuts_modal_opened', {
        category: 'editor',
        transcriptId: userData.transcript.id,
        user_userId: userDataId,
        accountId: currentAccount.id,
      })
    }

    const fetchPlaybackUrl = async () => {
      try {
        const playbackUrl = await storage
          .refFromURL(playbackGsUrl)
          .getDownloadURL()
        setPlaybackUrl(playbackUrl)
      } catch (error) {
        console.error('Error fetching Playback URL: ', error)
        // ReactGA.exception({
        //   description: error.message,
        //   fatal: false,
        // })
        ReactGA.gtag('event', 'AudioPlayer_playbackUrl_exception', {
          description: (error as Error).message,
          fatal: false,
        })
      }
    }

    const play = () => {
      if (audioRef.current) {
        audioRef.current
          .play()
          .then(() => {
            const timer = setInterval(() => {
              handleTimeUpdateFunc()
            }, 100)
            setTimer(timer)
          })
          .catch((error) => {
            console.log('Error playing audio: ', error)
          })
        setIsPlaying(true)
      }
    }
    const handleTimeUpdateFunc = () => {
      if (audioRef.current) {
        const currentTime = audioRef.current.currentTime
        // setCurrentTime(currentTime)
        // handleTimeUpdate(currentTime)
        // SetCurrentTimeState(currentTime);
        dispatch(setCurrentTime(currentTime))
      }
    }

    if (playbackUrl === undefined) {
      return <div />
    }

    let totalDuration = nanoSecondsToFormattedTime(
      '00:00:00:00',
      duration * 1e9,
      true,
      false,
    )
    if (timeCodePreferrence) {
      totalDuration = nanoSecondsToFormattedTime(
        timecode,
        duration * 1e9,
        true,
        false,
      )
    }

    let currentTimeCode = nanoSecondsToFormattedTime(
      '00:00:00:00',
      currentTime * 1e9,
      true,
      false,
    )
    if (timeCodePreferrence) {
      currentTimeCode = nanoSecondsToFormattedTime(
        timecode,
        currentTime * 1e9,
        true,
        false,
      )
    }
    return (
      <>
        <audio ref={audioRef} src={playbackUrl || ''} />
        <div className="transcript-details-player-container1 relative">
          <div
            data-tut="Player-tour"
            className="flex items-center w-full justify-center max-w-5xl gap-4 mr-auto ml-auto"
          >
            <ShortcutDialog
              open={setOpenShortcut}
              handleOpen={handleClickOpen}
            />
            {/* @ts-ignore */}
            <IconButton
              onClick={handleTogglePlay}
              data-cy="audioPlayer-play-pause-button"
              className="playPauseButton shadow-none rounded-full w-9 h-9 mr-1 bg-transparent text-lstnGreen-500"
            >
              <span
                role="img"
                data-cy="audioPlayer-play-pause-button-span"
                aria-label={!isPlaying ? 'Spill av' : 'Pause'}
              >
                <svg
                  width="40"
                  height="40"
                  focusable="false"
                  aria-hidden="true"
                >
                  <use
                    xlinkHref={!isPlaying ? '#icon-play-c' : '#icon-pause-c'}
                  />
                </svg>
              </span>
            </IconButton>
            <div>
              <div className="flex items-center gap-3">
                <Typography className="text-xs font-medium text-lstnBlueGray-500 mb-0">
                  Timecode
                </Typography>
                {(transcript.projectId !== '' &&
                  verifyProjectRole('transcript')?.includes(roleType.EDIT)) ||
                transcript.projectId == '' ? (
                  <div>
                    <Button
                      onClick={handleEditBtn}
                      className="playPauseButton text-xs font-medium shadow-none p-0 text-lstnGreen-500 bg-transparent"
                    >
                      Edit
                    </Button>
                  </div>
                ) : null}
              </div>
              <Typography className="text-sm font-normal text-lstnBlueGray-500 mb-0">
                {currentTimeCode}
              </Typography>
              <div>
                {timecode && visibleToggle && (
                  <div className="toggle-container">
                    <CustomSwitch
                      // @ts-ignore
                      size="sm"
                      checked={timeCodePreferrence}
                      onChange={handleToggleTimecode}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex justify-center items-center p-0">
              <Progress
                value={progress * 100}
                color="green"
                variant="filled"
                size="md"
              />
            </div>
            <div className="duration-text-container">
              <Typography className="text-xs font-medium text-lstnBlueGray-500 mb-0">
                {t('player.totalDuration')}
              </Typography>
              <Typography className="text-sm font-normal text-lstnBlueGray-500 mb-0">
                {totalDuration}
              </Typography>
            </div>
            <div
              data-cy="audio-volume"
              className="flex items-center gap-2 relative"
            >
              <Button
                className="bg-transparent p-0 w-5 h-5 shadow-none drop-shadow-none"
                onClick={handleShowBar}
              >
                <img src={Volume} alt="img" width={18} height={14} />
              </Button>
              {showVolumebar && (
                <div className="absolute bottom-[4.2rem] left-[-2.90rem] rotate-[270deg] bg-blue-gray-50 p-2 rounded-full">
                  <Slider
                    defaultValue={volume}
                    size="md"
                    className="text-lstnGreen-500 min-w-[85px] h-1.5"
                    barClassName="bg-[#25DA3C]"
                    thumbClassName="[&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:w-2 [&::-webkit-slider-thumb]:h-2 [&::-webkit-slider-thumb]:mt-[0px] [&::-webkit-slider-thumb]:ring-2"
                    onChange={handleVolume}
                    placeholder={undefined}
                    data-cy="audio-volume-show"
                  />
                </div>
              )}
            </div>
            <Selector
              options={playerSpeedOptions}
              onChange={setPlayerSpeedfun}
              selectedValue={playerSpeed}
              selectClassName="border-none"
              className="border-none relative text-xs font-bold w-20 min-w-[80px] text-blue-gray-500 uppercase bg-blue-gray-50 rounded-lg "
              children={undefined}
            />
            {/* <Dropdown options={playerSpeedOptions} onSelect={setPlayerSpeedfun} alternateText="Speed" selected={playerSpeed} /> */}
          </div>
          <Button
            data-tut="Shortcut-tour"
            className="hidden shortcutIcon text-xs font-bold uppercase bg-white text-lstnBlueGray-500 border border-black rounded-lg w-32 h-9 lg:flex xl:flex 2xl:flex items-center justify-center right-2"
            onClick={handleClickOpen}
          >
            <Extension />
            {t('player.shortcut')}
          </Button>
        </div>
      </>
    )
  },
)

// const mapStateToProps = (state:) => ({
//   videoPlayCurrentTime: state.TimeReducer,
// });

// const mapDispatchToProps =  (dispatch: ) =>{
//   return {
//   SetCurrentTimeState: (currentTime:) => dispatch(SetCurrentTimeState(currentTime))
//   }
// };
export default Player
// const ConnectedAudioPlayer = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Player);
// export default forwardRef<audioPlayerMethods, IProps>((props, ref) => (
//   <ConnectedAudioPlayer {...props} ref={ref} />
// ));
