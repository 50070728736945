import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Select, Option } from '@material-tailwind/react'
import type { SelectProps } from '@material-tailwind/react'

interface IProps {
  options: Array<{
    value: string
    label: string
    disabled?: boolean
    color?: string
  }>
  selectedValue?: string
  onChange: (value: string) => void
  handleMarkClick?: (e: React.MouseEvent<HTMLElement>) => void
  selectClassName?: string
  placeholder?: string
  menuClassName?: string
}

const Selector: React.FC<IProps & SelectProps> = (props) => {
  const [isLabelHidden, setIsLabelHidden] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)

  const handleSelectClick = () => {
    console.log(props.selectedValue, 'check')
    setIsLabelHidden(true)
  }
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(e.target as Node)) {
        if (props.selectedValue == '') {
          setIsLabelHidden(false)
        }
      }
    },
    [props.selectedValue],
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <div className={props.className} ref={selectRef}>
      <Select
        onChange={props.onChange}
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
        className={props.selectClassName}
        value={props.selectedValue}
        labelProps={{
          className: isLabelHidden
            ? 'hidden'
            : 'border-none before:border-none',
        }}
        containerProps={{
          className: props.className,
        }}
        label={props.placeholder}
        placeholder={props.placeholder}
        onClick={handleSelectClick}
        menuProps={{
          className: props.menuClassName,
        }}
        data-cy="player-speed-dropdown"
      >
        {props.options &&
          props.options.map((option) => (
            <Option
              key={option.value}
              className="z-10"
              value={option.value}
              disabled={option.disabled}
              data-cy={`data-project-export-file-type-${option.value}`}
            >
              {option.label}
            </Option>
          ))}
      </Select>
    </div>
  )
}

export default Selector
