import React, { useCallback } from 'react'
import { Button } from '@material-tailwind/react'
import { CloseIcon } from '../../SvgIcons'
import { customEvent } from '../../../utils/customHooks'
import { useAppSelector } from '../../../store/store'

export interface IMenuHeaderProps {
  setMenuOne: React.Dispatch<React.SetStateAction<boolean>>
  setMenuTwo: React.Dispatch<React.SetStateAction<boolean>>
  setMenuThree: React.Dispatch<React.SetStateAction<boolean>>
  setButtonBgColor1: React.Dispatch<React.SetStateAction<string>>
  setButtonBgColor2: React.Dispatch<React.SetStateAction<string>>
  setButtonBgColor3: React.Dispatch<React.SetStateAction<string>>
  setStrokeColor1: React.Dispatch<React.SetStateAction<string>>
  setStrokeColor2: React.Dispatch<React.SetStateAction<string>>
  setStrokeColor3: React.Dispatch<React.SetStateAction<string>>
  headerName: string
  userId: string
}

const MenuHeader: React.FC<IMenuHeaderProps> = ({
  setMenuOne,
  setMenuTwo,
  setMenuThree,
  setButtonBgColor1,
  setButtonBgColor2,
  setButtonBgColor3,
  setStrokeColor1,
  setStrokeColor2,
  setStrokeColor3,
  headerName,
  userId,
}) => {
  const { currentAccount } = useAppSelector((store) => store.account)

  const actionSourceName = useCallback(() => {
    if (headerName === 'About Transcript') return 'action-info'
    if (headerName === 'AI Assistant') return 'action-ai-assistant'
    if (headerName === 'Comments') return 'action-comments'
    return ''
  }, [headerName])

  return (
    <div className="w-full flex flex-row justify-between items-center px-4 py-3 border-b-2">
      <h2 className="text-lg font-sans font-bold">{headerName}</h2>
      <Button
        variant="text"
        className="border-none hover:bg-white p-0"
        onClick={() => {
          setMenuThree(false)
          setMenuOne(false)
          setMenuTwo(false)
          setButtonBgColor1('bg-white')
          setButtonBgColor2('bg-white')
          setButtonBgColor3('bg-white')
          setStrokeColor1('#607D8B')
          setStrokeColor2('#607D8B')
          setStrokeColor3('#607D8B')
          customEvent('actions_sidepanel_toggled', {
            category: 'ActionsSidepanelEvents',
            user_userId: userId,
            accountId: currentAccount.id,
            actionSource: actionSourceName(),
            actionMetadata: 'closed',
          })
        }}
        placeholder={undefined}
      >
        <CloseIcon />
      </Button>
    </div>
  )
}

export default MenuHeader
