export const formateDuration = (durationInSeconds: number) => {
  const hours = Math.floor(durationInSeconds / 3600)
  const remainingSeconds = durationInSeconds % 3600
  const minutes = Math.floor(remainingSeconds / 60)
  const seconds = remainingSeconds % 60

  let formattedDuration = ''

  if (hours > 0) {
    formattedDuration += `${hours} hour`
    if (hours > 1) formattedDuration += 's'
    formattedDuration += ' '
    formattedDuration += `${minutes} min`
    if (minutes > 1 || seconds === 0) formattedDuration += 's'
    formattedDuration += ' '
  } else {
    formattedDuration += `${minutes} min`
    if (minutes > 1 || seconds === 0) formattedDuration += 's'
    formattedDuration += ' '
  }

  if (seconds > 0 || (hours === 0 && minutes === 0)) {
    formattedDuration += `${seconds.toFixed(0)} sec`
    if (seconds > 1) formattedDuration += 's'
    formattedDuration += ' '
  }

  return formattedDuration.trim()
}
