import React, { useState } from 'react'
import { Card, Input, Button } from '@material-tailwind/react'
import Typography from '../components/Typography/Typography'
import Selector from '../components/SelectInput/Select'
import { database } from '../firebaseApp'
import firebase from 'firebase/compat/app'
import { Dialog, DialogBody, DialogFooter } from '@material-tailwind/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MultipleSelectChip from '../components/SelectInput/MultipleSelect'
import { useAppSelector } from '../store/store'
import CircularProgress from '@material-ui/core/CircularProgress'
import { customEvent } from '../utils/customHooks'

interface IProps {
  user?: firebase.UserInfo
}
const CreateAccount: React.FC<IProps> = ({
  // match,
  user,
}) => {
  const [companySize, setCompanySize] = useState<string>('')
  const [role, setRole] = useState<string>('')
  const [industry, setIndustry] = useState<string>('')
  const [useCase, setUseCase] = useState<string[]>([])
  const [open, setOpen] = React.useState(false)
  const { userData } = useAppSelector((store) => store.user)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  // Accessing the inviterId parameter from the URL
  const { inviteId } = useParams()
  // Get the organization ID
  const accountId =
    userData && userData.accounts && Object.keys(userData.accounts)[0]

  const roleOptions = [
    {
      value: 'Media Producer',
      label: t && t('createAccount.roleOptions.mediaProducer'),
    },
    {
      value: 'Movie / Documentary Director',
      label: t && t('createAccount.roleOptions.movieDirector'),
    },
    {
      value: 'Video editor',
      label: t && t('createAccount.roleOptions.videoEditor'),
    },
    {
      value: 'Researcher',
      label: t && t('createAccount.roleOptions.researcher'),
    },
    {
      value: 'Content creator',
      label: t && t('createAccount.roleOptions.contentCreator'),
    },
    { value: 'Other', label: t && t('createAccount.roleOptions.other') },
  ]
  const industryOptions = [
    {
      value: 'Broadcasting',
      label: t && t('createAccount.industryOptions.broadcasting'),
    },
    {
      value: 'Media production (movies, documentaries, …)',
      label: t && t('createAccount.industryOptions.mediaProduction'),
    },
    {
      value: 'Education',
      label: t && t('createAccount.industryOptions.education'),
    },
    {
      value: 'Software',
      label: t && t('createAccount.industryOptions.software'),
    },
    {
      value: 'Advertising & Marketing',
      label: t && t('createAccount.industryOptions.advertising'),
    },
    { value: 'Other', label: t && t('createAccount.roleOptions.other') },
  ]
  const companySizeOptions = [
    { value: 'Myself only', label: t && t('createAccount.myself-only') },
    { value: 'Up to 10', label: 'Up to 10' },
    { value: '11 to 50', label: '11 to 50' },
    { value: '51 to 200', label: '51 to 200' },
    { value: '201 to 500', label: '201 to 500' },
    { value: '501 to 2000', label: '501 to 2000' },
    { value: '2000+', label: '2000+' },
  ]
  const useCaseOptions = [
    {
      value: 'Transcribe my audio/video footage',
      label: t && t('createAccount.useCaseOptions.transcribeAudio'),
    },
    {
      value: 'Create subtitles',
      label: t && t('createAccount.useCaseOptions.createSubtitles'),
    },
    {
      value: 'Translate my content to other languages',
      label: t && t('createAccount.useCaseOptions.translateLanguages'),
    },
    { value: 'Other', label: t && t('createAccount.roleOptions.other') },
  ]
  const handleCompanyChange = (value: string | undefined): void => {
    if (value) {
      setCompanySize(value)
    }
  }
  const handleIndustryChange = (value: string | undefined): void => {
    if (value) {
      setIndustry(value)
    }
  }
  const handleRoleChange = (value: string | undefined): void => {
    if (value) {
      setRole(value)
    }
  }
  const handleUseCase = (value: string[]): void => {
    if (value) {
      setUseCase(value)
    }
  }

  const handlesubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData(event.currentTarget)
    // Convert FormData to a plain object
    const formObject: Record<string, string> = {}
    formData.forEach((value, key) => {
      formObject[key] = value.toString()
    })
    try {
      // const docRef = database.collection('organizations').doc(organizationId)
      // docRef
      //   .set(
      //     {
      //       name: formData.get('name'),
      //       size: companySize,
      //       industry: industry,
      //       usecases: useCase,
      //     },
      //     { merge: true },
      //   )
      const payload = {
        data: {
          accountId: accountId,
          name: formData.get('name'),
          size: companySize,
          industry: industry,
          usecases: useCase,
          creatorOnboardingUserRole: role,
        },
      }
      const token = await firebase.auth().currentUser?.getIdToken()
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_API}/accounts/update`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        },
      )
      // console.log(response)
      if (response.status === 200) {
        if (user) {
          customEvent('account_created', {
            category: 'accounts',
            accountId: accountId,
            user_userId: user.uid,
            actionSource: 'from-new-signup',
          })
          customEvent('account_onboarding_info_provided', {
            category: 'accounts',
            user_userId: user.uid,
            accountId: accountId,
          })
        }
        navigate('/transcripts')
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
    // event.currentTarget.reset()
  }
  const handleSubmitmembers = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    // Convert FormData to a plain object
    const formObject: Record<string, string> = {}
    formData.forEach((value, key) => {
      formObject[key] = value.toString()
    })
    try {
      const organizationRef = database.collection('organizations').doc(inviteId)
      const organizationFetch = await organizationRef.get()
      const organizationData = organizationFetch.data()

      if (organizationData && organizationData.users) {
        if (user) {
          const newUser = {
            [`users.${user.uid}`]: {
              status: 'active',
              role: role,
            },
          }
          await organizationRef
            .update(newUser)
            .then(() => {
              console.log('New user added successfully')
              // history.push('/transcripts')
            })
            .catch((error) => {
              console.error('Error adding new user: ', error)
            })
          Object.keys(organizationData.users).forEach((userId) => {
            const organizationUser = organizationData.users[userId]
            // Check if the role is "owner"
            if (organizationUser.role === 'owner') {
              // console.log('User with role "owner" found. User ID:', userId);
              const userDataQuery = database.collection('users').doc(userId)
              if (userData && userData.organizations) {
                const newOrganization = {
                  [`organizations.${user.uid}`]: {
                    status: 'active',
                    role: role,
                  },
                }
                userDataQuery
                  .update(newOrganization)
                  .then(() => {
                    console.log('New organization added successfully')
                    navigate('/transcripts')
                  })
                  .catch((error) => {
                    console.error('Error adding new organization: ', error)
                  })
              }
            }
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleOpen = (): void => setOpen(!open)
  const handleSkip = (): void => {
    if (user) {
      customEvent('account_onboarding_info_skipped', {
        category: 'accounts',
        user_userId: user.uid,
        accountId: accountId,
      })
    }
    navigate('/transcripts')
  }
  return (
    <div className="h-screen flex items-center justify-center">
      <Card
        color="transparent"
        shadow={false}
        placeholder={undefined}
        className="w-full sm:w-[450px]"
      >
        <div className="w-full px-6 overflow-auto my-6">
          <Typography
            variant="h4"
            color="blue-gray"
            className="text-2xl font-semibold text-center text-blue-gray-900"
          >
            {/* Before We Dive in, Help Us in <br /> Understanding You Better */}
            {t && t('createAccount.title')}
          </Typography>
          {!inviteId ? (
            <form className="mt-4 mb-2 w-full" onSubmit={handlesubmit}>
              <div className="mb-1 flex flex-col gap-3">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="text-sm font-medium text-blue-gray-900"
                >
                  {t('createAccount.companyName')}
                </Typography>
                <Input
                  size="md"
                  type="text"
                  placeholder="X Factor"
                  name="name"
                  className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                  labelProps={{
                    className: 'hidden',
                  }}
                  crossOrigin={undefined}
                  data-cy="data-account-company-name"
                />
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="text-sm font-medium text-blue-gray-900"
                >
                  {t('createAccount.companySize')}
                </Typography>
                <div data-cy="data-account-company-size">
                  <Selector
                    selectClassName="border border-blue-gray-100 rounded-lg"
                    className="border-none relative text-xs  font-bold w-auto text-blue-gray-500  rounded-lg "
                    options={companySizeOptions}
                    selectedValue={companySize}
                    onChange={handleCompanyChange}
                    children={undefined}
                    name="companySize"
                    placeholder={t && t('createAccount.companySizePlaceholder')}
                  />
                </div>
                <div className="flex flex-wrap gap-3 sm:gap-0">
                  <div className="w-full sm:w-1/2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 text-sm font-medium text-blue-gray-900"
                    >
                      {t && t('createAccount.industry')}
                    </Typography>
                    <div data-cy="data-account-industry">
                      <Selector
                        selectClassName="border border-blue-gray-100 rounded-lg"
                        className="border-none relative text-xs sm:pr-1 font-bold w-auto text-blue-gray-500 rounded-lg "
                        options={industryOptions}
                        selectedValue={industry}
                        onChange={handleIndustryChange}
                        children={undefined}
                        name="industry"
                        placeholder={
                          t && t('createAccount.industryPlaceholder')
                        }
                      />
                    </div>
                    {/* <div className={industry === 'Other' ? 'block mt-2' : 'hidden'}>
                      <Input
                        size="md"
                        type="text"
                        placeholder="Other..."
                        name="otherIndustry"
                        className="!border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100 "
                        labelProps={{
                          className: 'hidden',
                        }}
                        crossOrigin={undefined}
                      />
                    </div> */}
                  </div>
                  <div className="w-full sm:w-1/2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 text-sm font-medium text-blue-gray-900"
                    >
                      {t && t('createAccount.role')}
                    </Typography>
                    <div data-cy="data-account-your-role">
                      <Selector
                        selectClassName="border border-blue-gray-100 rounded-lg"
                        className="border-none relative text-xs font-bold w-auto text-blue-gray-500 rounded-lg "
                        options={roleOptions}
                        selectedValue={role}
                        onChange={handleRoleChange}
                        children={undefined}
                        name="role"
                        placeholder={t && t('createAccount.rolePlaceholder')}
                      />
                    </div>
                    {/* <div className={role === 'Other' ? 'block mt-2' : 'hidden'}>
                      <Input
                        size="md"
                        type="text"
                        placeholder="Other..."
                        name="otherRole"
                        className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                        labelProps={{
                          className: 'hidden',
                        }}
                        crossOrigin={undefined}
                      />
                    </div> */}
                  </div>
                </div>
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="text-sm font-medium text-blue-gray-900"
                >
                  {t && t('createAccount.useCase')}
                </Typography>
                {/* <Selector
                  selectClassName="border border-blue-gray-100 rounded-lg"
                  className="border-none relative text-xs  font-bold w-auto text-blue-gray-500 rounded-lg "
                  options={useCaseOptions}
                  selectedValue={useCase}
                  onChange={handleUseCase}
                  children={undefined}
                  name="useLstn"
                /> */}
                <MultipleSelectChip
                  options={useCaseOptions}
                  selectedValue={useCase}
                  onChange={handleUseCase}
                />
                {/* <div className={useCase === 'Other' ? 'block mt-2' : 'hidden'}>
                  <Input
                    size="md"
                    placeholder="Transcribe my audio/video"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900 placeholder:opacity-100"
                    labelProps={{
                      className: 'before:content-none after:content-none',
                    }}
                    crossOrigin={undefined}
                    name="useLstnForOther"
                  />
                </div> */}
              </div>
              <Button
                type="submit"
                className="mt-6 text-sm flex items-center justify-center font-bold bg-lstnGreen-500 uppercase shadow-sm"
                size="md"
                fullWidth
                placeholder={undefined}
                disabled={loading}
                data-cy="data-account-continue"
              >
                {loading ? (
                  <CircularProgress
                    size={18}
                    color="inherit"
                    className="mr-2"
                  />
                ) : (
                  ''
                )}
                {loading
                  ? t('createAccount.creatingAccount')
                  : t && t('createAccount.continue')}
              </Button>
              <div className="text-center mt-6" data-cy="data-account-skip">
                <Button
                  onClick={handleOpen}
                  variant="text"
                  className="text-xs font-bold hover:bg-transparent uppercase"
                  placeholder={undefined}
                  disabled={loading}
                >
                  {t && t('createAccount.skip')}
                </Button>
                <Dialog
                  open={open}
                  size="xs"
                  handler={handleOpen}
                  placeholder={undefined}
                >
                  <DialogBody placeholder={undefined}>
                    {t && t('createAccount.dialogContent')}
                  </DialogBody>
                  <DialogFooter placeholder={undefined}>
                    <Button
                      variant="text"
                      onClick={handleOpen}
                      className="mr-1 text-xs font-bold"
                      placeholder={undefined}
                      data-cy="data-account-skip-cancel"
                    >
                      <span>{t && t('startTourDialog.cancel')}</span>
                    </Button>
                    <Button
                      variant="text"
                      className="bg-lstnGreen-500 text-xs font-bold text-white"
                      onClick={handleSkip}
                      placeholder={undefined}
                      data-cy="data-account-skip-confirm"
                    >
                      <span>{t && t('createAccount.confirm')}</span>
                    </Button>
                  </DialogFooter>
                </Dialog>
              </div>
            </form>
          ) : (
            <form className="mt-4 mb-2 w-full" onSubmit={handleSubmitmembers}>
              <div className="mb-1 flex flex-col gap-3">
                <Typography
                  variant="h6"
                  color="blue-gray"
                  className="text-sm font-medium text-blue-gray-900"
                >
                  {t && t('createAccount.role')}
                </Typography>
                <Selector
                  selectClassName="border border-blue-gray-100 rounded-lg"
                  className="border-none relative text-xs  font-bold w-auto text-blue-gray-500  rounded-lg "
                  options={roleOptions}
                  selectedValue={role}
                  onChange={handleRoleChange}
                  children={undefined}
                  name="companySize"
                />
                {/* <div className={role === 'Other' ? 'block mt-2' : 'hidden'}>
                  <Input
                    size="md"
                    type="text"
                    placeholder="Other..."
                    name="otherRole"
                    className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                    labelProps={{
                      className: 'hidden',
                    }}
                    crossOrigin={undefined}
                  />
                </div> */}
              </div>

              <Button
                type="submit"
                className="mt-6 text-sm font-bold bg-lstnGreen-500 uppercase shadow-sm"
                size="lg"
                fullWidth
                placeholder={undefined}
                loading={loading}
                data-cy="data-account-continue"
              >
                {t && t('createAccount.continue')}
              </Button>
              <div className="text-center mt-6" data-cy="data-account-skip">
                <Button
                  onClick={handleOpen}
                  variant="text"
                  className="text-xs font-bold hover:bg-transparent uppercase"
                  placeholder={undefined}
                  disabled={loading}
                >
                  {t && t('createAccount.skip')}
                </Button>
                <Dialog
                  open={open}
                  size="xs"
                  handler={handleOpen}
                  placeholder={undefined}
                >
                  <DialogBody placeholder={undefined}>
                    {t && t('createAccount.dialogContent')}
                  </DialogBody>
                  <DialogFooter placeholder={undefined}>
                    <Button
                      variant="text"
                      onClick={handleOpen}
                      className="mr-1 text-xs font-bold"
                      placeholder={undefined}
                      data-cy="data-account-skip-cancel"
                    >
                      <span>{t && t('startTourDialog.cancel')}</span>
                    </Button>
                    <Button
                      variant="text"
                      className="bg-lstnGreen-500 text-xs font-bold text-white"
                      onClick={handleSkip}
                      placeholder={undefined}
                      data-cy="data-account-skip-confirm"
                    >
                      <span>{t && t('createAccount.confirm')}</span>
                    </Button>
                  </DialogFooter>
                </Dialog>
              </div>
            </form>
          )}
        </div>
      </Card>
    </div>
  )
}

export default CreateAccount
