import React from 'react'
// import Loader from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Lottie from 'lottie-react'
import waterAnimation from '../waterAnimation.json'
import paymentAnimation from '../payment-loading-animation.json'
import Typography from './Typography/Typography'

export default () => {
  return (
    <div className="spash-container">
      {/* <Loader type="Oval" color="#04c61d" height={100} width={100} /> */}
      <Lottie
        animationData={waterAnimation}
        loop={true}
        style={{ width: '16%', height: '16%' }}
      />
    </div>
  )
}

export const PaymentSplashScreen = () => {
  return (
    <div className="spash-container">
      <div className="block">
        <div className="flex justify-center">
          <Lottie
            animationData={paymentAnimation}
            loop={true}
            style={{ width: '55%', height: '55%' }}
          />
        </div>
        <div className="text-center" style={{ marginTop: -35 }}>
          <Typography color="black" className="text-base font-bold">
            Loading a secure payment portal
          </Typography>
          <Typography
            color="black"
            className="text-base font-normal animate-pulse"
          >
            Please wait
          </Typography>
        </div>
      </div>
    </div>
  )
}
