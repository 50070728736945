import React, { useCallback } from 'react'
import { Chip } from '@material-tailwind/react'
import Flag from 'react-flagkit'
import { database } from '../../firebaseApp'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { customEvent } from '../../utils/customHooks'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'
import { fetchTranslationList } from '../../store/features/transcript/helper'

function Icon({ code }: { code: string }) {
  return <Flag country={code} size={15} />
}
export function ChipIcon({
  code,
  value,
  handleClick,
  parameterValue,
  showCloseIcon,
  bgClass,
}: {
  code: string
  value: string
  handleClick: (arg0: string) => void
  parameterValue: string
  showCloseIcon: boolean
  bgClass: string
}) {
  // const [open, setOpen] = React.useState(true)
  const transcript = useAppSelector((store) => store.paragraphsSlice)
  const { userData } = useAppSelector((store) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const rendercode = useCallback(
    (code: string) => {
      switch (code) {
        case 'en-GB':
          return 'gb'
        case 'en-US':
          return 'us'
        case 'es-AR':
          return 'es'
        case 'pt-BR':
          return 'pt'
        case 'pt-PT':
          return 'pt'
        case 'ja':
          return 'de'
        case 'cs':
          return 'ca'
        case 'el':
          return 'se'
        default:
          return code
      }
    },
    [code],
  )

  const handleDialogOpen = () => {
    setDialogOpen(!dialogOpen)
  }

  const handleClose = async () =>
    // e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    {
      // e.stopPropagation()
      // setOpen(false)
      const subCollectionRef = database.collection(
        `transcripts/${transcript?.id}/${parameterValue}`,
      )
      try {
        const snapshot = await subCollectionRef.get()
        // Check if the subcollection has any documents
        if (snapshot.empty) {
          console.log('No documents found in the subcollection.')
          return
        }
        const batch = database.batch()
        snapshot.docs.forEach((doc) => {
          batch.delete(doc.ref)
        })

        // Commit the batch delete operation
        await batch.commit()
        customEvent('transcript_translated_deleted', {
          transcriptId: transcript.id,
          projectId: transcript.projectId,
          user_userId: userData.id,
          accountId: currentAccount.id,
        })
        console.log('Subcollection deleted successfully.')
        handleClick('paragraphs')
        handleDialogOpen()
        dispatch(fetchTranslationList(transcript?.id!))
        // console.log('DEl')
      } catch (error) {
        console.log(error)
      }
    }
  const _handleOpen = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    handleDialogOpen()
  }
  return (
    <div
      className="flex gap-2 cursor-pointer"
      onClick={() => handleClick(parameterValue)}
    >
      <Chip
        // open={open}
        value={value?.toUpperCase() ? value?.toUpperCase() : value}
        icon={<Icon code={code ? rendercode(code).toUpperCase() : 'US'} />}
        variant="ghost"
        className={`rounded-bl-none rounded-b-none translationFlag ${bgClass}`}
        // @ts-ignore
        onClose={showCloseIcon ? _handleOpen : undefined}
      />

      <Dialog
        open={dialogOpen}
        size="xs"
        handler={handleDialogOpen}
        placeholder={undefined}
        className="px-4"
      >
        <DialogHeader placeholder={undefined}>
          {t && t('transcripts.delete')}
        </DialogHeader>
        <DialogBody placeholder={undefined}>
          {t && t('transcripts.confirmation')}
        </DialogBody>
        <DialogFooter placeholder={undefined}>
          <Button
            placeholder={undefined}
            variant="text"
            color="black"
            onClick={() => handleDialogOpen()}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            placeholder={undefined}
            variant="text"
            color="red"
            onClick={handleClose}
          >
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  )
}
