import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Toast from '../../components/Toast/Toast'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'
import '../../css/uploader.css'
import { useTranslation } from 'react-i18next'

interface IProps {
  headerText?: string
  delBtn?: true | false
  copyBtn?: boolean
  bodyText?: string
  primaryLabel?: string
  open: boolean
  onClose: (value?: boolean) => void
  noSecondaryAction?: boolean
  cy: string
}

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    // @ts-ignore
    return <Slide direction="up" ref={ref} {...props} />
  },
)

export default function ConfirmationDialog({
  headerText,
  delBtn,
  copyBtn,
  bodyText,
  primaryLabel,
  open,
  onClose,
  noSecondaryAction,
  cy,
}: IProps) {
  const { t } = useTranslation()
  const handleClose = (status?: boolean) => () => {
    onClose(status)
  }

  const handleCopy = () => {
    if (typeof bodyText === 'string') {
      navigator.clipboard.writeText(bodyText)
      Toast({ text: t && t('transcripts.copyConfirm'), variant: 'success' })
    }
  }

  return (
    <div className="ConfirmationDialogue">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        data-cy={`data-project-${cy}-dialog`}
      >
        <DialogTitle id="alert-dialog-slide-title">{headerText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {bodyText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!noSecondaryAction && (
            <Button
              onClick={handleClose(false)}
              className="black"
              data-cy={`data-project-${cy}-cancel`}
            >
              {t('renameDialog.cancel')}
            </Button>
          )}
          {delBtn && (
            <Button
              onClick={handleClose(true)}
              className="red"
              variant="outlined"
              data-cy={`data-project-${cy}-submit`}
            >
              {primaryLabel}
            </Button>
          )}
          {copyBtn && (
            <Button onClick={handleCopy} color="secondary" variant="outlined">
              {primaryLabel}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}
