import debounce from 'lodash.debounce'
import React, { useEffect, useState } from 'react'
// import ReactGA from 'react-ga4'
// import { connect } from "react-redux"
// import { StaticContext } from 'react-routimpoimport { Navigate,useLocation } from 'react-router-dom'
import {
  useNavigate,
  useLocation,
  NavigationType,
  useNavigationType,
} from 'react-router-dom'
// import { Dispatch } from 'redux'
import firebase from 'firebase/compat/app'
// import { fetchUserStatus } from "../store/actions/userStatus"
// import { IRootState } from "../store/reducers/rootReducer"
// import { IUserStatusState } from "../store/reducers/userStatus"
import SplashScreen from '../components/SplashScreen'
// import { functions } from '../firebaseApp'
// import { planData } from '../utils/plans'
// import getProvider from '../utils/provider'
import { fetchUserById, helperUserData } from '../store/features/userSlice'
import { useAppDispatch, useAppSelector } from '../store/store'
// import * as QueryString from 'query-string'
// import { getCountryCode } from '../utils/customHooks'
// import { fetchAccount } from '../store/features/userSlice'
import {
  fetchAccounts,
  fetchAllAccounts,
  setTeamSwitch,
} from '../store/features/accountSlice'
import { fetchAllProjects, fetchRoles } from '../store/features/projectSlice'
import { IUserStatus } from '../interfaces/IUser'

interface IProps {
  user?: firebase.User
  userStatus?: IUserStatus | undefined
  currentUser?: firebase.User | null
  children?: React.ReactNode
}
// tslint:disable: ordered-imports
const _PrivateRoute = ({ children }: IProps): React.ReactNode => {
  const {
    user,
    userStatus,
    userData,
    fetchStatusFailure,
    fetchStatusSuccess,
    roles,
  } = useAppSelector((store) => store.user)

  const [loading, setLoading] = useState<boolean>(true)
  // const [isStarted, setIsStarted] = useState<boolean>(false)
  const accountObject = localStorage.getItem('selectedAccount')
  const { accounts, currentAccount, teamSwitch } = useAppSelector(
    (store) => store.account,
  )
  // const { projects } = useAppSelector((store) => store.project)
  const [accountRole, setAccountRole] = useState<string[]>([])
  const location = useLocation()
  const { pathname } = location

  // Get the organization ID
  const isInvitedAccount =
    userData.accounts &&
    Object.keys(userData.accounts).filter(
      (key) => userData.accounts[key].role !== 'owner',
    )
  const accountId =
    userData && userData.accounts
      ? accountObject
        ? JSON.parse(accountObject).accountId
        : isInvitedAccount.length > 0
          ? isInvitedAccount[0]
          : userData.accounts && Object.keys(userData.accounts)[0]
      : ''

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  // const countryCode = getCountryCode()
  // const queries = QueryString.parse(location?.search)
  // const newUser = localStorage.getItem('lstn-init-user')
  const navType: NavigationType = useNavigationType()
  //@ts-ignore
  // const updatedUser = {
  //   ...user,
  //   country_code: countryCode,
  //   preferredLanguage: queries.lng || 'en',
  // }
  // useEffect(() => {
  //   const createUser = async () => {
  //     if (user && user.uid && Object.keys(userData).length === 0) {
  //       if (newUser === 'true' && !isStarted) {
  //         console.log('createUserWithSubscription')
  //         setIsStarted(true)
  //         await createUserWithSubscription()
  //       }
  //     }
  //   }
  //   createUser()
  // }, [user, newUser])

  useEffect(() => {
    if (currentAccount) {
      const userAccountRole =
        currentAccount?.users && currentAccount.users[user.uid]?.role
      if (userAccountRole) {
        setAccountRole([userAccountRole])
      }
    }
  }, [currentAccount, currentAccount?.users, user.uid])

  useEffect(() => {
    if (teamSwitch) {
      navigate('/transcripts')
      dispatch(setTeamSwitch(false))
    }
  }, [dispatch, teamSwitch])

  useEffect(() => {
    if (
      !teamSwitch &&
      accountRole.length > 0 &&
      accountRole.includes('guest') &&
      pathname.startsWith('/transcripts')
    ) {
      navigate('/projects')
    }
  }, [currentAccount, accountRole, pathname])

  useEffect(() => {
    if (firebase) {
      if (user) {
        setLoading(false)
      }
      if (
        navType === 'POP' &&
        location.pathname === '/create-account' &&
        user &&
        user.email &&
        user.email !== process.env.REACT_APP_CYPRESS_USER_EMAIL
      ) {
        navigate('/')
      }
      setTimeout(() => {
        if (!firebase.auth().currentUser) {
          navigate('/')
        }
      }, 2000)
    }
  }, [navigate, user, navType])

  useEffect(() => {
    if (
      (Object.keys(currentAccount).length === 0 || accounts.length === 0) &&
      accountId
    ) {
      fetchAccounts({ dispatch, id: accountId })
      dispatch(fetchAllAccounts({ dispatch }))
      fetchAllProjects(accountId, dispatch)
      fetchRoles(dispatch)
    }
  }, [dispatch, accountId, userData, currentAccount])

  useEffect(() => {
    if (
      (userStatus == undefined ||
        (userStatus && Object.keys(currentAccount).length === 0)) &&
      user &&
      user.uid &&
      // newUser !== 'true' &&
      !fetchStatusFailure &&
      !fetchStatusSuccess
    ) {
      // fetchStatus(user.uid)
      if (Object.keys(roles).length === 0) {
        dispatch(fetchUserById(user.uid))
      }
      helperUserData({ dispatch })
    }
    if (userStatus == undefined && fetchStatusSuccess && user) {
      debounceFetchStatus(user.uid)
    }
  }, [userStatus, fetchStatusSuccess, user, currentAccount])

  // const createUserWithSubscription = async () => {
  //   if (user) {
  //   //   // let gaClientId
  //   //   const providerLabel =
  //   //     localStorage.getItem('lstn-auth-provider') ||
  //   //     (user.providerData[0] !== null
  //   //       ? getProvider(user.providerData[0].providerId)
  //   //       : '')
  //   //   ReactGA.gtag('set', 'user_properties', {
  //   //     userId: user.uid,
  //   //   })

  //   //   customEvent('sign_up', {
  //   //     category: 'auth',
  //   //     authAttribute: providerLabel,
  //   //     user_userId: user.uid,
  //   //     accountId: accountId,
  //   //   })

  //   //   const selectedPlan =
  //   //     localStorage.getItem('lstn-plan') !== null
  //   //       ? // @ts-ignore
  //   //         planData[localStorage.getItem('lstn-plan')]
  //   //       : ''
  //   //   const createdAt =
  //   //     currentUser && currentUser.metadata
  //   //       ? currentUser.metadata.creationTime
  //   //       : ''
  //   //   const inviteId = localStorage.getItem('inviteId')
  //   //   const validityCode = localStorage.getItem('inviteValidityCode')
  //   //   const subscription = functions.httpsCallable('addSubscription')
  //   //   try {
  //   //     await subscription({
  //   //       ...updatedUser,
  //   //       selectedPlan: selectedPlan ? selectedPlan : '',
  //   //       createdAt,
  //   //       inviteId,
  //   //       validityCode: validityCode ? parseInt(validityCode, 10) : null,
  //   //     })
  //   //     localStorage.removeItem('lstn-init-user')
  //   //     localStorage.removeItem('lstn-plan')
  //   //     if (inviteId && validityCode) {
  //   //       localStorage.removeItem('inviteId')
  //   //       localStorage.removeItem('inviteValidityCode')
  //   //     }
  //       // fetchStatus(user.uid)
  //       dispatch(fetchUserById(user.uid))
  //       console.log('&&&&')
  //     // } catch (err) {
  //     //   console.error(err)
  //     // }
  //   }
  // }

  const debounceFetchStatus = debounce(fetchUserById, 1500)

  if (loading) {
    return <SplashScreen />
  }

  if (user && user.uid) {
    if (Object.keys(currentAccount).length === 0) {
      return <SplashScreen />
    }
    return children
  }
  return <SplashScreen />
}

export const PrivateRoute = _PrivateRoute
