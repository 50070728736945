import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  List,
  ListItem,
  Chip,
} from '@material-tailwind/react'
import Typography from '../Typography/Typography'
import { Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead'
import { IProject, IUser } from '../../interfaces'
import UserProfileAvatar from '../UserProfileAvatar'
import Selector from '../SelectInput/Select'
import { editMemberRole } from '../../store/features/projectSlice'
import { useNavigate } from 'react-router-dom'
import { customEvent } from '../../utils/customHooks'
import CustomRadio from './RadioButton'
import addUser from '../../icons/addUser.svg'
import { useAppSelector, useAppDispatch } from '../../store/store'
import firebase from 'firebase/compat/app'
import axios from 'axios'
import { fetchInvites } from '../../store/features/inviteSlice'
import Toast from '../Toast/Toast'

interface IProps {
  open: boolean
  handleOpen: () => void
  project: IProject
  members: IUser[]
  joinedMembers: Array<{
    id: string
    name: string
    email: string
    role: string | undefined
  }>
  addMember: (projectId: string, memberId: string, role: string) => void
  source: string
  userId: string
}

export default function AddDialog({
  open,
  handleOpen,
  members,
  joinedMembers,
  project,
  addMember,
  source,
  userId,
}: IProps): React.JSX.Element {
  const { t } = useTranslation()
  const typeaheadInput = useRef(null)
  const [member, setMember] = useState<IUser[]>([])
  const [role, setRole] = useState('projectEditor')
  const [update, setUpdate] = useState(false)
  // const [changedRoles, setChangedRoles] = useState<{
  //   [key: string]: { role: string }
  // }>({})
  const [memberInput, setMemberInput] = useState('')
  const [addTeamMember, setAddTeamMember] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [teamRole, setTeamRole] = useState('projectEditor')
  const [loading, setLoading] = useState(false)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { invites } = useAppSelector((store) => store.invite)
  const { user } = useAppSelector((store) => store.user)
  const [userAccount, setUserAccount] = useState<string[]>([])
  const dispatch = useAppDispatch()
  const pendingFilterProjectInvites = invites.filter(
    (invite) => invite.status === 'pending' && invite?.projectId === project.id,
  )
  useEffect(() => {
    if (currentAccount && currentAccount.users) {
      const userAccount = currentAccount.users[user?.uid!]?.role
      setUserAccount([userAccount])
    }
  }, [currentAccount])
  // useEffect(() => {
  //   if (changedRoles === project.users) setUpdate(false)
  // }, [changedRoles, project.users])

  useEffect(() => {
    if (currentAccount) {
      dispatch(fetchInvites({ dispatch, id: currentAccount.id }))
    }
  }, [currentAccount, dispatch])

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    setIsDisabled(!emailRegex.test(memberInput))
  }, [memberInput])

  useEffect(() => {
    if (open) {
      customEvent('project_share_dialog_opened', {
        accountId: project.accountId,
        projectId: project.id,
        actionSource: source,
        user_userId: userId,
      })
    }
  }, [project, userId, open, source])

  const navigate = useNavigate()

  const options = [
    {
      label: 'Viewer',
      value: 'projectViewer',
    },
    {
      label: 'Commenter',
      value: 'projectCommenter',
    },
    {
      label: 'Editor',
      value: 'projectEditor',
    },
    {
      label: 'Admin',
      value: 'projectAdmin',
    },
  ]
  const memberRole = (status: string) => {
    switch (status) {
      case 'projectViewer':
        return 'guest'
      default:
        return 'standard'
    }
  }
  const handleChangeRole = async (
    value: string,
    memberId: string,
  ): Promise<void> => {
    // setChangedRoles((prevRoles) => ({
    //   ...prevRoles,
    //   [memberId]: { role: value },
    // }))
    try {
      editMemberRole(project.id, {
        [memberId]: { role: value },
      })
      customEvent('collaborator_role_edited', {
        accountId: project.accountId,
        projectId: project.id,
        userRole: value,
        actionSource: `from-role-${value}`,
        user_userId: userId,
      })
      Toast({
        text: t('createProject.toastRoleChange'),
        variant: 'success',
        options: { autoClose: 2500 },
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleShare = async (): Promise<void> => {
    setLoading(true)
    if (!addTeamMember) {
      if (member.length !== 0) {
        addMember(project.id, member[0].id!, role)
        Toast({
          title: t('accountSettings.inviteSent'),
          text: t('accountSettings.inviteSentTo', { email: member[0].email }),
          variant: 'success',
          options: { autoClose: 5000 },
        })
      }
      handleOpen()
      setLoading(false)
      setMember([])
      // setChangedRoles({})
      // if (update) {
      //   editMemberRole(project.id, changedRoles)
      //   for (const user of Object.keys(changedRoles)) {
      //     customEvent('project_collaborator_role_edited', {
      //       accountId: project.accountId,
      //       projectId: project.id,
      //       userRole: changedRoles[user].role,
      //       actionSource: `from-role-${project.users[user].role}`,
      //       user_userId: userId,
      //     })
      //   }
      // }
      setUpdate(false)
    } else {
      if (member && member[0].email) {
        const email = member[0].email
        // const isLstnUser = await firebase
        //   .auth()
        //   .fetchSignInMethodsForEmail(email)
        // console.log(isLstnUser, 'isLstnUser')
        const domain = window.location.origin
        const token = await firebase?.auth()?.currentUser?.getIdToken()
        await axios
          .post(
            `${process.env.REACT_APP_FIREBASE_API}/accounts/inviteUser`,
            {
              data: {
                accountId: currentAccount.id,
                email: email,
                domainUrl: domain,
                role: memberRole(teamRole),
                type: 'projectAndAccount',
                projectId: project.id,
                projectRole: teamRole,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Access-Control-Expose-Headers': 'Content-Disposition',
              },
            },
          )
          .then(() => {
            handleOpen()
            setMember([])
            // setChangedRoles({})
            setMemberInput('')
            setUpdate(false)
            setLoading(false)
            Toast({
              title: t('accountSettings.inviteSent'),
              text: t('accountSettings.inviteSentTo', { email: email }),
              variant: 'success',
              options: { autoClose: 5000 },
            })
          })
          .catch((error) => {
            handleOpen()
            setLoading(false)
            console.log(error)
          })
      }
    }
  }

  const handleRadioChange = (value: string) => {
    setRole(value) // Set the selected radio button value
  }

  const handleAdd = () => {
    if (memberInput) {
      // @ts-ignore
      setMember((prevMember) => [...prevMember, { email: memberInput }])
      setAddTeamMember(true)
    }
  }

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      placeholder={undefined}
      size="sm"
      className="p-4"
    >
      <DialogHeader
        id="alert-dialog-slide-title"
        className="py-2 px-4 flex flex-col items-start"
        placeholder={undefined}
      >
        <Typography className="text-2xl font-semibold text-blue-gray-900">
          {t('addProjectMember.title')}
        </Typography>
        <Typography className="text-lg font-normal text-blue-gray-500">
          {t('addProjectMember.subHeading')}
        </Typography>
        {userAccount?.includes('owner') || userAccount?.includes('admin') ? (
          <Typography className="text-lg font-normal text-blue-gray-500">
            {t('addProjectMember.adminSubHeading')}
            <Typography
              className="text-lg font-normal text-blue-gray-500 underline hover:cursor-pointer inline-block"
              onClick={() => navigate('/account-settings?tab=members')}
            >
              {t('addProjectMember.link')}
            </Typography>
            .
          </Typography>
        ) : (
          <Typography className="text-lg font-normal text-blue-gray-500">
            {t('addProjectMember.nonAdminSubHeading')}
          </Typography>
        )}
      </DialogHeader>
      <DialogBody placeholder={undefined}>
        <Typography
          variant="h6"
          className="mb-2 text-base font-medium text-blue-gray-900"
        >
          {t && t('createProject.title')}
        </Typography>
        <Typeahead
          ref={typeaheadInput}
          filterBy={['name', 'email']}
          id="project-members"
          labelKey="email"
          onChange={(selected) => {
            if (selected[0] == '' && memberInput !== '') {
              return
            }
            setMember(selected as IUser[])
            setMemberInput('')
            setAddTeamMember(false)
          }}
          onInputChange={(value) => {
            setMemberInput(value)
          }}
          options={members}
          placeholder="Name"
          className="project-members-typeahead"
          selected={member}
          minLength={1}
          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
            <div className="relative flex w-full items-center">
              {/* @ts-ignore */}
              <Input
                {...inputProps}
                ref={(node) => {
                  inputRef(node)
                  referenceElementRef(node)
                }}
                size="lg"
                placeholder="Name or Email"
                className=" !border-blue-gray-200 focus:!border-gray-400 placeholder:text-blue-gray-500 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                style={{ borderRadius: '8px' }}
                crossOrigin={undefined}
              />
            </div>
          )}
          renderMenu={(results, menuProps) => (
            <Menu {...menuProps}>
              {results.length === 0 ? (
                userAccount?.includes('admin') ||
                userAccount?.includes('owner') ? (
                  <MenuItem
                    key={0}
                    option={''}
                    position={0}
                    onClick={handleAdd}
                    disabled={isDisabled}
                  >
                    <div className="flex items-center mt-2">
                      <div
                        className={`w-12 h-12 rounded-full bg-[#25DA3C] flex justify-center items-center ${
                          isDisabled ? 'opacity-50' : ''
                        }`}
                      >
                        <img src={addUser} alt="" className="w-5 h-5" />
                      </div>
                      <div className="ml-2">
                        <Typography
                          className={`text-base font-semibold text-gray-900 ${
                            isDisabled ? 'opacity-50' : ''
                          }`}
                        >
                          {memberInput}
                        </Typography>
                        <Typography
                          className={`text-sm font-medium text-blue-gray-500 ${
                            isDisabled ? 'opacity-50' : ''
                          }`}
                        >
                          {t('addProjectMember.AddBothTeamAndProject')}
                        </Typography>
                      </div>
                    </div>
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={0}
                    option={''}
                    position={0}
                    disabled={isDisabled}
                  >
                    <div className="flex items-center mt-2">
                      <div className="ml-2">
                        <Typography
                          className={`text-sm font-normal text-blue-gray-200 `}
                        >
                          {t('addProjectMember.noFound')}
                        </Typography>
                        <Typography
                          className={`text-xs font-normal text-blue-gray-300 mt-2`}
                        >
                          {t('addProjectMember.noFoundContent1')}
                        </Typography>
                        <Typography
                          className={`text-xs font-normal text-blue-gray-300`}
                        >
                          {t('addProjectMember.noFoundContent2')}
                        </Typography>
                      </div>
                    </div>
                  </MenuItem>
                )
              ) : (
                results.map((option: any, index) => (
                  <MenuItem option={option} position={index} key={index}>
                    <div className="flex items-center mt-2">
                      <UserProfileAvatar
                        userName={option.name}
                        avatarUrl={option.profilePicture}
                        width="48px"
                        height="48px"
                      />
                      <div className="ml-2">
                        <Typography className="text-base font-semibold text-gray-900">
                          {option.name}
                        </Typography>
                        <Typography className="text-sm font-medium text-blue-gray-500">
                          {option.email}
                        </Typography>
                      </div>
                    </div>
                  </MenuItem>
                ))
              )}
            </Menu>
          )}
        />
        {member.length > 0 &&
          (!addTeamMember ? (
            <div>
              <Typography className="text-base font-medium text-blue-gray-900 py-2">
                {t('addProjectMember.addProjectContent', {
                  name: project.name,
                })}
              </Typography>
              <div className="grid">
                <CustomRadio
                  name="projectAdmin"
                  checked={role === 'projectAdmin'}
                  onChange={() => handleRadioChange('projectAdmin')}
                  label={
                    <div>
                      <Typography color="blue-gray" className="font-medium">
                        {t('addProjectMember.projectAdmin')}
                      </Typography>
                      <Typography
                        variant="small"
                        color="gray"
                        className="font-normal text-sm text-blue-gray-500"
                      >
                        {t('addProjectMember.projectAdminContent')}
                      </Typography>
                    </div>
                  }
                  containerProps={{
                    className: '-mt-5',
                  }}
                  crossOrigin={undefined}
                />
                <CustomRadio
                  name="projectEditor"
                  checked={role === 'projectEditor'}
                  onChange={() => handleRadioChange('projectEditor')}
                  label={
                    <div>
                      <Typography color="blue-gray" className="font-medium">
                        {t('addProjectMember.projectEditor')}
                      </Typography>
                      <Typography
                        variant="small"
                        color="gray"
                        className="font-normal text-sm text-blue-gray-500"
                      >
                        {t('addProjectMember.projectEditorContent')}
                      </Typography>
                    </div>
                  }
                  containerProps={{
                    className: '-mt-5',
                  }}
                  crossOrigin={undefined}
                />
                <CustomRadio
                  name="projectCommenter"
                  checked={role === 'projectCommenter'}
                  onChange={() => handleRadioChange('projectCommenter')}
                  label={
                    <div>
                      <Typography color="blue-gray" className="font-medium">
                        {t('addProjectMember.projectCommenter')}
                      </Typography>
                      <Typography
                        variant="small"
                        color="gray"
                        className="font-normal text-sm text-blue-gray-500"
                      >
                        {t('addProjectMember.projectCommenterContent')}
                      </Typography>
                    </div>
                  }
                  containerProps={{
                    className: '-mt-5',
                  }}
                  crossOrigin={undefined}
                />
                <CustomRadio
                  name="projectViewer"
                  checked={role === 'projectViewer'}
                  onChange={() => handleRadioChange('projectViewer')}
                  label={
                    <div>
                      <Typography color="blue-gray" className="font-medium">
                        {t('addProjectMember.projectViewer')}
                      </Typography>
                      <Typography
                        variant="small"
                        color="gray"
                        className="font-normal text-sm text-blue-gray-500"
                      >
                        {t('addProjectMember.projectViewerContent')}
                      </Typography>
                    </div>
                  }
                  containerProps={{
                    className: '-mt-5',
                  }}
                  crossOrigin={undefined}
                />
              </div>
            </div>
          ) : (
            <div>
              <Typography className="text-base font-medium text-blue-gray-900 py-2">
                {t('addProjectMember.addProjectAndAccountContent', {
                  name: currentAccount.name,
                  projectName: project.name,
                })}
              </Typography>
              <div className="grid">
                <CustomRadio
                  name="projectEditor"
                  checked={teamRole === 'projectEditor'}
                  onChange={() => setTeamRole('projectEditor')}
                  label={
                    <div>
                      <Typography color="blue-gray" className="font-medium">
                        {t('addProjectMember.editor')}
                      </Typography>
                      <Typography
                        variant="small"
                        color="gray"
                        className="font-normal text-sm text-blue-gray-500"
                      >
                        {t('addProjectMember.editorContent')}
                      </Typography>
                    </div>
                  }
                  containerProps={{
                    className: '-mt-5',
                  }}
                  crossOrigin={undefined}
                />
                <CustomRadio
                  name="projectCommenter"
                  checked={teamRole === 'projectCommenter'}
                  onChange={() => setTeamRole('projectCommenter')}
                  label={
                    <div>
                      <Typography color="blue-gray" className="font-medium">
                        {t('addProjectMember.commenter')}
                      </Typography>
                      <Typography
                        variant="small"
                        color="gray"
                        className="font-normal text-sm text-blue-gray-500"
                      >
                        {t('addProjectMember.projectCommenterContent')}
                      </Typography>
                    </div>
                  }
                  containerProps={{
                    className: '-mt-5',
                  }}
                  crossOrigin={undefined}
                />
                <CustomRadio
                  name="projectViewer"
                  checked={teamRole === 'projectViewer'}
                  onChange={() => setTeamRole('projectViewer')}
                  label={
                    <div>
                      <Typography color="blue-gray" className="font-medium">
                        {t('addProjectMember.viewerGuest')}
                      </Typography>
                      <Typography
                        variant="small"
                        color="gray"
                        className="font-normal text-sm text-blue-gray-500"
                      >
                        {t('addProjectMember.viewerGuestContent')}
                      </Typography>
                    </div>
                  }
                  containerProps={{
                    className: '-mt-5',
                  }}
                  crossOrigin={undefined}
                />
              </div>
            </div>
          ))}
        <hr className="mt-6 mb-3 bg-blue-gray-50" />
        {pendingFilterProjectInvites.length > 0 &&
          pendingFilterProjectInvites.map((invite) => (
            <List placeholder={undefined} key={invite.id} className="p-0">
              <ListItem
                placeholder={undefined}
                className="flex justify-between px-0"
                ripple={false}
              >
                <div className="items-center">
                  <Typography className="text-sm font-medium text-blue-gray-900">
                    {invite.email.split('@')[0]}
                  </Typography>
                  <Typography className="text-xs font-normal text-blue-gray-500">
                    {invite.email}
                  </Typography>
                </div>
                <Chip
                  variant="ghost"
                  size="sm"
                  value={invite.status}
                  className={'bg-cyan-50 text-cyan-500'}
                  icon={
                    <div
                      className={`w-2 h-2 rounded-full absolute bottom-1 right-1 top-1 left-1 bg-cyan-500
                      }`}
                    ></div>
                  }
                />
              </ListItem>
            </List>
          ))}
        {joinedMembers.map((member) => (
          <List placeholder={undefined} key={member.id} className="p-0">
            <ListItem
              placeholder={undefined}
              className="flex justify-between px-0"
              ripple={false}
            >
              <div className="items-center">
                <Typography className="text-sm font-medium text-blue-gray-900">
                  {member.name}
                </Typography>
                <Typography className="text-xs font-normal text-blue-gray-500">
                  {member.email}
                </Typography>
              </div>
              <Selector
                options={options}
                onChange={(value) => handleChangeRole(value!, member.id!)}
                selectClassName="border-none"
                selectedValue={member.role}
                className="border-none text-xs right-0 top-0 addDialogSelector"
                menuClassName="min-w-[130px]"
                children={undefined}
              />
            </ListItem>
          </List>
        ))}
      </DialogBody>
      <DialogFooter placeholder={undefined} className="gap-0.5">
        <Button
          onClick={() => {
            handleOpen()
            setMember([])
            // setChangedRoles({})
          }}
          variant="text"
          className="text-xs font-bold uppercase hover:bg-transparent"
          placeholder={undefined}
        >
          {t('createProject.cancel')}
        </Button>
        <Button
          onClick={() => handleShare()}
          loading={loading}
          className="bg-lstnGreen-500 text-white text-xs font-bold uppercase rounded-lg"
          placeholder={undefined}
          variant="filled"
          disabled={!(member.length !== 0 || update)}
        >
          {t('addProjectMember.shareBtn')}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
