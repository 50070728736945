//@ts-nocheck
import { useEffect, useRef } from 'react'
import ReactGA from 'react-ga4'
import momentTimezoneData from 'moment-timezone/data/meta/latest.json'
export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    // @ts-ignore
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return
  }, [delay])
}

export const customEvent = (
  eventname: string,
  customDimensions?: Record<string>,
) => {
  ReactGA.gtag('event', eventname, {
    ...customDimensions,
  })
}

export const getCountryCode = () => {
  const country = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZoneToCountry = {}

  Object.keys(momentTimezoneData.zones).forEach((zone) => {
    const countryAbbr =
      momentTimezoneData.countries[momentTimezoneData.zones[zone]?.countries[0]]
        ?.abbr
    if (countryAbbr) {
      timeZoneToCountry[zone] = countryAbbr
    }
  })

  return timeZoneToCountry[country]
}
export const getCurrentPlan = (plans, userStatus) => {
  if (plans && userStatus) {
    const userPlan = plans.find(
      (plan) => plan?.planID === userStatus?.plan?.product,
    )
    return userPlan
  }
}
