import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
// import ReactGA from "react-ga"
import axios from 'axios'
import firebase from 'firebase/compat/app'

interface IPaymentProps {
  user?: firebase.User
}

const PortalScreen = ({ user }: IPaymentProps) => {
  const [portalData, setPortalData] = useState()
  const navigate = useNavigate()
  let frames = 0

  useEffect(() => {
    if (user && user.uid && !portalData) {
      fetchPortal()
    }
  }, [user])

  const checkFrames = () => {
    frames++
    if (frames > 1) {
      navigate('/')
      window.location.reload()
    }
  }

  const fetchPortal = async () => {
    const clientId: string = ''
    // ReactGA.ga((tracker) => {
    //   clientId = tracker.get("clientId");
    // })

    if (user) {
      try {
        // @ts-ignore
        const token = await firebase.auth().currentUser.getIdToken()
        const data = await axios.post(
          `${process.env.REACT_APP_GCP}/payment/portal`,
          {
            data: {
              clientId,
              userId: user.uid,
            },
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              'Access-Control-Expose-Headers': 'Content-Disposition',
            },
          },
        )
        if (data.data) {
          setPortalData(data.data.response.portal_session)
        }
      } catch (error) {
        console.error('Error on hosted page function', error)
      }
    }
  }

  return (
    <div className="payment-container">
      {!portalData ? (
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      ) : (
        // @ts-ignore
        <iframe src={portalData.access_url} onLoad={checkFrames} />
      )}
    </div>
  )
}

export default PortalScreen
