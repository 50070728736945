import * as React from 'react'
import { Provider } from 'react-redux'
// import {  ReactReduxFirebaseProvider, ReactReduxFirebaseProviderProps } from "react-redux-firebase"
import 'react-toastify/dist/ReactToastify.css'
// import { applyMiddleware, compose, createStore } from "redux"
// import { getFirestore } from "redux-firestore"
// import { createFirestoreInstance } from "redux-firestore"
// import createSagaMiddleware from "redux-saga"
// import thunk from "redux-thunk"
import App from './App'
import ErrorBoundary from './components/ErrorBoundary'
import firebase from './firebaseApp'
// import rootReducer from "./store/reducers/rootReducer"
// import userStatusSaga from './store/sagas/userStatus'
import './i18n'
import store from './store/store'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@material-tailwind/react'
import { PostHogProvider } from 'posthog-js/react'

async function getToken() {
  try {
    const response = await fetch('/token')
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const json = await response.json()
      const auth = firebase.auth()
      await auth.signInWithCustomToken(json.token)
    } else {
      console.log('Not a json format: ' + contentType)
    }
  } catch (error) {
    console.error(
      'Failed to fetch valid token from Firebas. This method expects that an user is loged in. Reason: ',
      error,
    )
  }
}

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}
// async function loginDev(username: string, password: string) {
//   console.warn("Login with dev credentials. Username: ", username)
//   try {
//     const userCredential = await firebase.auth().signInWithEmailAndPassword(username, password)
//     await userCredential.user!.updateProfile({ displayName: "DevUser: " + username, photoURL: null })
//   } catch (error) {
//     const errorCode = error.code
//     const errorMessage = error.message

//     console.error(errorCode, errorMessage)
//   }
// }
const development = process.env.NODE_ENV === 'development'
if (development) {
  // const username = process.env.REACT_APP_FIREBASE_DEV_USERNAME || "ginopino@mailinator.com"
  // const password = process.env.REACT_APP_FIREBASE_DEV_PASSWORD || "ginopino"
  // const username = 'ginopino@mailinator.com'
  // const password = 'ginopino'
  // loginDev(username, password)
} else {
  getToken()
}

// Create store with reducers and initial state

// const sagaMiddleware = createSagaMiddleware()
// // @ts-ignore
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// const initialState = {}
// const store = createStore(rootReducer, initialState, composeEnhancers(
//   applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
//   applyMiddleware(sagaMiddleware)
// ))

// sagaMiddleware.run(userStatusSaga)
// export type RootState = ReturnType<typeof store.getState>
// export type AppDispatch = typeof store.dispatch
// const rrfProps: ReactReduxFirebaseProviderProps = {
//   config: {
//     useFirestoreForProfile: true,
//     userProfile: "users",
//   },
//   createFirestoreInstance,
//   dispatch: store.dispatch,
//   firebase,
// }
const element = document.getElementById('root')
const root = createRoot(element!)
root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <ThemeProvider>
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <App />
        </PostHogProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </Provider>,
)
// registerServiceWorker()
