import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react'
import ReactGA from 'react-ga4'
import { storage } from '../../firebaseApp'
import { useAppSelector } from '../../store/store'

interface IProps {
  playbackVideoGsUrl: string
  // videoPlayCurrentTime:;
  ref?: React.Ref<VideoPlayerMethods> | undefined
}
export interface VideoPlayerMethods {
  togglePlay: () => void
  setPlayerSpeed: (val: string) => void
  setTime: (time: number) => void
}
const VideoPlayer = forwardRef<VideoPlayerMethods, IProps>((props, ref) => {
  const { playbackVideoGsUrl } = props
  const [isPlaying, setIsPlaying] = useState(false)
  const [playbackVideoUrl, setPlaybackVideoUrl] = useState('')
  const videoRef = useRef<HTMLVideoElement | null>(null)
  let timer: NodeJS.Timeout
  const { currentTime } = useAppSelector((store) => store.getTranscript)

  useEffect(() => {
    fetchPlaybackVideoUrl()
  }, [playbackVideoGsUrl])

  useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0
    }
    clearInterval(timer)
  }, [playbackVideoUrl])

  const pause = useCallback(() => {
    if (isPlaying) {
      videoRef.current?.pause()
      clearInterval(timer)
      setIsPlaying(false)
    }
  }, [isPlaying])

  const setTime = useCallback((time: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time
    }
  }, [])
  useEffect(() => {
    if (
      videoRef.current &&
      Math.abs(
        Math.round(videoRef.current.currentTime) - Math.round(currentTime),
      ) > 1
    ) {
      setTime(currentTime)
    }
  }, [currentTime])
  const setPlayerSpeed = useCallback((val: string) => {
    if (videoRef.current) {
      videoRef.current.playbackRate = parseFloat(val)
    }
  }, [])

  useImperativeHandle(ref, () => ({
    togglePlay,
    setPlayerSpeed,
    setTime,
  }))

  const fetchPlaybackVideoUrl = useCallback(async () => {
    try {
      const url = await storage.refFromURL(playbackVideoGsUrl).getDownloadURL()
      setPlaybackVideoUrl(url)
    } catch (error) {
      console.error('Error fetching Playback URL: ', error)
      // ReactGA.exception({
      //   description: error.message,
      //   fatal: false,
      // })
      ReactGA.gtag('event', 'VideoPlayer_PlaybackURL_exception', {
        description: (error as Error).message,
        fatal: false,
      })
    }
  }, [playbackVideoGsUrl])

  const play = useCallback(() => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .then(() => {})
        .catch((error) => {
          console.log('Error playing audio: ', error)
        })
      setIsPlaying(true)
    }
  }, [])

  const togglePlay = useCallback(() => {
    if (isPlaying) {
      pause()
      // ReactGA.event({
      //   action: "video pause button pressed",
      //   category: "player",
      // })
    } else {
      play()
      // ReactGA.event({
      //   action: "video play button pressed",
      //   category: "player",
      // })
    }
  }, [isPlaying, pause, play])

  if (!playbackVideoUrl) {
    return <div />
  }

  return (
    <div className="video-player-container " style={{ zIndex: 2 }}>
      <video
        ref={videoRef}
        src={playbackVideoUrl}
        controls={false}
        muted
        width={241}
        height={137}
      />
    </div>
  )
})

// const mapStateToProps = (state:) => ({
//   videoPlayCurrentTime: state.TimeReducer,
// });
// const ConnectedVideoPlayer = connect(mapStateToProps, null, null, { forwardRef: true })(VideoPlayer);
// export default forwardRef<VideoPlayerMethods, IProps>((props, ref) => (
//   <ConnectedVideoPlayer {...props} ref={ref} />
// ));

export default VideoPlayer
