import React, { Component } from 'react'
import Sentry from '../utils/sentry'
import { Extras } from '@sentry/types'

interface IState {
  hasError: boolean
  eventId?: string
}
interface ComponentProps {
  children: React.ReactNode
  // other props...
}

export default class ErrorBoundary extends Component<ComponentProps, IState> {
  public static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    console.error(error)
    return { hasError: true }
  }

  constructor(props: Readonly<ComponentProps>) {
    super(props)
    // @ts-ignore
    this.state = { eventId: null }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope((scope) => {
      const extras: Extras = {
        // Here you can include relevant properties from errorInfo
        componentStack: errorInfo.componentStack,
      }
      scope.setExtras(extras)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <button
          onClick={() =>
            Sentry.showReportDialog({ eventId: this.state.eventId })
          }
        >
          Report feedback
        </button>
      )
    }

    return this.props.children
  }
}
