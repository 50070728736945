import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { ThemeProvider } from '@material-ui/styles'
import { TransitionProps } from '@material-ui/core/transitions'
import { makeStyles } from '@material-ui/core/styles'
import { lstnBasictheme } from '../../theme/lstn-theme-basic'
import { useTranslation } from 'react-i18next'

interface IProps {
  initialValue: string
  open: boolean
  data?: {
    id: string
    name: string
  } | null
  onClose: (status: boolean, value?: string) => void
}

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    // @ts-ignore
    return <Slide direction="up" ref={ref} {...props} />
  },
)

export default function FolderDialog({
  initialValue,
  open,
  data,
  onClose,
}: IProps) {
  const { t } = useTranslation()
  const [value, setValue] = useState<string>(initialValue)
  const useStyles = makeStyles({
    root: {
      maxWidth: 600,
      width: '-webkit-fill-available',
    },
  })

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleClose = (status: boolean) => () => {
    onClose(status, value)
  }

  const handleText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const classes = useStyles()
  return (
    <ThemeProvider theme={lstnBasictheme}>
      <Dialog
        classes={{ paperWidthSm: classes.root }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        data-cy="data-project-create-folder-dialog"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {data && data.id ? t('folder.renametitle') : t('folder.title')}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            value={value}
            variant="outlined"
            margin="dense"
            placeholder="Transcript Name"
            fullWidth
            onChange={handleText}
            data-cy="data-project-create-folder-name"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose(false)}
            color="secondary"
            variant="outlined"
            data-cy="data-project-create-folder-cancel"
          >
            {t('folder.cancel')}
          </Button>
          <Button
            onClick={handleClose(true)}
            color="primary"
            variant="contained"
            disabled={!value}
            data-cy="data-project-create-folder-submit"
          >
            {data && data.id ? t('folder.rename') : t('folder.create')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}
