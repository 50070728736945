// DeepL API supports the following languages
export const translationTargetLanguagesDeepL = [
  { value: 'none', label: 'None' },
  { value: 'en-GB', label: 'English (British)' },
  { value: 'en-US', label: 'English (American)' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'cs', label: 'Czech' },
  { value: 'da', label: 'Danish' },
  { value: 'de', label: 'German' },
  { value: 'el', label: 'Greek' },
  { value: 'es', label: 'Spanish' },
  // { value: "es-AR", label: "Spanish (Argentina)" },
  { value: 'es-AR', label: 'Spanish (Latin America)' },
  { value: 'et', label: 'Estonian' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fr', label: 'French' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'id', label: 'Indonesian' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'ko', label: 'Korean' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'lv', label: 'Latvian' },
  { value: 'nb', label: 'Norwegian' },
  { value: 'nl', label: 'Dutch' },
  { value: 'pl', label: 'Polish' },
  { value: 'pt-BR', label: 'Portuguese (Brazilian)' },
  { value: 'pt-PT', label: 'Portuguese (Portugal)' },
  { value: 'ro', label: 'Romanian' },
  { value: 'ru', label: 'Russian' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sl', label: 'Slovenian' },
  { value: 'sv', label: 'Swedish' },
  { value: 'tr', label: 'Turkish' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'zh', label: 'Chinese' },
]

export const translationSourceLanguages = {
  'en-GB': 'English (UK)',
  'en-US': 'English (USA)',
  'en-AU': 'English (Australia)',
  'en-IN': 'English (India)',
  'it-IT': 'Italian',
  'pl-PL': 'Polish',
  'pt-BR': 'Portuguese (Brazil)',
  'pt-PT': 'Portuguese (Portugal)',
  'de-DE': 'German',
  'fr-FR': 'French (France)',
  'es-ES': 'Spanish (Spain)',
  // "es-AR": "Spanish (Argentina)",
  'es-AR': 'Spanish (Latin America)',
  'es-BO': 'Spanish (Bolivia)',
  'es-CL': 'Spanish (Chile)',
  'es-CO': 'Spanish (Colombia)',
  'es-CR': 'Spanish (Costa Rica)',
  'es-DO': 'Spanish (Dominican Republic)',
  'es-EC': 'Spanish (Ecuador)',
  'es-SV': 'Spanish (El Salvador)',
  'es-GT': 'Spanish (Guatemala)',
  'es-HN': 'Spanish (Honduras)',
  'es-MX': 'Spanish (Mexico)',
  'es-NI': 'Spanish (Nicaragua)',
  'es-PA': 'Spanish (Panama)',
  'es-PY': 'Spanish (Paraguay)',
  'es-PE': 'Spanish (Peru)',
  'es-PR': 'Spanish (Puerto Rico)',
  'es-UY': 'Spanish (Uruguay)',
  'es-US': 'Spanish (USA)',
  'es-VE': 'Spanish (Venezuela)',
  'da-DK': 'Danish',
  'ko-KR': 'Korean',
  'sv-SE': 'Swedish',
  'no-NO': 'Norwegian',
  'fi-FI': 'Finnish',
  'nl-NL': 'Dutch',
  'ro-RO': 'Romanian',
  'hu-HU': 'Hungarian',
  'uk-UA': 'Ukrainian',
  'ru-RU': 'Russian',
  'bg-BG': 'Bulgarian',
  'cz-CZ': 'Czech',
  'el-GR': 'Greek',
  'sk-SK': 'Slovak',
  'sl-SI': 'Slovenian',
  'en-IE': 'English (Ireland)',
}

export const unsupportedDeepLSourceLanguages = ['km-KH']
