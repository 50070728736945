import React, { useEffect, useMemo, useState, useTransition } from 'react'
import { Dialog, DialogHeader, DialogBody } from '@material-tailwind/react'
import Typography from '../Typography/Typography'
import PricingCard from '../UpgradeModal/Card'
import { useTranslation } from 'react-i18next'
// import firebase from 'firebase/compat/app'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { helperPlanData } from '../../store/features/planSlice'
import DialogComponent from '../Dialog'
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react'
import '../../css/upgradeModal.css'
import { customEvent, getCurrentPlan } from '../../utils/customHooks'
import moment from 'moment'
import { functionsV2 } from '../../firebaseApp'
import { PaymentSplashScreen } from '../SplashScreen'

interface IProps {
  UpgradeOpen: boolean
  handleUpgrade: () => void
}

interface Payload {
  accountId: string
  returnUrl: string
  planSpecificPortal: string
}
const UpgradeModal: React.FC<IProps> = ({ UpgradeOpen, handleUpgrade }) => {
  const { t } = useTranslation()
  const { plans } = useAppSelector((store) => store.plan)
  const { userStatus, user } = useAppSelector((store) => store.user)
  // const [currentPlan, setCurrentPlan] = useState<IPlanData>()
  const [open, setOpen] = useState(false)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { currentProject } = useAppSelector((store) => store.project)
  const accountId = currentAccount?.id
  // console.log(userStatus?.plan, 'plans')

  const [arePlansLoading, setArePlansLoading] = useState(true)
  const [isFilteredPlan, setIsFilteredPlan] = useState(false)

  const [isPending, startTransition] = useTransition()
  const [isRedirectUrl, setIsRedirectUrl] = useState(false)

  useEffect(() => {
    if (plans.length > 0) {
      setArePlansLoading(false)
    }
  }, [plans])

  const dispatch = useAppDispatch()
  useEffect(() => {
    helperPlanData({ dispatch })
  }, [])
  const currentPlan = useMemo(() => {
    if (plans.length > 0 && userStatus) {
      const activePlan = getCurrentPlan(plans, userStatus)
      return activePlan
    }
  }, [plans, userStatus])

  useEffect(() => {
    if (userStatus?.plan) {
      const filteredPlans = plans.filter(
        (plan) =>
          plan &&
          plan.metadata.isVisible === 'true' &&
          plan.stripe_metadata_allowedSeconds != null &&
          plan.planID === userStatus?.plan?.product,
      )
      if (filteredPlans.length > 0) {
        setIsFilteredPlan(true)
      } else {
        setIsFilteredPlan(false)
      }
    }
  }, [userStatus, userStatus?.plan, plans])

  const handleOpen = (): void => {
    if (!open) {
      customEvent('demo_request_modal_opened', {
        accountId: currentProject?.accountId,
        user_userId: user.uid,
      })
    }
    setOpen(!open)
  }

  const handlePortal = async (id: string): Promise<void> => {
    setIsRedirectUrl(true)
    startTransition(() => {
      const payload: Payload = {
        accountId: accountId,
        returnUrl: window.location.origin + '/transcripts',
        planSpecificPortal: id,
      }
      console.log('function call', moment().format('HH:mm:ss'))
      const getCustomerPortalSessionUrl = functionsV2.httpsCallable(
        'getCustomerPortalSessionUrl',
      )
      getCustomerPortalSessionUrl(payload)
        .then((response) => {
          console.log('response', moment().format('HH:mm:ss'))
          customEvent('billing_manage_sub_opened', {
            accountId: accountId,
            user_userId: user.uid,
            actionMetadata: `plan-${id}`,
          })
          const url = response.data.url
          console.log('end', moment().format('HH:mm:ss'))
          window.location.href = url
        })
        .catch((error) => {
          console.error(error)
          setIsRedirectUrl(false)
        })
    })
  }

  return (
    <Dialog
      open={UpgradeOpen}
      handler={handleUpgrade}
      size={'xl'}
      placeholder={undefined}
      style={{ height: '95vh', overflowY: 'auto' }}
    >
      {arePlansLoading ? (
        // <div className="flex justify-center items-center min-h-[50vh]">
        <PaymentSplashScreen />
      ) : // </div>
      isPending || isRedirectUrl ? (
        <PaymentSplashScreen />
      ) : (
        <div>
          <DialogHeader
            placeholder={undefined}
            className="flex justify-center sm:my-6 "
          >
            <div>
              <Typography
                color="black"
                className="text-2xl sm:text-3xl font-bold text-center sm:mb-2"
              >
                {t('upgradeModal.title')}
              </Typography>
              <Typography className="text-base font-normal text-center hidden sm:block">
                {t('upgradeModal.content')}
              </Typography>
            </div>
          </DialogHeader>
          <DialogBody
            placeholder={undefined}
            className="flex justify-center items-center"
          >
            <div className="block sm:hidden">
              <Tabs value="Starter" className="w-full mx-auto nav-tabs">
                <TabsHeader placeholder={undefined} className="w-80">
                  {plans
                    .filter(
                      (plan) =>
                        plan &&
                        plan.metadata.isVisible === 'true' &&
                        plan.stripe_metadata_allowedSeconds != null,
                    )
                    .sort((a, b) => {
                      const secondsA = parseInt(
                        a.stripe_metadata_allowedSeconds,
                        10,
                      )
                      const secondsB = parseInt(
                        b.stripe_metadata_allowedSeconds,
                        10,
                      )
                      return secondsA - secondsB
                    })
                    .map((plan, index: React.Key | null | undefined) => {
                      return (
                        <Tab
                          key={index}
                          value={plan.name}
                          placeholder={undefined}
                        >
                          {plan.name}
                        </Tab>
                      )
                    })}
                </TabsHeader>
                <TabsBody placeholder={undefined}>
                  {plans
                    .filter(
                      (plan) =>
                        plan &&
                        plan.metadata.isVisible === 'true' &&
                        plan.stripe_metadata_allowedSeconds != null,
                    )
                    .sort((a, b) => {
                      const secondsA = parseInt(
                        a.stripe_metadata_allowedSeconds,
                        10,
                      )
                      const secondsB = parseInt(
                        b.stripe_metadata_allowedSeconds,
                        10,
                      )
                      return secondsA - secondsB
                    })
                    .map((plan, index: React.Key | null | undefined) => {
                      return (
                        <TabPanel key={index} value={plan.name}>
                          <PricingCard
                            key={index}
                            index={index as number}
                            {...plan}
                            currentPlan={currentPlan}
                            selectedPlan={userStatus?.plan}
                            handleclick={handlePortal}
                            handleOpen={handleOpen}
                            isFilteredPlan={isFilteredPlan}
                          />
                        </TabPanel>
                      )
                    })}
                </TabsBody>
              </Tabs>
            </div>
            <div className="hidden sm:grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mx-auto items-center">
              {plans
                .filter(
                  (plan) =>
                    plan &&
                    plan.metadata.isVisible === 'true' &&
                    plan.stripe_metadata_allowedSeconds != null,
                )
                .sort((a, b) => {
                  const secondsA = parseInt(
                    a.stripe_metadata_allowedSeconds,
                    10,
                  )
                  const secondsB = parseInt(
                    b.stripe_metadata_allowedSeconds,
                    10,
                  )
                  return secondsA - secondsB
                })
                .map((plan, index: React.Key | null | undefined) => {
                  if (plan && plan.metadata.isVisible === 'true') {
                    return (
                      <PricingCard
                        key={index}
                        index={index as number}
                        {...plan}
                        currentPlan={currentPlan}
                        selectedPlan={userStatus?.plan}
                        handleclick={handlePortal}
                        handleOpen={handleOpen}
                        isFilteredPlan={isFilteredPlan}
                      />
                    )
                  } else {
                    return null
                  }
                })}
            </div>
            <DialogComponent open={open} handleClose={handleOpen} />
          </DialogBody>
        </div>
      )}
    </Dialog>
  )
}

export default UpgradeModal
