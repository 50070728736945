import { createSlice } from '@reduxjs/toolkit'
import { database } from '../../firebaseApp'
// import firebase from 'firebase'
import { IProject } from '../../interfaces'
import type { AppDispatch } from '../store'
import { customEvent } from '../../utils/customHooks'
import axios from 'axios'
import firebase from 'firebase/compat/app'

interface ProjectsState {
  projects: IProject[]
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null | undefined
  currentProject: IProject
  projectId: string | null
  roles: { [key: string]: string[] }
}

const initialState: ProjectsState = {
  projects: [],
  loading: 'idle',
  error: null,
  currentProject: {} as IProject,
  projectId: null,
  roles: {} as { [key: string]: string[] },
}

export const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload
    },
    setAllProjects: (state, action) => {
      state.projects = action.payload
    },
    setRoles: (state, action) => {
      state.roles = action.payload
    },
  },
})

export const { setCurrentProject, setProjectId, setAllProjects, setRoles } =
  projectSlice.actions

export default projectSlice.reducer

export const fetchRoles = async (dispatch: AppDispatch): Promise<void> => {
  const roleDoc = await database.collection('roles').doc('project').get()
  const roles = roleDoc.data()
  dispatch(setRoles(roles))
}

export const fetchAllProjects = async (
  accountId: string,
  dispatch: AppDispatch,
): Promise<() => void> => {
  const projDocQuery = database
    .collection('projects')
    .where(`accountId`, '==', accountId)

  const unsubscribe = projDocQuery.onSnapshot((snapShot) => {
    const projects = snapShot.docs.map((doc) => {
      const parsedData = JSON.stringify(doc.data())
      const project = {
        ...JSON.parse(parsedData),
        id: doc.id,
      }
      return project
    })
    dispatch(setAllProjects(projects))
  })

  return () => unsubscribe()
}

export const addProject = async (
  accountId: string,
  name: string,
  userId: string,
  description?: string,
): Promise<string> => {
  const project = {
    accountId,
    name,
    description,
    status: 'active',
    users: {
      [userId]: { role: 'projectAdmin', status: 'active' },
    },
  }
  const doc = await database.collection('projects').add(project)
  const projectId = doc.id
  await customEvent('project_created', {
    accountId: accountId,
    projectId: projectId,
    user_userId: userId,
  })
  return projectId
}

export const editProject = async (
  id: string,
  name: string,
  description?: string,
): Promise<void> => {
  const project = {
    name,
    description,
  }
  await database.collection('projects').doc(id).update(project)
}

export const archiveProject = async (id: string): Promise<void> => {
  await database.collection('projects').doc(id).update({ status: 'archived' })
}

export const addMember = async (
  projectId: string,
  memberId: string,
  role: string,
): Promise<void> => {
  const projectDocRef = database.collection('projects').doc(projectId)
  await projectDocRef.update({
    [`users.${memberId}`]: { role: role, status: 'active' },
  })
  const domain = window.location.origin
  const token = await firebase?.auth()?.currentUser?.getIdToken()
  await axios
    .post(
      `${process.env.REACT_APP_FIREBASE_API}/accounts/sendProjectNotificationEmail`,
      {
        data: {
          projectId: projectId,
          memberId: memberId,
          domainUrl: domain,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Expose-Headers': 'Content-Disposition',
        },
      },
    )
    .then(() => {
      // console.log(res, 'res')
    })
    .catch((error) => {
      console.log(error)
    })
}

export const editMemberRole = async (
  projectId: string,
  member: {
    [key: string]: { role: string }
  },
): Promise<void> => {
  const projectDocRef = database.collection('projects').doc(projectId)
  await projectDocRef.set(
    {
      [`users`]: member,
    },
    { merge: true },
  )
}
