import React from 'react'
import './index.css'
const TidycalIframe = (): JSX.Element => {
  return (
    <div className="iframe-container">
      <iframe
        src={process.env.REACT_APP_TIDYCALL_URL}
        title="Tidycal Booking"
        width="100%"
        height="650"
        scrolling="no"
        frameBorder="0"
      />
      <div className="iframe-overlay text-center text-base font-medium mt-2">
        <p>
          or email us at{' '}
          <a href="mailto:sales@lstn.ai" className="text-lstnGreen-500">
            sales@lstn.ai
          </a>
        </p>
      </div>
    </div>
  )
}

export default TidycalIframe
