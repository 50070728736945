import React from 'react'
// import { makeStyles } from "@material-ui/core/styles"
import Button, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import { Omit } from '@material-ui/types'

// to modify existing property of MUI Button i.e. color
interface IProps {
  // color: 'primary' | 'default'
}

function CustomButton(
  props: IProps & Omit<MuiButtonProps, keyof IProps>,
): JSX.Element {
  // const { color, ...other } = props // use others instead in Button props so that we preserve the prop and modify the behaviour
  return <Button {...props} />
}

export default CustomButton
