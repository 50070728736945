import React, { ChangeEventHandler, useEffect, useState } from 'react'
import '../css/Settings.css'
import SettingsBox from '../components/Settings/SettingsBox'
import i18n from '../i18n'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { useTranslation } from 'react-i18next'
import { IAPi } from '../interfaces'
import { database } from '../firebaseApp'
import Loader from 'react-loader-spinner'
import Toast from '../components/Toast/Toast'
// import Dialog from '@material-ui/core/Dialog'
// import DialogActions from '@material-ui/core/DialogActions'
// import DialogContent from '@material-ui/core/DialogContent'
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'
import axios from 'axios'
import firebase from 'firebase/compat/app'
import SplashScreen from '../components/SplashScreen'
import { useAppSelector, useAppDispatch } from '../store/store'
import { fetchUserById } from '../store/features/userSlice'
import { customEvent } from '../utils/customHooks'
import 'firebase/compat/auth'
import { useNavigate } from 'react-router-dom'
// import { resetUserStatus } from '../store/features/userSlice'
import Typography from '../components/Typography/Typography'
import { Input } from '@material-tailwind/react'
import Button from '../components/Button/TailwindButton'
import Selector from '../components/SelectInput/Select'
import CustomCheckbox from '../components/Checkbox/Checkbox'
import { IconButton, Textarea, ThemeProvider } from '@material-tailwind/react'
import UserProfileAvatar from '../components/UserProfileAvatar'
import ImagePlaceholderSkeleton from '../components/ImagePlaceholderSkeleton'
import { logoutStore } from '../store/utils'

interface IStateProps {
  // firebase:
  user: firebase.UserInfo
  // userData: IUser[]
  // userStatus?:
  // getUserStatus: (userId: string) => void
  // updateUserData: (id: string, data: ) => void
}

interface IupdateUserData {
  profilePicture?: string | undefined
  transcriptionLanguage?: string
  preferredLanguage?: string
  name?: string | undefined
  receiveNotification?: boolean
  showPunctuation?: boolean
  filterProfanity?: boolean
  apiKeys?: Array<IAPi>
}

// const customStyles = makeStyles({
//   root1: {
//     marginTop: '38px',
//     outline: 'none',
//   },
//   paper: {
//     width: '600px',
//     height: '268px',
//   },
// })

const _Settings = ({ user }: IStateProps): JSX.Element => {
  const [transLanguage, setTransLanguage] = useState<string>('it')
  const [displaylanguage, setDisplayLanguage] = useState<string>('en')
  const [emailCheck, setEmailCheck] = useState<boolean>(true)
  const [enablePunctuation, setEnablePunctuation] = useState<boolean>(true)
  const [profanityFilter, setProfanityFilter] = useState<boolean>(true)
  const [open, setOpen] = useState(false)
  const [credText, setCredText] = useState(false)
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [loginThrough, setLoginThrough] = useState<string>('')

  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [nextBtn, setNextBtn] = useState(true)
  const [loaderState, setLoaderState] = useState(false)

  const [authStatus, setAuthStatus] = useState(false)
  const [requestedApiKey, setRequestedApiKey] = useState(false)
  const [picture, setPicture] = useState<string | ArrayBuffer | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useAppDispatch()
  const { userData } = useAppSelector((store) => store.user) //userStatus
  const { currentAccount } = useAppSelector((store) => store.account)

  const navigate = useNavigate()
  const handlePictureChange = async (event: {
    target: { files: File[] }
  }): Promise<void> => {
    setIsLoading(true)
    customEvent('user_avatar_menu_clicked', {
      category: 'UserSettings',
      user_userId: user.uid,
      accountId: currentAccount.id,
    })
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = async () => {
      setPicture(reader.result)
      await updateUserData(user.uid, {
        profilePicture: reader.result as string,
      })
    }
    reader.readAsDataURL(file)
    setIsLoading(false)
  }

  // const classes = customStyles()

  useEffect(() => {
    if (user.uid) {
      setIsLoading(true)
      // getUserStatus(user.uid)
      dispatch(fetchUserById(user.uid))
    }
    if (!open) {
      setNextBtn(true)
      setCredText(false)
      setAuthStatus(false)
    }
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus, nextBtn, open, user.uid, picture])

  useEffect(() => {
    if (userData) {
      const storedTransLanguage =
        userData.transcriptionLanguage ||
        localStorage.getItem('lstn-trans-lng') ||
        'it'
      const storedDisplayLanguage =
        userData.preferredLanguage ||
        localStorage.getItem('lstn-display-lng') ||
        'en'
      const receiveNotification =
        userData.receiveNotification !== undefined
          ? userData.receiveNotification
          : true
      const showPunctuation =
        userData.showPunctuation !== undefined ? userData.showPunctuation : true
      const filterProfanity =
        userData.filterProfanity !== undefined ? userData.filterProfanity : true

      setEmailCheck(receiveNotification)
      setTransLanguage(storedTransLanguage)
      setDisplayLanguage(storedDisplayLanguage)
      setEnablePunctuation(showPunctuation)
      setProfanityFilter(filterProfanity)
    }
  }, [userData])

  const { t } = useTranslation()
  const languages = [
    { value: 'it', label: 'Italian' },
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Spanish' },
  ]

  // const upgrade = () => {
  //   history.push('/payment-plan')

  //   // ReactGA.event({
  //   //   action: "upgrade button pressed",
  //   //   category: "payment",
  //   // })
  // }

  // const portal = () => {
  //   history.push('/user-portal')
  //   customEvent('billing_manage_sub_opened', {
  //     category: 'payment',
  //     actionSource: 'current-sub',
  //     planId: userStatus && userStatus.plan,
  //     // priceId: "no",
  //     // priceValue: "3$",
  //   })
  //   // ReactGA.event({
  //   //   action: "portal button pressed",
  //   //   category: "payment",
  //   // })
  // }

  // const getAdditionalMinutes = () => {
  //   if (userStatus && userStatus.remainingTime >= 0) {
  //     return 0
  //   } else if (userStatus && userStatus.remainingTime < 0) {
  //     const calculatingAdditionalMinutes = Math.ceil(userStatus && userStatus.remainingTime)
  //     const additionalMinutes = Math.abs(calculatingAdditionalMinutes)
  //     return additionalMinutes
  //   }
  //   return
  // }
  // const getButton = () => {
  //   if (userStatus && userStatus.status === 'in_trial') {
  //     return (
  //       <button className="manage-billing-btn" onClick={upgrade}>
  //         {t && t('auth.daysRemaining', { days: userStatus.daysRemaining })}
  //       </button>
  //     )
  //   } else if (userStatus && userStatus.status === 'cancelled') {
  //     return (
  //       <button className="manage-billing-btn" onClick={upgrade}>
  //         {t && t('auth.subscriptionCancelled')}
  //       </button>
  //     )
  //   } else if (userStatus && (userStatus.status === 'active' || userStatus.status === 'non_renewing')) {
  //     return (
  //       <button className="manage-billing-btn" onClick={portal}>
  //         {t && t('auth.manageBilling')}
  //       </button>
  //     )
  //   }
  //   return null
  // }

  const handleTransLanguageChange = (value: string | undefined): void => {
    const ln = value || ''
    setTransLanguage(ln as string)
    localStorage.setItem('lstn-trans-lng', ln as string)
    if (user.uid) {
      updateUserData(user.uid, { transcriptionLanguage: ln })
      const { transcriptionLanguage } = userData
      customEvent('user_default_transcript_lang_changed', {
        user_userId: user.uid,
        accountId: currentAccount.id,
        actionSource: transcriptionLanguage,
        actionDestination: ln,
      })
    }
  }

  const handleDisplayLanguageChange = async (
    value: string | undefined,
  ): Promise<void> => {
    const ln = value || ''
    i18n.changeLanguage(ln as string)
    localStorage.setItem('lstn-display-lng', ln as string)
    if (user.uid) {
      updateUserData(user.uid, { preferredLanguage: ln })
    }
    const updateUser = firebase
      .app()
      .functions('us-central1')
      .httpsCallable('updateUser')
    if (userData) {
      try {
        const { preferredLanguage } = userData
        await updateUser({
          ...userData,
          preferredLanguage: ln,
          userId: user.uid,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        setDisplayLanguage(ln as string)

        customEvent('user_display_language_changed', {
          user_userId: user.uid,
          accountId: currentAccount.id,
          actionSource: preferredLanguage,
          actionDestination: ln,
        })
      } catch (error) {
        // console.log("error")
      }
    }
  }

  const handleUserName = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    const usName = event.target.value || 'ciao'
    if (user.uid) {
      updateUserData(user.uid, { name: usName as string })
      customEvent('user_display_name_changed', {
        user_userId: user.uid,
        accountId: currentAccount.id,
      })
    }
  }

  const logout = (user: firebase.UserInfo): void => {
    customEvent('sign_out_requested', {
      category: 'auth',
      user_userId: user.uid,
      accountId: currentAccount.id,
    })
    firebase
      .auth()
      .signOut()
      .then(() => {
        navigate('/')
        dispatch(logoutStore())
      })
      .catch((error: Error) => {
        console.error(error)
      })

    /* Clearing gist state */
    if (localStorage) {
      localStorage.removeItem('selectedAccount')
      localStorage.removeItem('chatDetails')
      localStorage.removeItem('gist-state')
    }

    if (document.cookie) {
      document.cookie =
        'gist_id_cg3hsghp' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      document.cookie =
        'gist_identified_cg3hsghp' +
        '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    }

    // this.props.history.push("/")
    // firebase.auth().signOut()
    // dispatch(resetUserStatus())
    customEvent('sign_out', {
      category: 'auth',
      user_userId: user.uid,
      accountId: currentAccount.id,
    })
    // ReactGA.event({
    //   action: "log out button pressed",
    //   category: "authentication",
    // })
  }
  const emailCheckBox = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailCheck(event.target.checked)
    updateUserData(user.uid, { receiveNotification: event.target.checked })
  }

  const handlePunctuation = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setEnablePunctuation(event.target.checked)
    updateUserData(user.uid, { showPunctuation: event.target.checked })
  }

  const handleFilterProfanity = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setProfanityFilter(event.target.checked)
    updateUserData(user.uid, { filterProfanity: event.target.checked })
  }

  // const gettingPlanName = () => {
  //   const plan:  = userStatus && userStatus.plan
  //   if (plan) {
  //     const getPlanName = planName[plan]
  //     return getPlanName
  //   }
  // }

  // const getRemainingTime = () => {
  //   if (userStatus && userStatus.remainingTime > 0) {
  //     const time = userStatus && userStatus.remainingTime
  //     const getTime = time.toFixed(2)
  //     return getTime
  //   } else if (userStatus && userStatus.remainingTime <= 0) {
  //     return 0
  //   } else {
  //     return undefined
  //   }
  // }
  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  async function updateUserData(
    id: string,
    data: IupdateUserData,
  ): Promise<void> {
    try {
      const userRef = database.collection(`users`).doc(id)
      const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
      await userRef.update({ ...data, updatedAt: updatedAt })
    } catch (error) {
      console.error('error', error)
    }
  }

  const handleSavePassword = (): void => {
    setOpen(false)
    const currentUser = firebase.auth().currentUser
    if (currentUser) {
      currentUser
        .updatePassword(password)
        .then(function () {
          Toast({
            text: t && t('settings.general.logoutMsg'),
            variant: 'success',
          })
          customEvent('change_password', {
            category: 'auth',
            actionSource: 'password_resetted',
            user_userId: user.uid,
            accountId: currentAccount.id,
          })
          logout(user)
        })
        .catch(function (error: Error) {
          Toast({ text: error.message, variant: 'error' })
        })
    }
  }

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value)
    if (
      event.target.value !== confirmPassword ||
      confirmPassword.length < 6 ||
      confirmPassword === ''
    ) {
      setCredText(true)
      setNextBtn(true)
    } else {
      setCredText(false)
      setNextBtn(false)
    }
  }
  const handleConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setConfirmPassword(event.target.value)
    if (
      password !== event.target.value ||
      password.length < 6 ||
      password === ''
    ) {
      setCredText(true)
      setNextBtn(true)
    } else {
      setCredText(false)
      setNextBtn(false)
    }
  }

  const handleCurrentPassword = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setCurrentPassword(event.target.value)
    if (event.target.value && event.target.value !== '') {
      setNextBtn(false)
    } else {
      setNextBtn(true)
    }
  }

  const handleNextBtn = (): void => {
    setLoaderState(true)
    setCredText(false)
    setNextBtn(true)
    const currentUser = firebase.auth().currentUser
    if (currentUser) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        currentUser.email || '',
        currentPassword,
      )
      currentUser
        .reauthenticateWithCredential(credential)
        .then(function () {
          // User re-authenticated.
          setAuthStatus(true)
          setLoaderState(false)
        })
        .catch(function () {
          setCredText(true)
          setLoaderState(false)
        })
    }
  }

  firebase
    .auth()
    .fetchSignInMethodsForEmail(user.email || '')
    .then(function (result) {
      // result.user.tenantId should be ‘TENANT_PROJECT_ID’.
      setLoginThrough(result[0])
    })
    .catch(function () {
      // Handle error.
    })

  const generateAPIToken = async (): Promise<void> => {
    setRequestedApiKey(true)
    try {
      const token = await firebase.auth()?.currentUser?.getIdToken()
      const apiKeyData = await axios.get(
        `${process.env.REACT_APP_FIREBASE_HTTP_CLOUD_FUNCTION_URL_API}/public/v1/getToken`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            'Access-Control-Expose-Headers': 'Content-Disposition',
          },
        },
      )

      if (apiKeyData.data.token) {
        const keyval = Object.assign([], userData.apiKeys)
        keyval.push({
          keyValue: apiKeyData.data.token,
        })
        await updateUserData(user.uid, { apiKeys: keyval })
        setRequestedApiKey(false)

        customEvent('user_generate_new_api_key', {
          user_userId: user.uid,
          accountId: currentAccount.id,
        })
      }
    } catch (err) {
      Toast({
        title: t && t('settings.general.tryAgain'),
        text: t && t('settings.general.wrong'),
        variant: 'error',
      })
      setRequestedApiKey(false)
    }
  }
  const handleCopy = (token: string | undefined): void => {
    navigator.clipboard.writeText(token ?? '')
    Toast({ text: t && t('transcripts.copyConfirm'), variant: 'success' })
    customEvent('user_copy_api_key', {
      user_userId: user.uid,
      accountId: currentAccount.id,
    })
  }

  const handleUploadButtonClick = (): void => {
    document.getElementById('contained-button-file')?.click()
  }

  return (
    <ThemeProvider>
      {userData && userData.email ? (
        <div
          className="flex flex-col mt-6 pl-4 sm:pl-0"
          data-cy="project-settings-page"
        >
          <SettingsBox
            titleText={t('settings.general.title')}
            userSettings={t('settings.title')}
          >
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/2 ">
                <div className="max-w-xs">
                  <form data-cy="project-settings-general-full-name">
                    <Typography className="text-base font-medium test-lstnBlueGray-900 mb-2">
                      {t && t('settings.general.userName')}
                    </Typography>
                    <Input
                      id="standard-basic"
                      className="!border !border-blue-gray-100 bg-white text-blue-gray-900 placeholder:text-blue-gray-500 focus:!border-blue-gray-800 focus:!border-t-blue-gray-900 "
                      labelProps={{
                        className: 'hidden',
                      }}
                      placeholder={t && t('settings.general.userName')}
                      defaultValue={userData.name || ''}
                      onChange={handleUserName}
                      variant="outlined"
                      crossOrigin={undefined}
                    />
                  </form>
                </div>
                <div className="mt-4 max-w-xs">
                  <div className="flex justify-between mb-2 items-center">
                    <Typography className="text-base font-medium text-lstnBlueGray-900 mb-0">
                      {t('settings.general.userIdHeading')}
                    </Typography>
                    {loginThrough && loginThrough === 'password' && (
                      <Button
                        className="text-sm font-medium text-lstnGreen-500 bg-transparent p-0 shadow-none capitalize hover:shadow-none"
                        onClick={handleClickOpen}
                      >
                        {t && t('settings.general.changePassword')}
                      </Button>
                    )}
                  </div>
                  <Input
                    id="standard-basic"
                    className="!border !border-blue-gray-100 bg-white text-blue-gray-900 placeholder:text-blue-gray-500 focus:!border-blue-gray-800 focus:!border-t-blue-gray-900"
                    labelProps={{
                      className: 'hidden',
                    }}
                    placeholder={t && t('settings.general.email')}
                    defaultValue={user.email || ''}
                    name="email"
                    variant="outlined"
                    crossOrigin={undefined}
                    disabled
                  />
                </div>
                <div
                  data-cy="project-settings-display-language"
                  className="mt-4"
                >
                  <Typography className="text-base font-medium text-lstnBlueGray-900 mb-2">
                    {t('settings.communication.displayLangTile')}
                  </Typography>
                  <Selector
                    selectClassName="border border-blue-gray-100 rounded-lg uppercase font-semibold"
                    className="w-fit"
                    options={languages}
                    selectedValue={displaylanguage}
                    onChange={handleDisplayLanguageChange}
                    children={undefined}
                  />
                </div>
              </div>
              <div className="flex-1 w-full md:w-2/5">
                <Typography className="text-base font-medium text-lstnBlueGray-900 mb-2">
                  {t('settings.general.photo')}
                </Typography>
                <div className="mb-3">
                  {!userData ? (
                    <div style={{ width: '74px', height: '74px' }}>
                      <ImagePlaceholderSkeleton />
                    </div>
                  ) : (
                    <UserProfileAvatar
                      userName={
                        userData.name ? userData.name : 'Profile Picture'
                      }
                      avatarUrl={String(userData.profilePicture)}
                      width="74px"
                      height="74px"
                      font="44px"
                    />
                  )}
                </div>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  hidden
                  onChange={
                    handlePictureChange as unknown as ChangeEventHandler<HTMLInputElement>
                  }
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="outlined"
                    size="sm"
                    onClick={handleUploadButtonClick}
                    loading={isLoading ? isLoading : !userData}
                  >
                    {t('uploadButton.uploadAvatar')}
                  </Button>
                </label>
                <Dialog
                  open={open}
                  handler={handleClose}
                  size="sm"
                  placeholder={undefined}
                >
                  <DialogHeader placeholder={undefined}>
                    {t && t('settings.general.changePassword')}
                  </DialogHeader>
                  <DialogBody placeholder={undefined}>
                    {!authStatus && (
                      <div>
                        <Typography className="text-base font-medium test-lstnBlueGray-900 mb-2">
                          {t && t('settings.general.currentPassword')}
                        </Typography>

                        <Input
                          id="password"
                          className="!border !border-blue-gray-100 bg-white text-blue-gray-900 placeholder:text-blue-gray-500 focus:!border-blue-gray-800 focus:!border-t-blue-gray-900 "
                          labelProps={{
                            className: 'hidden',
                          }}
                          placeholder={
                            t && t('settings.general.currentPassword')
                          }
                          onChange={handleCurrentPassword}
                          variant="outlined"
                          type="password"
                          crossOrigin={undefined}
                        />
                        {loaderState && (
                          <div className="auth-loader">
                            <Loader
                              type="Oval"
                              color="#04c61d"
                              height={40}
                              width={40}
                            />
                          </div>
                        )}
                        {credText && (
                          <label className="wrong-credentials">
                            {t && t('settings.general.wrongCredentials')}
                          </label>
                        )}
                      </div>
                    )}
                    {authStatus && (
                      <div>
                        <Typography className="text-base font-medium test-lstnBlueGray-900 mb-2">
                          {t && t('settings.general.newPassword')}
                        </Typography>

                        <Input
                          id="password1"
                          className="!border !border-blue-gray-100 bg-white text-blue-gray-900 placeholder:text-blue-gray-500 focus:!border-blue-gray-800 focus:!border-t-blue-gray-900 "
                          labelProps={{
                            className: 'hidden',
                          }}
                          placeholder={
                            t && t('settings.general.currentPassword')
                          }
                          onChange={handlePassword}
                          variant="outlined"
                          type="password"
                          crossOrigin={undefined}
                        />
                        <Typography className="text-base font-medium test-lstnBlueGray-900 mb-2">
                          {t && t('settings.general.confirmPassword')}
                        </Typography>

                        <Input
                          id="password2"
                          className="!border !border-blue-gray-100 bg-white text-blue-gray-900 placeholder:text-blue-gray-500 focus:!border-blue-gray-800 focus:!border-t-blue-gray-900 "
                          labelProps={{
                            className: 'hidden',
                          }}
                          placeholder={
                            t && t('settings.general.currentPassword')
                          }
                          onChange={handleConfirmPassword}
                          variant="outlined"
                          type="password"
                          crossOrigin={undefined}
                        />
                        {credText && (
                          <label className="pass-match">
                            {t && t('settings.general.notMatch')}
                          </label>
                        )}
                      </div>
                    )}
                  </DialogBody>

                  <DialogFooter placeholder={undefined}>
                    <Button
                      variant="text"
                      color="red"
                      onClick={handleClose}
                      className="mr-1"
                    >
                      <span>Cancel</span>
                    </Button>
                    {!authStatus && (
                      <Button
                        onClick={handleNextBtn}
                        className="mr-1"
                        disabled={nextBtn}
                      >
                        <span>Next</span>
                      </Button>
                    )}
                    {authStatus && (
                      <Button
                        onClick={handleSavePassword}
                        className="mr-1"
                        disabled={nextBtn}
                      >
                        <span>Next</span>
                      </Button>
                    )}
                  </DialogFooter>
                </Dialog>
              </div>
            </div>
          </SettingsBox>
          <SettingsBox titleText={t('settings.transcription.title')}>
            <div
              className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
              data-cy="project-settings-language"
            >
              <div
                className="flex-1"
                data-cy="project-settings-transcription-language"
              >
                <Typography className="text-base font-medium test-lstnBlueGray-900 mb-2.5">
                  {t('settings.communication.transLangTitle')}
                </Typography>

                <Selector
                  selectClassName="border border-blue-gray-100 rounded-lg uppercase font-semibold"
                  className="w-fit"
                  options={languages}
                  selectedValue={transLanguage}
                  onChange={handleTransLanguageChange}
                  children={undefined}
                />
              </div>
              <div className="flex-1 w-full md:w-2/5">
                <div>
                  <div data-cy="project-settings-language-notification">
                    <CustomCheckbox
                      name="checked"
                      label={t('settings.transcribed')}
                      checked={emailCheck}
                      onChange={emailCheckBox}
                      labelProps={{
                        className: 'font-normal',
                      }}
                    />
                  </div>
                  <div data-cy="project-settings-punctuation">
                    <CustomCheckbox
                      label={t('settings.transcription.enablePunctuation')}
                      name="punctuation"
                      checked={enablePunctuation}
                      onChange={handlePunctuation}
                      labelProps={{
                        className: 'font-normal',
                      }}
                    />
                  </div>
                  <div data-cy="project-settings-filter-profanity">
                    <CustomCheckbox
                      label={t('settings.transcription.profanityFilter')}
                      name="profanityFilter"
                      checked={profanityFilter}
                      onChange={handleFilterProfanity}
                      labelProps={{
                        className: 'font-normal',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </SettingsBox>

          {/* {userData && !userData.isEnterprise && (
           <SettingsBox titleText={t('settings.billing.title')}>
             <div className="settings-items-billing">
               <div className="settings-left-panel">
                 <div className="language-selection-text">{t('settings.billing.planTitle')}</div>
                 <div className="language-selection-text">{gettingPlanName()}</div>
               </div>
             </div>
             <div className="remaining-credit-text">
               {t('settings.billing.remainingCredits')}
               <span className="minutes-text">
                 {getRemainingTime()} {t('settings.billing.minutes')}
               </span>
             </div>
             <div className="settings-items-billing">{getButton()}</div>
             <div className="additional-minutes-text">
               {t('settings.billing.additionalMinutes')}
               <span className="minutes-text">
                 {getAdditionalMinutes()} {t('settings.billing.minutes')}
               </span>
             </div>
             <div className="additionalMinutesNote">{t('settings.billing.additionalMinutesNote')}</div>
           </SettingsBox>
         )} */}

          {userData.features && userData.features.developerOption && (
            <SettingsBox titleText={t('settings.developer.title')}>
              <div className="flex flex-col">
                <div className="settings-left-panel-developer">
                  <Button
                    className="flex justify-start mb-2 !border !border-lstnGreen-500 bg-white text-lstnGreen-500"
                    onClick={generateAPIToken}
                    disabled={requestedApiKey}
                  >
                    {t && t('settings.general.generateApi')}
                  </Button>
                  {userData.apiKeys &&
                    userData.apiKeys?.length > 0 &&
                    userData.apiKeys.map((data: IAPi, index: number) => {
                      return (
                        <div
                          key={index}
                          className="mt-4 max-w-xs sm:max-w-full"
                        >
                          <Typography className="flex text-md font-medium text-lstnBlueGray-400 mb-1">
                            API Key {index + 1}
                          </Typography>
                          <div className="flex">
                            <Textarea
                              disabled
                              id="api-key"
                              className="developerKey text-lstnBlueGray-400 text-md"
                              defaultValue={data.keyValue}
                            />
                            <IconButton
                              size="md"
                              variant="outlined"
                              className="border-transparent"
                              placeholder={undefined}
                            >
                              <FileCopyOutlinedIcon
                                onClick={() => handleCopy(data.keyValue)}
                                className="text-lstnGreen-400"
                              />
                            </IconButton>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </SettingsBox>
          )}

          <SettingsBox titleText={t('settings.misc.title')}>
            <div className="mt-2">
              <Button
                className="!border !border-blue-gray-100 bg-white text-blue-gray-900"
                onClick={() => logout(user)}
                data-cy="project-settings-general-logout"
              >
                {t('settings.general.logout')}
              </Button>
            </div>
          </SettingsBox>
        </div>
      ) : (
        <SplashScreen />
      )}
    </ThemeProvider>
  )
}
// const mapStateToProps = (state: ) => {
//   return {
//     userData: state.firestore.ordered.users,
//   }
// }

// const mapDispatchToProps = (dispatch: Dispatch) => {
//   return {
//     getUserStatus: (userId: string) => dispatch(fetchUserStatus.request(userId)),
//   }
// }
export default _Settings
// export default compose(
//   withFirebase,
//   connect(
//     // Map redux state to component props
//     ({ firebase: { auth }, userStatus }: IRootState) => ({
//       auth,
//       userStatus,
//     }),
//   ),
//   // connect(mapStateToProps),
//   firestoreConnect((props: ) => [
//     {
//       collection: 'users',
//       doc: props.user.uid,
//     },
//   ]),
//   withRouter,
//   withHandlers({
//     updateUserData1: (props: WithFirestoreProps) => async (id: string, data: ) => {
//       await props.firestore.update(`users/${id}`, { ...data })
//     },
//   }),
// )(_Settings)
