import React, { useEffect } from 'react'
import { Form } from 'formik'
import Typography from '../Typography/Typography'
import { Input } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '../Button/TailwindButton'
import { FormikErrors, FormikTouched } from 'formik'

interface IProps {
  values: {
    email: string
    password: string
  }
  errors: FormikErrors<{
    email: string
    password: string
  }>
  touched: FormikTouched<{
    email: string
    password: string
  }>
  handleChange: {
    (e: React.ChangeEvent<any>): void
    <T = string | React.ChangeEvent<any>>(
      field: T,
    ): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void
  }
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
  }
  handleSubmit: () => void
  setFieldValue: (field: string, value: string) => void
  inviteId: string | undefined | null
  setInviteEmail: React.Dispatch<React.SetStateAction<string>>
  passwordInputRef: React.RefObject<HTMLInputElement>
}
const LoginForm = ({
  handleChange,
  handleBlur,
  values,
  passwordInputRef,
  touched,
  errors,
  handleSubmit,
  inviteId,
  setFieldValue,
  setInviteEmail,
}: IProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    const fetchInviteEmail = async () => {
      try {
        if (!inviteId) return // If no inviteId, exit the function
        const response = await fetch(
          `${process.env.REACT_APP_FIREBASE_API}/public/getEmailByInviteId?inviteId=${inviteId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              // Authorization: `Bearer ${token}`,
            },
          },
        )
        const data = await response.json()
        if (data && data.inviteDoc) {
          const emailDoc = data.inviteDoc
          setFieldValue('email', emailDoc?.email)
          setInviteEmail(emailDoc?.email)
          // console.log('Invite email:', emailDoc?.email)
        } else {
          console.log('Invite document does not exist.')
        }
      } catch (error) {
        console.error('Error fetching invite:', error)
      }
    }

    fetchInviteEmail()
  }, [inviteId])
  return (
    <Form>
      <div className="mt-2 mb-2 w-full max-w-screen-lg">
        <div className="mb-1 flex flex-col gap-4">
          <div>
            <Typography
              variant="h6"
              className="mb-2 text-sm font-normal text-lstnBlueGray-900"
            >
              {t && t('auth.yourEmail')}
            </Typography>
            <Input
              size="lg"
              placeholder="jondoe@example.com"
              className=" !border-blue-gray-200 focus:!border-gray-900 placeholder:text-blue-gray-500 placeholder:opacity-100"
              labelProps={{
                className: 'hidden',
              }}
              data-cy="email-login"
              autoCapitalize="none"
              type="email"
              id="email"
              // className="custom-input"
              onChange={handleChange('email')}
              onBlur={handleBlur('email')}
              value={values.email}
              crossOrigin={undefined}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  if (
                    passwordInputRef.current != null &&
                    passwordInputRef.current.children[0] != null
                  ) {
                    ;(
                      passwordInputRef.current.children[0] as HTMLInputElement
                    ).focus()
                  }
                }
              }}
            />
            {touched.email && errors.email && (
              <Typography
                color="gray"
                className="mt-2 flex items-center gap-1 text-xs font-normal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="-mt-px h-4 w-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                    clipRule="evenodd"
                  />
                </svg>
                {errors.email}
              </Typography>
            )}
          </div>
          <div>
            <Typography
              variant="h6"
              className="mb-2 text-sm font-normal text-lstnBlueGray-900"
            >
              {t && t('auth.password')}
            </Typography>
            <Input
              size="lg"
              placeholder={t && t('auth.passwordPlaceholder')}
              className=" !border-blue-gray-200 focus:!border-gray-900 placeholder:text-blue-gray-500 placeholder:opacity-100"
              labelProps={{
                className: 'hidden',
              }}
              autoCapitalize="none"
              data-cy="password-login"
              type="password"
              id="password"
              ref={passwordInputRef}
              onChange={handleChange('password')}
              onBlur={handleBlur('password')}
              value={values.password}
              crossOrigin={undefined}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  handleSubmit()
                }
              }}
            />
            {touched.password && errors.password && (
              <Typography
                color="gray"
                className="mt-2 flex items-center gap-1 text-xs font-normal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="-mt-px h-4 w-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                    clipRule="evenodd"
                  />
                </svg>
                {errors.password}
              </Typography>
            )}
          </div>
        </div>
        <Button
          data-cy="login-button"
          className="mt-10 bg-green text-sm font-bold uppercase"
          fullWidth
          type="submit"
        >
          {t && t('auth.logIn')}
        </Button>
        <div className="text-center mt-6">
          <Link
            to="/reset-password"
            className="font-bold text-base underline text-lstnBlueGray-900 hover:text-blue-gray-900"
          >
            {t && t('auth.forgot')}
          </Link>
        </div>

        <Typography className="mt-8 text-center text-base text-lstnBlueGray-700 font-normal">
          {t && t('auth.dontAccount')}{' '}
          <Link
            to="/sign-up"
            className="font-medium text-gray-900 hover:text-blue-gray-900"
          >
            {t && t('auth.dontAccountSignUp')}
          </Link>
        </Typography>
      </div>
    </Form>
  )
}

export default LoginForm
