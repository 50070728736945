import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TransProps } from 'react-i18next'
import '../css/Projects.css'
import { RootState, useAppSelector } from '../store/store'
// import SplashScreen from '../components/SplashScreen'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-tailwind/react'
import Button from '../components/Button/TailwindButton'
import UploadIcon from '../icons/Upload.svg'
import CreateProjectDialog from '../components/Projects/CreateProjectDialog'
import ProjectCard from '../components/Projects/ProjectCard'
import {
  addProject,
  editProject,
  archiveProject,
} from '../store/features/projectSlice'
import { customEvent } from '../utils/customHooks'
import ProjectTour from '../components/onBoarding/ProjectTour'
import { database } from '../firebaseApp'
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react'
import { useNavigate } from 'react-router-dom'
import { roleType } from '../enums'

interface IonBoarding {
  onboardingSteps: {
    uploadVideos?: boolean
    transcriptionEdit?: boolean
    createProject?: boolean
  }
}
const Projects: React.FC<
  //@ts-ignore
  TransProps
> = () => {
  const { user, userData } = useAppSelector((store) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { projects, roles } = useAppSelector((store) => store.project)
  const { t } = useTranslation()
  const [openCreateProject, setOpenCreateProject] = useState(false)
  const [openEditProject, setOpenEditProject] = useState(false)
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string | undefined>('')
  const [projectId, setProjectId] = useState<string>('')
  const [openTour, setOpenTour] = useState<boolean>(false)
  const filteredProjects = projects.filter((project) => project.users[user.uid])
  const { accountRoles } = useAppSelector((store: RootState) => store.user)
  const navigate = useNavigate()
  const handleOpenCreate = (): void => {
    setOpenCreateProject(!openCreateProject)
  }

  const handleCreateProject = async (
    name: string,
    description?: string,
  ): Promise<void> => {
    customEvent('project_create_requested', {
      accountId: currentAccount.id,
      user_userId: user.uid,
    })
    const projectId = await addProject(
      currentAccount.id,
      name,
      user.uid,
      description,
    )
    navigate(`/project/${projectId}`)
    handleOpenCreate()
  }

  const handleOpenEdit = (): void => {
    setOpenEditProject(!openEditProject)
  }

  const handleEdit = (id: string, name: string, description?: string): void => {
    setProjectId(id)
    setName(name)
    setDescription(description)
    handleOpenEdit()
  }

  const handleEditProject = async (
    id: string,
    name: string,
    description?: string,
  ): Promise<void> => {
    await editProject(id, name, description)
    handleOpenEdit()
  }

  const handleArchive = async (id: string): Promise<void> => {
    await archiveProject(id)
    customEvent('project_archived', {
      accountId: currentAccount.id,
      projectId: id,
      actionSource: 'project-card',
      user_userId: userData.id,
    })
  }
  const closeOnboarding = useCallback(async () => {
    const onboardingSteps = { ...userData.onboardingSteps }
    onboardingSteps.createProject = true

    await onBoardingComplete(user.uid, { onboardingSteps })
    setOpenTour(false)
  }, [])

  useEffect(() => {
    if (
      userData &&
      userData.onboardingSteps &&
      !userData.onboardingSteps.createProject
    ) {
      setOpenTour(true)
    }
  }, [])
  async function onBoardingComplete(id: string, data: IonBoarding) {
    try {
      const userRef = database.collection(`users`).doc(id)
      await userRef.update({ ...data })
      // console.log('&&&&',userRef);
    } catch (error) {
      console.error('error', error)
    }
  }
  const verifyAccountRole = useMemo(() => {
    if (accountRoles && currentAccount) {
      const currentAccountRole = currentAccount.users[user.uid!]
      const accRoles = accountRoles[currentAccountRole?.role]?.['project']
      return accRoles
    }
  }, [accountRoles, currentAccount])
  const data = [
    {
      label: 'Active',
      value: 'active',
      desc: () => {
        if (
          filteredProjects.filter((project) => project.status === 'active')
            .length === 0
        ) {
          return (
            <div className="flex flex-1 flex-col items-center justify-center h-96 mt-10">
              <img src={UploadIcon} alt="Icon" className="mb-8" />
              <Typography
                className="text-2xl font-medium mb-3"
                placeholder="Your Projects"
              >
                {t('projects.getStarted')}
              </Typography>
              <Typography
                className="text-base font-normal text-lstnBlueGray-500 text-center mb-5"
                placeholder="Your Projects"
              >
                {t('projects.description')}
              </Typography>
              {verifyAccountRole?.includes(roleType.CREATEPROJECT) ? (
                <Typography
                  className="text-sm font-medium text-lstnBlue-500 cursor-pointer text-lstnGreen-500 flex items-center justify-center"
                  placeholder="Your Projects"
                  onClick={handleOpenCreate}
                >
                  <div className="inline-block lstn-btn w-3 h-3 p-0 rounded-full text-center relative mr-1">
                    <svg width="12" height="12" aria-hidden="true">
                      <use xlinkHref="#icon-pluss" />
                    </svg>
                  </div>
                  {t('projects.createFirst')}
                </Typography>
              ) : null}
            </div>
          )
        } else {
          return (
            <div className="justify-center flex flex-row flex-wrap xl:justify-between mb-6">
              {filteredProjects.map((project) => {
                if (project.status === 'active') {
                  return (
                    <ProjectCard
                      key={project.id}
                      project={project}
                      handleEdit={handleEdit}
                      handleArchive={handleArchive}
                      roles={roles}
                    />
                  )
                } else {
                  return null
                }
              })}
            </div>
          )
        }
      },
    },
    {
      label: 'Archived',
      value: 'archived',
      desc: () => {
        if (
          filteredProjects.filter((project) => project.status === 'archived')
            .length === 0
        ) {
          return (
            <div className="flex flex-1 flex-col items-center justify-center h-96 mt-10">
              <img src={UploadIcon} alt="Icon" className="mb-8" />
              <Typography
                className="text-2xl font-medium mb-3"
                placeholder="Your Projects"
              >
                {t('projects.getStarted')}
              </Typography>
              <Typography
                className="text-base font-normal text-lstnBlueGray-500 text-center mb-5"
                placeholder="Your Projects"
              >
                {t('projects.description')}
              </Typography>
              {verifyAccountRole?.includes(roleType.CREATEPROJECT) ? (
                <Typography
                  className="text-sm font-medium text-lstnBlue-500 cursor-pointer text-lstnGreen-500 flex items-center justify-center"
                  placeholder="Your Projects"
                  onClick={handleOpenCreate}
                >
                  <div className="inline-block lstn-btn w-3 h-3 p-0 rounded-full text-center relative mr-1">
                    <svg width="12" height="12" aria-hidden="true">
                      <use xlinkHref="#icon-pluss" />
                    </svg>
                  </div>
                  {t('projects.createFirst')}
                </Typography>
              ) : null}
            </div>
          )
        } else {
          return (
            <div className="justify-center flex flex-row flex-wrap xl:justify-between mb-6">
              {filteredProjects.map((project) => {
                if (project.status === 'archived') {
                  return (
                    <ProjectCard
                      key={project.id}
                      project={project}
                      handleEdit={handleEdit}
                      handleArchive={handleArchive}
                      roles={roles}
                    />
                  )
                } else {
                  return null
                }
              })}
            </div>
          )
        }
      },
    },
  ]

  return (
    <main className="projects-container flex flex-col">
      {user && user.uid ? (
        <>
          <div className="flex justify-between items-center">
            <Typography
              placeholder="Your Projects"
              className="text-2xl text-blue-gray-900 font-medium"
            >
              {t && t('projects.title')}
            </Typography>
            {verifyAccountRole?.includes(roleType.CREATEPROJECT) ? (
              <Button
                color="green"
                variant="filled"
                className="py-2 font-sans"
                onClick={handleOpenCreate}
                data-tut="tour-add-project-btn"
              >
                <span className="text-base pr-4">+</span>
                {t('projects.addProject')}
              </Button>
            ) : null}
          </div>
          <Tabs id="custom-animation" value="active" className="py-4">
            <TabsHeader placeholder={undefined} className=" w-full sm:w-96">
              {data.map(({ label, value }) => (
                <Tab placeholder={undefined} key={value} value={value}>
                  {label}
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody placeholder={undefined}>
              {data.map(({ value, desc }) => (
                <TabPanel key={value} value={value} className="p-0">
                  {desc()}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>

          <ProjectTour
            handleCloseTour={closeOnboarding}
            openTourGuide={openTour}
          />
        </>
      ) : null}
      <CreateProjectDialog
        open={openCreateProject}
        handleOpen={handleOpenCreate}
        dialogType="create"
        handleCreate={handleCreateProject}
      />
      <CreateProjectDialog
        open={openEditProject}
        dialogType="edit"
        handleOpen={handleOpenEdit}
        handleEdit={handleEditProject}
        projectId={projectId}
        name={name}
        description={description}
      />
    </main>
  )
}

export default Projects
