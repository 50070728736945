import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { ThemeProvider } from '@material-ui/styles'
import { TransitionProps } from '@material-ui/core/transitions'
import { makeStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import { Folder, FolderOpen } from '@material-ui/icons'

import { lstnBasictheme } from '../../theme/lstn-theme-basic'
import { useTranslation } from 'react-i18next'
import { IFolders, ITranscriptRow } from '../../interfaces'

interface IProps {
  open: boolean
  moveFolderBoxSelect: (id: string) => void
  folderHierarchy?: IFolders[]
  checkedRowsData: ITranscriptRow[]
  moveFolderBox: (status: boolean) => void
  folderData?: IFolders
}

interface treeItems {
  id: string
  name: string
  children?: treeItems[]
}

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    // @ts-ignore
    return <Slide direction="up" ref={ref} {...props} />
  },
)

export default function MoveDialog(props: IProps) {
  const [selectedMoveFolder, setSelectedMoveFolder] = useState('')
  const [selectedFolder, setSelectedFolder] = useState<string[]>([])

  const { t } = useTranslation()
  const useStyles = makeStyles({
    root: {
      maxWidth: 600,
      width: '-webkit-fill-available',
    },
  })

  const folderHierarchy = [
    {
      id: 'root',
      name: 'Home' + (!props.folderData ? ' [Current]' : ''),
      children: props.folderHierarchy,
    },
  ]

  const handleMove = () => () => {
    props.moveFolderBoxSelect(selectedMoveFolder)
  }

  const handleClose = (status: boolean) => () => {
    props.moveFolderBox(status)
  }

  useEffect(() => {
    const ids: string[] = []
    props.checkedRowsData.map((row) => {
      if (row.status == 'Folder') {
        ids.push(row.id)
      }
    })
    setSelectedFolder(ids)
  }, [props.checkedRowsData])

  //console.log(folderHierarchy)
  const getTreeItemsFromData = (treeItems: treeItems[]) => {
    return treeItems.map((treeItemData: treeItems) => {
      let children = undefined
      if (
        treeItemData.children &&
        treeItemData.children.length > 0 &&
        selectedFolder.indexOf(treeItemData.id) === -1
      ) {
        children = getTreeItemsFromData(treeItemData.children)
      }
      return (
        <TreeItem
          className={
            (props.folderData && props.folderData.id == treeItemData.id) ||
            selectedFolder.indexOf(treeItemData.id) > -1
              ? 'disabled'
              : ''
          }
          key={treeItemData.id}
          nodeId={treeItemData.id}
          label={
            treeItemData.name +
            (props.folderData && props.folderData.id == treeItemData.id
              ? ' [Current]'
              : '')
          }
          children={children}
        />
      )
    })
  }

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string) => {
    if (
      (props.folderData && props.folderData.id == nodeIds) ||
      selectedFolder.indexOf(nodeIds) > -1
    ) {
      setSelectedMoveFolder('')
    } else {
      setSelectedMoveFolder(nodeIds)
    }
  }

  const classes = useStyles()
  return (
    <ThemeProvider theme={lstnBasictheme}>
      <Dialog
        classes={{ paperWidthSm: classes.root }}
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        data-cy="data-project-move-dialog"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Move Folder/Transcript
        </DialogTitle>
        <DialogContent>
          {folderHierarchy && folderHierarchy.length > 0 && (
            <TreeView
              defaultCollapseIcon={<FolderOpen />}
              defaultExpandIcon={<Folder />}
              defaultEndIcon={<Folder />}
              defaultExpanded={['root']}
              onNodeSelect={handleSelect}
              data-cy="data-project-move-tree"
            >
              {getTreeItemsFromData(folderHierarchy)}
            </TreeView>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose(false)}
            color="secondary"
            variant="outlined"
            data-cy="data-project-move-cancel"
          >
            {t('folder.cancel')}
          </Button>
          <Button
            onClick={handleMove()}
            color="primary"
            variant="contained"
            disabled={!selectedMoveFolder}
            data-cy="data-project-move-submit"
          >
            Move
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}
