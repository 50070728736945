import React, { useEffect, useMemo } from 'react'
import firebase from 'firebase/compat/app'
import Header from './Header'
import Sidebar from './Sidebar'
import { useLocation } from 'react-router-dom'
import { Button } from '@material-tailwind/react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { customEvent } from '../utils/customHooks'
import { useAppSelector } from '../store/store'
type LayoutProps = {
  children: React.ReactNode
  user: firebase.User
  isEnterprise?: boolean
}
const Layout: React.FC<LayoutProps> = ({ children, user, isEnterprise }) => {
  // const transcript = useAppSelector((store) => store.paragraphsSlice)
  const { currentAccount } = useAppSelector((store) => store.account)
  const location = useLocation()
  const storedSidebarCollapse = localStorage.getItem('sidebarCollapse')
  const [sidebarCollapse, setSidebarCollapse] = React.useState(
    storedSidebarCollapse ? JSON.parse(storedSidebarCollapse) : false,
  )
  const checkPath = useMemo(() => {
    const currentPath = location && location.pathname
    if (
      currentPath === '/transcripts' ||
      currentPath.includes('/transcript/folder/') ||
      currentPath === '/projects' ||
      currentPath.includes('/project')
    ) {
      return location.pathname
    } else {
      return
    }
  }, [location])
  useEffect(() => {
    localStorage.setItem('sidebarCollapse', JSON.stringify(sidebarCollapse))
  }, [sidebarCollapse])
  // console.log(location.pathname, 'log')
  const handleSidebarCollapse = () => {
    setSidebarCollapse(!sidebarCollapse)
    customEvent('nav_sidebar_collaps_status_change', {
      accountId: currentAccount.id,
      user_userId: user && user.uid,
      actionSource: 'from-chevron-icon',
      actionMetadata: sidebarCollapse
        ? 'nav-sidebar-collapsed'
        : 'nav-sidebar-opened',
    })
  }

  return (
    <div className="flex">
      {user && user.uid && checkPath && (
        <div className="h-screen hidden lg:flex top-0 sticky">
          <div
            className={`h-screen hidden lg:flex  ${
              sidebarCollapse ? 'w-[18rem]' : 'w-[5rem]'
            } sticky top-0 hover:w-[18rem] group transition-all duration-300`}
          >
            <Sidebar sidebarCollapse={sidebarCollapse} />
          </div>
          <div className="absolute bottom-10 right-[-20px] ">
            <Button
              size="sm"
              placeholder={undefined}
              className=" rounded-full p-2 bg-white border border-blue-gray-50 text-black flex justify-center items-center"
              onClick={handleSidebarCollapse}
            >
              <ArrowForwardIosIcon
                fontSize="small"
                className={`${
                  sidebarCollapse ? 'rotate-180' : ''
                } group-hover:transition-transform`}
              />
            </Button>
          </div>
        </div>
      )}
      <div className="w-full">
        {user && user.uid && location.pathname !== '/create-account' ? (
          <Header
            isEnterprise={isEnterprise}
            userId={user.uid}
            children={undefined}
          />
        ) : (
          ''
        )}
        {children}
      </div>
    </div>
  )
}

export default Layout
