import { createTheme } from '@material-ui/core/styles'

export const lstnBasictheme = createTheme({
  palette: {
    primary: {
      // rest will be calculated from palette.primary.main,
      main: '#2ed33f',
      contrastText: '#fff',
    },
    secondary: {
      main: '#252627',
    },
  },
})

export const shortcutDialogTheme = createTheme({
  overrides: {
    MuiDialogTitle: {
      root: {
        marginTop: '-35px',
      },
    },
  },
})

export const tourDialogTheme = createTheme({
  overrides: {
    MuiDialogTitle: {
      root: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    MuiDialogActions: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    MuiDialogContent: {
      root: {
        display: 'flex',
        justifyContent: 'center',
        color: '#000',
      },
    },
  },
  palette: {
    primary: {
      main: '#35da3c',
    },
  },
})
