import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn:
    process.env.REACT_APP_ENV != 'staging'
      ? process.env.REACT_APP_SENTRY_URL
      : '',
})

export default Sentry
