import React from 'react'
import { Avatar } from '@material-tailwind/react'
import AccountCircle from '@material-ui/icons/AccountCircle'

export interface ICommentBoxProps {
  name: string
  avatar?: string
  date: string
  comment: string
  length: number
  index: number
}
const CommentBox: React.FC<ICommentBoxProps> = ({
  name,
  avatar,
  date,
  comment,
  length,
  index,
}) => {
  return (
    <>
      <div className="flex flex-row w-full gap-3">
        <div className="flex flex-col items-center">
          {avatar ? (
            <Avatar src={avatar} size="sm" placeholder={undefined} />
          ) : (
            <AccountCircle className="!text-4xl text-blue-gray-500" />
          )}
          {length !== index && (
            <div className="border-2 border-blue-gray-100 h-4 w-0 mt-2 rounded-lg" />
          )}
        </div>
        <div>
          <div className="flex items-center gap-2">
            <h3 className="font-sans font-bold text-sm text-blue-gray-900">
              {name}
            </h3>
            <h4 className="font-sans font-bold text-xs text-blue-gray-500">
              {date}
            </h4>
          </div>
          <p className="font-sans text-sm text-blue-gray-900">{comment}</p>
        </div>
      </div>
    </>
  )
}

export default CommentBox
