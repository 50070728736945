import React from 'react'
import { Tabs, TabsHeader, Tab } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'

export function UnderlineTabs(): JSX.Element {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = React.useState('myUser')
  const data = [
    {
      label: t('settings.tab.myUser'),
      value: 'myUser',
    },
    // {
    //   label: 'Notification',
    //   value: 'notification',
    // },
  ]
  return (
    <Tabs value={activeTab} className="mb-6 mt-1 w-fit">
      <TabsHeader
        className="rounded-none border-b border-gray-200 bg-transparent p-0"
        indicatorProps={{
          className:
            'bg-transparent border-b-2 border-lstnBlueGray-500 shadow-none rounded-none my-3',
        }}
        placeholder={undefined}
      >
        {data.map(({ label, value }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setActiveTab(value)}
            className="text-lstnBlueGray-500 my-3"
            placeholder={undefined}
            style={{ width: 'fit-content' }}
          >
            {label}
          </Tab>
        ))}
      </TabsHeader>
    </Tabs>
  )
}
