import React from 'react'
import { Button } from '@material-tailwind/react'
import { ButtonProps, ThemeProvider } from '@material-tailwind/react'

interface IProps {
  // color: 'primary' | 'default'
}
interface ButtonStyleTypes {
  button: {
    defaultProps: {
      variant: string
      size: string
      fullWidth: boolean
      ripple: boolean
      className: string
    }
    valid: {
      sizes: string[]
      colors: string[]
    }
    styles: {
      sizes: {
        sm: object
        md: object
        lg: object
      }
      variants: {
        filled: object
      }
    }
  }
}
const theme: ButtonStyleTypes = {
  button: {
    defaultProps: {
      variant: 'filled',
      size: 'md',
      fullWidth: false,
      ripple: true,
      className: '',
    },
    valid: {
      sizes: ['sm', 'md', 'lg'],
      colors: ['green'],
    },
    styles: {
      sizes: {
        sm: {
          fontSize: 'text-xs',
          borderRadius: 'rounded-lg',
        },
        md: {
          fontSize: 'text-xs',
          borderRadius: 'rounded-lg',
        },
        lg: {
          fontSize: 'text-sm',
          borderRadius: 'rounded-lg',
        },
      },
      variants: {
        filled: {
          green: {
            backgroud: 'bg-lstnGreen-500',
            color: 'text-white',
            shadow: 'shadow-md shadow-lstnGreen-500/10',
            hover: 'hover:shadow-lg hover:shadow-lstnGreen-500/20',
            focus: 'focus:opacity-[0.85] focus:shadow-none',
            active: 'active:opacity-[0.85] active:shadow-none',
          },
        },
      },
    },
  },
}

function CustomButton(props: IProps & ButtonProps) {
  return (
    <ThemeProvider value={theme}>
      {/* @ts-ignore */}
      <Button {...props} />
    </ThemeProvider>
  )
}

export default CustomButton
