import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import classNames from 'classnames'
import '../../css/create-account.css'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@material-ui/icons/Check'

interface Option {
  label: string
  value: string
}

type SelectedType = string[]

interface MultipleSelectProps {
  options: Option[]
  onChange: (selected: SelectedType) => void
  selectedValue?: SelectedType
}

const useStyles = makeStyles(() => ({
  formControl: {
    maxWidth: '100%',
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

// const getStyles = (name: string, selected: string[]) => ({
//   fontWeight: selected.indexOf(name) === -1 ? 'normal' : 'normal',
//   fontSize: 'text-base',
// })

const MultipleSelect: React.FC<MultipleSelectProps> = ({
  options,
  onChange,
  selectedValue,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  console.log(selectedValue, 'selectedValue')
  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    const selected = event.target.value as SelectedType
    onChange(selected)
  }

  return (
    <FormControl className={classNames(classes.formControl)}>
      <Select
        multiple
        displayEmpty
        value={selectedValue || []}
        onChange={handleChange}
        input={
          <Input
            id="select-multiple-chip"
            disableUnderline
            className="border rounded-lg border-lstnBlueGray-100 h-10 text-xs flex items-center font-normal text-blue-gray-300 multiple-select"
          />
        }
        renderValue={(selected: SelectedType | unknown) => {
          if (Array.isArray(selected)) {
            if (selected.length === 0) {
              return (
                <span className="text-sm font-medium text-blue-gray-400">
                  {t && t('createAccount.useCasePlaceholder')}
                </span>
              )
            }
            return selected.join(', ')
          }
          // Default return statement
          return null
        }}
        MenuProps={MenuProps}
        data-cy="data-account-use-case"
      >
        {options.map((option) =>
          selectedValue?.includes(option.value) ? (
            <MenuItem
              key={option.label}
              value={option.value}
              className="select-item text-sm text-blue-gray-700"
              data-cy="data-account-use-cases"
            >
              <span>{option.label}</span>
              <CheckIcon className=" text-blue-gray-700" />
            </MenuItem>
          ) : (
            <MenuItem
              key={option.label}
              value={option.value}
              className="select-item text-sm text-blue-gray-700 group"
              data-cy="data-account-use-cases"
            >
              {option.label}
              <span className="hidden group-hover:block">
                <CheckIcon className="text-gray-500 " />
              </span>
            </MenuItem>
          ),
        )}
      </Select>
    </FormControl>
  )
}

export default MultipleSelect
