import React, { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import { ProgressType } from '../../enums'
import { ITranscript, IUser } from '../../interfaces'
import TranscriptionEditor from '../TranscriptDetails/TranscriptionEditor'
import { useAppSelector } from '../../store/store'

interface IProps {
  transcript: ITranscript & { paragraphsForTranscript: string }
  transcriptId: string
  firestore: firebase.firestore.Firestore
  updateStatus: (status: ProgressType) => void
  userData?: IUser
  rolesValue?: {}
}

const EditorsRoleArrange = (props: IProps) => {
  const [userRole, setUserRole] = useState<string[]>([])
  const { accountRoles, user } = useAppSelector((store) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)
  const currentAccountRole = currentAccount.users[user.uid!]

  useEffect(() => {
    if (accountRoles) {
      setUserRole(accountRoles[currentAccountRole?.role]?.['transcript'])
    }
  }, [accountRoles, currentAccountRole])

  const checkRole = (role: string) => userRole?.includes(role)

  //@ts-ignore
  return <TranscriptionEditor {...props} checkRole={checkRole} />
}

export default EditorsRoleArrange
