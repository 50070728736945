import React from 'react'
import { Typography } from '@material-tailwind/react'
import type { TypographyProps } from '@material-tailwind/react'

function CustomTypography(props: TypographyProps) {
  // @ts-ignore
  return <Typography {...props} />
}

export default CustomTypography
