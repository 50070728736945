import React from 'react'
import { Select, Option } from '@material-tailwind/react'
import type { SelectProps } from '@material-tailwind/react'
import Button from '../Button/TailwindButton'

interface IProps {
  options: Array<{
    value: string
    label: string
    disabled?: boolean
    color?: string
  }>
  selectedValue?: string
  onChange: (value: string) => void
  handleMarkClick?: (e: React.MouseEvent<HTMLElement>) => void
  selectClassName?: string
}

export const SelectColor: React.FC<IProps & SelectProps> = (props) => {
  return (
    <div className={`${props.className} border-none relative`}>
      <div className="absolute z-10 top-2 left-3">
        <Button
          className=" bg-white p-0 shadow-none"
          value={props.selectedValue}
          onClick={props.handleMarkClick}
          data-cy="transcript-details-MarkButton"
        >
          <img
            src={require(`../../icons/${props.selectedValue}.svg`)}
            className="w-5"
            alt="img"
          />
        </Button>
      </div>
      <Select
        onChange={props.onChange}
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
        className={`${props.className} border border-lstnBlueGray-600 h-[2.46rem] top-[-1px]`}
        value={props.selectedValue}
        labelProps={{
          className: 'hidden',
        }}
        placeholder={undefined}
      >
        {props.options &&
          props.options.map((option) => (
            <Option
              key={option.value}
              className="z-10 flex items-center justify-between capitalize"
              value={option.color}
              disabled={option.disabled}
              data-cy={`data-project-export-file-type-${option.value}`}
            >
              {option.label}{' '}
              <span
                className="w-8 h-6 rounded-lg"
                style={{ backgroundColor: option.value }}
              ></span>
            </Option>
          ))}
      </Select>
    </div>
  )
}
