import React from 'react'
import { Progress, ThemeProvider } from '@material-tailwind/react'
import type { ProgressProps } from '@material-tailwind/react'

interface ProgressStylesType {
  progress: {
    valid: {
      variants: string[]
      colors: string[]
      sizes: string[]
    }
    styles: {
      variants: {
        filled: object
      }
    }
  }
}
const theme: ProgressStylesType = {
  progress: {
    valid: {
      variants: ['filled', 'gradient'],
      colors: ['green'],
      sizes: ['sm', 'md', 'lg'],
    },
    styles: {
      variants: {
        filled: {
          green: {
            backgroud: 'bg-lstnGreen-500',
            color: 'text-white',
          },
        },
      },
    },
  },
}
function CustomProgress(props: ProgressProps) {
  return (
    <ThemeProvider value={theme}>
      {/* @ts-ignore */}
      <Progress {...props} />
    </ThemeProvider>
  )
}

export default CustomProgress
