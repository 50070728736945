import { ITranscript, ITranscriptRow, IFolders } from '../interfaces'
import firebase from 'firebase/compat/app'
import moment from 'moment'
import { roleType } from '../enums'

export const filterTranscriptData = (data: ITranscript[]) => {
  const filteredData: ITranscript[] = []
  // console.log('data', data)
  if (data && data.length) {
    data.map(
      (transcript: ITranscript) =>
        !transcript.archive && filteredData.push(transcript),
    )
    return filteredData
  }
  return data
}

export const filterTranscriptDataNoFolder = (data: ITranscript[]) => {
  const filteredData: ITranscript[] = []
  // console.log('data2', data)
  if (data && data.length) {
    data.map(
      (transcript: ITranscript) =>
        !transcript.archive &&
        typeof transcript.folderId === 'undefined' &&
        filteredData.push(transcript),
    )
    return filteredData
  }
  return data
}

export const filterTranscriptDataAlone = (
  allTranscript: ITranscript[],
  data: ITranscript[],
  folders: IFolders[],
  uid: string,
  rootFolder: any,
) => {
  const transcripts: ITranscript[] = []
  data = filterTranscriptData(data)

  // console.log(allTranscript, data, folders, uid, rootFolder)
  const isExist = (transcriptId: string | undefined) => {
    let exist = false
    for (let index = 0; index < allTranscript.length; index++) {
      const list = allTranscript[index]
      if (list.id == transcriptId) {
        exist = true
      }
    }
    return exist
  }

  //console.log('folderssssssss', folders)
  if (folders.length == 0) {
    if (data.length == 0 || rootFolder) {
      return []
    }
    for (let index = 0; index < data.length; index++) {
      const ll = data[index]
      if (
        ll.shareUserId &&
        ll.shareUserId.indexOf(uid) > -1 &&
        !isExist(ll.id)
      ) {
        transcripts.push(ll)
      }
    }
    return transcripts
  }

  const str = JSON.stringify(folders)
  for (let index = 0; index < data.length; index++) {
    const transcript = data[index]
    let find = false
    if (
      str.indexOf('"id":"' + transcript.folderId + '"') > -1 ||
      (rootFolder && rootFolder.id != transcript.folderId)
    ) {
      find = true
      break
    }
    if (!find && transcript.folderId) {
      transcripts.push(transcript)
    }
  }

  const filteredData: ITranscript[] = []
  if (transcripts && transcripts.length > 0) {
    transcripts.map(
      (transcript: ITranscript) =>
        !transcript.archive && filteredData.push(transcript),
    )
  }
  return filteredData
}

export const filterTranscriptObject = (data: {
  [key: string]: ITranscript
}) => {
  const filteredData: { [key: string]: ITranscript } = {}
  for (const transcript in data) {
    if (!data[transcript].archive) {
      filteredData[transcript] = data[transcript]
    }
  }
  return filteredData
}

export const parseToTranscriptList = (
  transcripts: ITranscript[],
  roles: { [key: string]: string[] },
  user: string,
  currentAccountRole: {
    status: string
    role: string
  },
) => {
  const data: ITranscriptRow[] = []
  const uniqueIds: string[] = []

  transcripts.map((transcript) => {
    if (transcript.id && uniqueIds.indexOf(transcript.id) === -1) {
      uniqueIds.push(transcript.id)
      const row: ITranscriptRow = {
        id: transcript.id ? transcript.id : '',
        name: transcript.name ? transcript.name : '',
      }

      if (transcript.status) {
        const updatedDate = transcript.status.lastUpdated
          ? new firebase.firestore.Timestamp(
              transcript.status.lastUpdated.seconds,
              transcript.status.lastUpdated.nanoseconds,
            ).toDate()
          : ''
        row.status = transcript.status.progress
        row.errorMessage = transcript.status.error?.message
        row.lastModified = updatedDate
          ? moment(updatedDate).format('DD MMM YYYY hh:mm')
          : '-'
      }

      if (transcript.metadata) {
        const metadata = transcript.metadata
        const audioDuration = metadata.audioDuration
          ? metadata.audioDuration
          : 0
        const durationObj = moment.duration(audioDuration * 1000)
        let duration = ''
        if (durationObj.days() > 0) {
          duration = `${durationObj.days()} d ${durationObj.hours()} h`
        } else if (durationObj.hours() > 0) {
          duration = `${durationObj.hours()} h ${durationObj.minutes()} m`
        } else if (durationObj.minutes() > 0) {
          duration = `${durationObj.minutes()} m`
        } else if (durationObj.hours() === 0 && durationObj.minutes() === 0) {
          duration =
            durationObj.seconds() === 0 ? '-' : `${durationObj.seconds()} s`
        }
        row.duration = duration
      }

      const creationDate = transcript.createdAt
        ? new firebase.firestore.Timestamp(
            transcript.createdAt.seconds,
            transcript.createdAt.nanoseconds,
          ).toDate()
        : ''
      row.uploadedOn = creationDate
        ? moment(creationDate).format('DD MMM YYYY hh:mm')
        : '-'
      row.shareUserId = transcript.shareUserId
      row.sharedUserIds = transcript.sharedUserIds
      row.sharedUserRoles = transcript.sharedUserRoles
      row.userId = transcript.userId
      if (transcript.userId === user) {
        // row.roles = roles['transcripts'][roleType.EDITOR]
        row.roles = roles[currentAccountRole?.role]?.['transcript']
      } else if (transcript.sharedUserIds?.includes(user)) {
        if (
          transcript.sharedUserRoles &&
          transcript.sharedUserRoles[user] === roleType.SHAREDEDIT
        ) {
          // row.roles = roles['transcripts'][roleType.EDITOR]
          row.roles = roles[currentAccountRole?.role]?.['transcript']
        } else {
          // row.roles = roles['transcripts'][roleType.VIEWER]
          row.roles = roles[currentAccountRole?.role]?.['transcript']
        }
      }
      data.push(row)
    }
  })

  return data
}

export const parseToFoldersList = (
  folders: IFolders[],
  roles: { [key: string]: string[] },
  user: string,
  currentAccountRole: {
    status: string
    role: string
  },
) => {
  const data: ITranscriptRow[] = []
  folders.map((folder) => {
    if (!folder.archive) {
      const checkForParent = folders.filter(
        (d) => d.id === folder.parentFolderId,
      )
      if (!(checkForParent.length > 0)) {
        const row: ITranscriptRow = {
          id: folder.id ? folder.id : '',
          name: folder.name ? folder.name : '',
        }
        row.status = 'Folder'
        row.duration = '--'
        row.uploadedOn = moment(folder.createdAt).format('DD MMM YYYY hh:mm')
        row.lastModified = moment(folder.lastModified).format(
          'DD MMM YYYY hh:mm',
        )
        row.shareUserId = folder.shareUserId
        row.sharedUserIds = folder.sharedUserIds
        row.sharedUserRoles = folder.sharedUserRoles
        row.userId = folder.userId
        row.roles = []
        row.parentFolderId = folder.parentFolderId
        if (folder.userId === user) {
          // row.roles = roles['fileMgmt'][roleType.SHAREDEDIT]
          row.roles = roles[currentAccountRole?.role]?.['transcript']
        } else if (folder.sharedUserIds?.includes(user)) {
          if (
            folder.sharedUserRoles &&
            folder.sharedUserRoles[user] === roleType.SHAREDEDIT
          ) {
            // row.roles = roles['fileMgmt'][roleType.SHAREDEDIT]
            row.roles = roles[currentAccountRole?.role]?.['transcript']
          } else {
            // row.roles = roles['fileMgmt'][roleType.SHAREDREAD]
            row.roles = roles[currentAccountRole?.role]?.['transcript']
          }
        }
        data.push(row)
      }
    }
  })
  return data
}

export const checkDuplicates = (folders: ITranscriptRow[]) => {
  // console.log(folders)
  const uniqueFoldersMap = new Map<string, ITranscriptRow>()
  for (const folder of folders) {
    // Check if the folder id is not already present or if it's parent folder id is not present
    if (
      folder.id &&
      !uniqueFoldersMap.has(folder.id) &&
      !uniqueFoldersMap.has(folder.parentFolderId as string)
    ) {
      uniqueFoldersMap.set(folder.id, folder)
    }
  }
  return Array.from(uniqueFoldersMap.values())
}
