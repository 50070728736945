// @ts-nocheck
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  Fragment,
} from 'react'
import isHotkey from 'is-hotkey'
import {
  Editable,
  withReact,
  Slate,
  RenderElementProps,
  ReactEditor,
} from 'slate-react'
import { Editor, Transforms, createEditor, Node } from 'slate'
import { useTranslation } from 'react-i18next'
import { withHistory } from 'slate-history'
// import { compose } from "recompose"
// import { withFirestore } from "react-redux-firebase"
// import { connect } from "react-redux"
import { createDraft, finishDraft } from 'immer'
import {
  ArrowBackIos as Left,
  StrikethroughS as Strike,
  ArrowForwardIos as Right,
} from '@material-ui/icons'
import { Button } from 'semantic-ui-react'
import ReactGA from 'react-ga4'
import { TextField } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
// import {  updateFramesPerSecond, updateTimecode } from "../../store/actions/transcriptActions"
// import HighlightIcon from '../../icons/Group.svg'
import nanoSecondsToFormattedTime from '../../nanoSecondsToFormattedTime'
import AudioPlayer from './AudioPlayer'
import VideoPlayer from './VideoPlayer'
import ExportMenu from '../ExportMenu/ExportMenu'
import { ITranscript } from '../../interfaces'
import { database } from '../../firebaseApp'
import { useInterval } from '../../utils/customHooks'
import { ProgressType, roleType } from '../../enums'
import {
  ThemeProvider as MuiThemeProvider,
  makeStyles,
} from '@material-ui/styles'
import { lstnBasictheme } from '../../theme/lstn-theme-basic'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Skeleton from '@material-ui/lab/Skeleton'
// import { SetCurrentTimeState } from "../../store/actions/transcriptActions"
import { useAppSelector, useAppDispatch } from '../../store/store'
import { customEvent } from '../../utils/customHooks'
import { readParagraph } from '../../store/features/paragraphSlice'
// import ShareTranscript from "../ShareList/ShareTranscript"
import {
  UpdateSpeakerName,
  setloading,
} from '../../store/features/paragraphSlice'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import {
  IconButton,
  Input,
  Card,
  CardBody,
  CardFooter,
  Dialog,
} from '@material-tailwind/react'
import Redo from '../../icons/Redo.svg'
import Undo from '../../icons/Undo.svg'
import translation from '../../icons/translation.svg'
import { SelectColor } from './Select'
import Delete from '../../icons/Delete.svg'
import Typography from '../Typography/Typography'
import CustomTooltip from '../Tooltip/Tooltip'
import { setEditSpeakerState } from '../../store/features/transcript/transcriptsSlice'
// import Checkbox from '../Checkbox/Checkbox'
// import UnverifyIcon from '../../icons/Unverify.svg'
// import Tooltip from '../Tooltip/Tooltip'
import lockIcon from '../../icons/lock.svg'
import UpgradeModal from '../UpgradeModal'
import { TranslateDialog } from './TranslateDialog'
import { ChipIcon } from './TranslationChip'
import { translationSourceLanguages } from '../../utils/translationLanguages'

const HOTKEYS = {
  'mod+j': 'strike',
  'mod+h': 'highlight',
}

const KEYS = [
  'Shift',
  'Alt',
  'Control',
  'Capslock',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Tab',
  'CapsLock',
  'NumLock',
  'Meta',
  'End',
  'Home',
  'PageUp',
  'PageDown',
]

const isPageForward = isHotkey('mod+ArrowRight')
const isPageBack = isHotkey('mod+ArrowLeft')
interface IProps {
  transcript: ITranscript & { paragraphsForTranscript: string }
  transcriptId: string
  firestore: firebase.firestore.Firestore
  updateStatus: (status: ProgressType) => void
  checkRole?: (role: string) => boolean
  userData?: IUser[]
  // SetCurrentTimeState:
  // videoPlayCurrentTime:
}

interface ISpeakerOptionType {
  inputValue?: string
  title: string
}

const customStyles = makeStyles({
  root: {
    paddingRight: '9px !important',
    '& :focus': {
      boxShadow: 'none !important',
    },
  },
  inputStyles: {
    paddingLeft: '0px !important',
    paddingTop: '3px !important',
    paddingBottom: '3px !important',
    borderRadius: '8px !important',
    // fontSize: '14px',
    fontWeight: '400',
  },
  labelStyles: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(5px, 14px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiInputLabel-animated': {
      transition: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      transition: 'none',
    },
  },
  timecodeText: {
    width: 50,
    marginRight: 10,
  },
})
const editorStyle = { boxShadow: 'none' }

interface LeafProps {
  attributes: any
  // attributes: {
  //   'data-slate-leaf': true
  // } // Adjust the type if possible
  children: ReactNode
  leaf: {
    metaData: {
      startTime: number
      endTime: number
    }
    bold?: boolean
    highlight?: 'yellow' | 'blue' | 'violet' | 'pink' | 'orange'
    strike?: boolean
  }
}

function TranscriptionEditor(props: IProps) {
  const [value, setValue] = useState<Node[] | null>(null)
  const [playerTime, setPlayerTime] = useState<null | number>()
  const [editStatus, setEditStatus] = useState<boolean>(false)
  const [metaModal, setMetaModal] = useState(false)
  const [metaData, setMetaData] = useState<{
    timecode?: string
    framesPerSecond?: number
  }>({ timecode: '00:00:00:00', framesPerSecond: 0 })
  const [needUpdate, setNeedUpdate] = useState(false)
  const [timeCodePreferrence, setTimeCodePreferrence] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const [pages, setPages] = useState<{
    [number]: { paragraphIds: string[]; elements: Node[] }
  }>()
  const [mediaPlaying, setMediaPlaying] = useState<boolean>(false)
  const [pageLimitReached, setPageLimitReached] = useState<boolean>(false)
  const [maxTimepage, setmaxTimepage] = useState()
  const [markSelect, setMarkSelect] = useState<string>('yellow')
  const [UpgradeOpen, setUpgradeOpen] = useState(false)
  const [paragraphToFetch, setParagraphToFetch] = useState('paragraphs')
  const [hasRun, setHasRun] = useState(false)
  const [open, setOpen] = useState(false)
  const wordsLimit = 300
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])
  const { transcriptId } = props
  const { userStatus, userData, user } = useAppSelector((store) => store.user)
  const { currentTime, translationLanguages } = useAppSelector(
    (store) => store.getTranscript,
  )
  const { currentAccount } = useAppSelector((store) => store.account)
  const { currentProject, roles } = useAppSelector((store) => store.project)
  const transcript = useAppSelector((store) => store.paragraphsSlice)
  const { sidebarSpeakerEdited } = useAppSelector(
    (store) => store.transcriptSlice,
  )
  const { metadata } = transcript

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const audioRef = useRef<HTMLAudioElement | null>()
  const videoRef = useRef<HTMLVideoElement | null>()
  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} state={value} />,
    [value, metadata, timeCodePreferrence],
  )
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [currentTime])
  const classes = customStyles()
  const userFeatures = (userData && userData.features) || {}
  const accountId = currentAccount.id

  const handleOpen = () => {
    customEvent('transcript_translate_modal_opened', {
      transcriptId: transcript.id,
      user_userId: userData.id,
      accountId: accountId,
      projectId: transcript.projectId,
    })
    setOpen(!open)
  }

  const populateState = () => {
    // const blocks = []
    const pages: { [number]: { paragraphIds: string[]; elements: Node[] } } = {}
    let index = 0,
      counter = 0
    const { paragraphs, metadata } = { ...transcript }
    const timecode =
      metadata.timecode && `${metadata.timecode}`.split(':').length === 4
        ? metadata.timecode
        : '00:00:00:00'

    // Dummy paragraph object
    const paragraphsArray = Array.from(paragraphs)

    if (
      paragraphsArray.length > 0 &&
      transcript.previewParagraphsForFreeUsers &&
      transcript.previewParagraphsForFreeUserRemainingDuration > 0
    ) {
      // Calculate the duration of each dummy paragraph
      const durationPerParagraph = 1.5 * 60 // 1.5 minutes in seconds

      // Calculate the number of dummy paragraphs needed based on remaining time
      const remainingDurationInSeconds =
        transcript.previewParagraphsForFreeUserRemainingDuration
      const numberOfDummyParagraphs = Math.floor(
        remainingDurationInSeconds / durationPerParagraph,
      )

      for (let index = 0; index < numberOfDummyParagraphs; index++) {
        const lastParagraph = paragraphsArray[paragraphsArray.length - 1]
        const startTimeInSeconds = lastParagraph.startTime / 1e9

        const startTimeWithOffsetInSeconds =
          startTimeInSeconds + durationPerParagraph

        // Convert back to nanoseconds
        const startTimeWithOffset = startTimeWithOffsetInSeconds * 1e9
        const endTimeWithOffset = startTimeWithOffset + 10 * 1e9 // 10 seconds duration

        const dummyParagraph = {
          id: 'dummy', // Provide a unique ID
          isverified: '',
          VerifiedBy: '',
          previewParagraphsForFreeUsers: true,
          startTime: startTimeWithOffset, // Start time
          words: [
            // Word data array
            {
              endTime: endTimeWithOffset,
              text: 'This is a dummy placeholder. This is a dummy placeholder. This is a dummy placeholder. This is a dummy placeholder. This is a dummy placeholder. This is a dummy placeholder. ',
              confidence: 0.9,
              startTime: startTimeWithOffset,
            },
            // Add more words as needed
          ],
        }

        paragraphsArray.push(dummyParagraph)
      }
    }
    if (paragraphsArray) {
      // console.log(paragraphs, 'paragraphs')
      for (const [i, paragraph] of paragraphsArray.entries()) {
        const { speaker, startTime } = paragraph
        // console.log('startTimestartTime', startTime)
        // const seconds = paragraphs[i + 1]?.startTime / 1e9 // 1e9 represents 1 billion
        // console.log('seconds', seconds, speaker)
        const formattedStartTime = metadata
          ? nanoSecondsToFormattedTime(timecode, startTime, true, false)
          : '00:00:00'
        const para: Node = {
          id: paragraph.id,
          isverified:
            paragraph.isverified === undefined ? '' : paragraph.isverified,
          VerifiedBy:
            paragraph.isverified === undefined ? '' : paragraph.isverified,
          type: 'paragraph',
          children: [],
          speaker,
          startTime,
          formattedStartTime,
          order: i,
          confidentialParagraph:
            paragraph.previewParagraphsForFreeUsers || false,
        }

        for (const [k, word] of paragraph.words.entries()) {
          const text = word.updatedText || word.text || ''
          const child: Node = {
            text:
              text.length > 0 && text[text.length - 1] !== ' '
                ? `${text} `
                : text,
            metaData: {
              ...word,
            },
            focus: {
              paragraph: i,
              word: k,
              paraid: paragraph.id,
            },
          }
          if (word.deleted) {
            child.strike = true
          }

          if (word.highlighted) {
            child.highlight = word.highlighted
          }
          para.children.push(child)
        }
        if (counter === 0) {
          counter += para.children.length
          pages[index] = { elements: [para], paragraphIds: [] }
        } else {
          counter += para.children.length
          if (counter < wordsLimit) {
            if (pages[index] && pages[index].elements) {
              pages[index].elements = [...pages[index].elements, para]
            } else {
              pages[index] = { elements: [para], paragraphIds: [] }
            }
          } else {
            counter =
              para.children.length < wordsLimit ? para.children.length : 0
            index++
            pages[index] = { elements: [para], paragraphIds: [] }
          }
        }
        pages[index].paragraphIds.push(paragraph.id)
        // blocks.push(para)
      }
      setPages(pages)
      // console.log(pages,'element');
      setValue(pages[pageIndex]?.elements)
    }

    if (metadata) {
      setMetaData({ timecode, framesPerSecond: metadata.framesPerSecond })
    }
    // setEditorState(state)
  }

  const updateDb = async (updateMetaData?: boolean, state?: Node[]) => {
    const transcriptDocumentReference = database.doc(
      `transcripts/${transcriptId}/`,
    )
    const paragraphsCollectionReference =
      transcriptDocumentReference.collection(paragraphToFetch)
    // const { paragraphs } = transcript
    const newParagraphs = []
    const deletedParagraphs = []
    const currentState = state || value
    const stateParagraphIds = []
    if (currentState && pages && editStatus) {
      // preparing update payload
      for (const paragraph of currentState) {
        const newParagraph = { ...paragraph }
        delete newParagraph.type
        delete newParagraph.children
        delete newParagraph.formattedStartTime
        delete newParagraph.order
        if (newParagraph.speaker === undefined) {
          delete newParagraph.speaker
        }
        if (!updateMetaData) {
          newParagraph.words = []
          // @ts-ignore
          for (const word of paragraph.children) {
            const originalText = word.metaData.text
            const newText = word.text
            const newWord = { ...word.metaData }
            if (newWord.updatedText !== undefined) {
              newWord.updatedText = newText
            } else if (
              originalText === newText.slice(0, newText.length - 1) ||
              originalText === newText
            ) {
              newWord.text = newText // not necessary
            } else {
              newWord.updatedText = newText
            }

            if (word.strike) {
              newWord.deleted = true
            }
            if (word.highlight) {
              newWord.highlighted = word.highlight
            }
            // @ts-ignore
            newParagraph.words.push(newWord)
          }
        }
        if (newParagraph.id !== 'dummy') {
          newParagraphs.push(newParagraph)
          stateParagraphIds.push(newParagraph.id)
        }
      }
      // To be deleted paragraphs
      for (const oldId of pages[pageIndex].paragraphIds) {
        if (stateParagraphIds.indexOf(oldId) === -1) {
          deletedParagraphs.push(oldId)
        }
      }
      setPages({
        ...pages,
        [pageIndex]: { ...pages[pageIndex], paragraphIds: stateParagraphIds },
      })
      // for (const paragraph of paragraphs) {
      //   const paraInEditor = currentState.filter((para) => para.id === paragraph.id)
      //   if (paraInEditor.length === 0) {
      //     deletedParagraphs.push(paragraph.id)
      //   }
      // }

      const batch = database.batch()
      if (!updateMetaData) {
        for (const paragraph of newParagraphs) {
          batch.set(paragraphsCollectionReference.doc(paragraph.id), paragraph) //{ merge }
          fetchParagraphs(transcriptId)
        }

        for (const paragraphId of deletedParagraphs) {
          batch.delete(paragraphsCollectionReference.doc(paragraphId))
        }
      } else if (updateMetaData) {
        for (const paragraph of newParagraphs) {
          if (paragraph.speaker) {
            batch.update(paragraphsCollectionReference.doc(paragraph.id), {
              speaker: paragraph.speaker,
            })
          } else {
            batch.update(paragraphsCollectionReference.doc(paragraph.id), {
              speaker: '',
            })
          }
          batch.update(paragraphsCollectionReference.doc(paragraph.id), {
            isverified: paragraph.isverified,
          })
          if (paragraph.VerifiedBy) {
            batch.update(paragraphsCollectionReference.doc(paragraph.id), {
              VerifiedBy: paragraph.VerifiedBy,
            })
          } else {
            batch.update(paragraphsCollectionReference.doc(paragraph.id), {
              VerifiedBy: '',
            })
          }
        }
        if (transcript.speakerNames) {
          batch.update(transcriptDocumentReference, {
            speakerNames: transcript.speakerNames,
          })
        }
        setNeedUpdate(false)
      }

      // if (transcript.status && transcript.status.progress !== ProgressType.Edited) {

      updateUserData(transcriptId, { status: ProgressType.Edited })
      // props.updateStatus(ProgressType.Edited)
      const lastUpdated = firebase.firestore.Timestamp.fromDate(new Date())
      batch.update(transcriptDocumentReference, {
        status: { progress: ProgressType.Edited, lastUpdated },
      })

      // }
      try {
        setEditStatus(false)
        await batch.commit()
        customEvent('editor_words_changed', {
          category: 'editor',
          transcriptId: transcript.id,
          user_userId: userData.id,
          actionMetadata: 'character-added',
          accountId: accountId,
        })
        dispatch(setloading(false))
        fetchParagraphs(transcriptId)
      } catch (error) {
        console.log('Error saving to Firebase: ', error)
        // ReactGA.exception({
        //   description: error.message,
        //   fatal: false,
        // })
        ReactGA.gtag('event', 'updateDb_exception', {
          description: error.message,
          fatal: false,
        })
      }
    }
  }

  const fetchParagraphs = async (
    transcriptId: string,
    collectionName?: string,
  ) => {
    try {
      // const querySnapshot = await firestore.get({ collection: `transcripts/${transcriptId}/paragraphs`, orderBy: "startTime" })
      const collectionRef = database.collection(
        `transcripts/${transcriptId}/${
          collectionName ? collectionName : paragraphToFetch
        }`,
      )

      const querySnapshot = await collectionRef.orderBy('startTime').get()

      // Using map to directly return the updated paragraphs array
      const paragraphs = querySnapshot.docs.map((doc) => {
        const paragraph = doc.data()
        const id = doc.id
        return { id, ...paragraph }
      })
      dispatch(readParagraph({ paragraphs, transcriptId }))
      setHasRun(false)
    } catch (error) {
      console.log('Error fetching paragraphs: ', error)
      // ReactGA.exception({
      //   description: error.message,
      //   fatal: false,
      // })
      ReactGA.gtag('event', 'fetching_paragraphs_exception', {
        description: error.message,
        fatal: false,
      })
    }
  }
  const handleUpgrade = (): void => {
    if (!UpgradeOpen) {
      customEvent('billing_upgrade_modal_opened', {
        category: 'accounts',
        accountId: currentAccount.id,
        user_userId: userData.id,
        actionSource: 'transcript-blurred-paragraph',
      })
    }
    setUpgradeOpen(!UpgradeOpen)
  }
  const Element = ({
    attributes,
    children,
    element,
    state,
  }: RenderElementProps & { state?: Node[] }) => {
    const customClass = 'text-base font-normal text-lstnBlueGray-500'
    const updatedClassName = `${attributes.className} ${customClass}`.trim()
    const updatedAttributes = { ...attributes, className: updatedClassName }
    const handleParaClick = () => {
      if (element.order === 0) {
        // toggleMetaModal(true)
      }
    }
    const getElementIndex = () => state.map((e) => e.id).indexOf(element.id)
    const handleOnChange = (_, newValue) => {
      dispatch(setloading(true))
      const index = getElementIndex()
      if (typeof newValue === 'string') {
        handleChangeSpeakerName(index, newValue)
      } else if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        handleAddSpeakerClick(index, newValue.inputValue)
      } else if (!newValue) {
        handleRemoveParagraphSpeaker(index)
      } else if (newValue && newValue.title) {
        handleChangeSpeakerName(index, newValue.title)
      }
    }
    const getFilteredOptions = (options, params) => {
      const filter = createFilterOptions<ISpeakerOptionType>()
      const filtered = filter(options, params)

      // Suggest the creation of a new value
      if (params.inputValue !== '') {
        filtered.push({
          inputValue: params.inputValue,
          title: `Add "${params.inputValue}"`,
        })
      }

      return filtered
    }
    const getOptionLabels = (option) => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue
      }
      // Regular option
      return option.title
    }
    const getRenderOptions = (option) => option.title

    if (state && state.length > 0) {
      switch (element.type) {
        case 'paragraph': {
          const index = getElementIndex()
          const filtersOptions = []
          let currentVal
          if (transcript.speakerNames) {
            for (const speaker in transcript.speakerNames) {
              filtersOptions.push({ title: transcript.speakerNames[speaker] })
            }
          }
          // if (element.speaker && transcript.speakerNames) {
          //   currentVal = transcript.speakerNames[element.speaker]
          // }
          if (element.speaker) {
            currentVal = element.speaker
          }
          // const handleVerification = (event: React.ChangeEvent<HTMLInputElement>) => {
          //   const index = getElementIndex()
          //   handlAddverifystatus(index, event.target.checked)
          // }

          return (
            <div className="transcript-layout-inner">
              <div className="speaker-btn-grid" contentEditable={false}>
                <MuiThemeProvider theme={lstnBasictheme}>
                  <Autocomplete
                    value={currentVal}
                    classes={{
                      inputRoot: classes.root,
                      input: classes.inputStyles,
                    }}
                    onChange={handleOnChange}
                    filterOptions={getFilteredOptions}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disabled={
                      transcript.projectId === ''
                        ? !props.checkRole(roleType.EDIT) ||
                          !props.checkRole(roleType.CHANGESPEAKER)
                        : !verifyProjectRole('transcript')?.includes(
                            roleType.CHANGESPEAKER,
                          )
                    }
                    id={`speaker-autofill-${index}`}
                    options={filtersOptions}
                    getOptionLabel={getOptionLabels}
                    renderOption={getRenderOptions}
                    style={{ width: '100%' }}
                    freeSolo
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Speaker Name"
                          inputProps={{
                            ...params.inputProps,
                            value: currentVal,
                          }}
                          className="speaker-input"
                          variant="outlined"
                          classes={{ root: classes.labelStyles }}
                        />
                      )
                    }}
                  />
                </MuiThemeProvider>
              </div>
              <div>
                <div style={{ userSelect: 'none' }} contentEditable={false}>
                  {timeCodePreferrence && (
                    <div
                      className="flex items-end gap-2"
                      style={{ userSelect: 'none' }}
                    >
                      <Typography
                        className="text-xs font-normal text-blue-gray-400 mb-0 metaTimecode"
                        style={{ userSelect: 'none' }}
                        contentEditable={false}
                        onClick={handleParaClick}
                      >
                        {nanoSecondsToFormattedTime(
                          metaData.timecode,
                          element.startTime,
                          true,
                          false,
                        )}
                      </Typography>
                      {element.confidentialParagraph && (
                        <span className="group flex">
                          <img
                            src={lockIcon}
                            alt="img"
                            className="w-[14px] h-[13px] cursor-pointer mb-[1.5px]"
                          />
                          <span className="text-xs font-normal text-blue-gray-400 hidden group-hover:block ml-2 duration-500 ease-in">
                            Locked content.{' '}
                            <span
                              className="text-lstnGreen-500 cursor-pointer"
                              onClick={handleUpgrade}
                            >
                              Upgrade
                            </span>{' '}
                            to unlock.
                          </span>
                        </span>
                      )}
                      {/* <Tooltip content={element.isverified ? 'Unverify paragraph' : 'Verify paragraph'} className="bg-lstnBlueGray-200 text-xs font-normal text-black">
                        <div className="h-[19px] verifyParagraph" style={{ userSelect: 'none' }}>
                          <Checkbox
                            label={element.isverified === '' ? '' : element.isverified ? `Verified by ${element.VerifiedBy}` : `Edited by ${element.VerifiedBy} since being marked as verified`}
                            name="verification"
                            icon={element.isverified ? undefined : <img src={UnverifyIcon} alt="img" />}
                            color="green"
                            uncheckedColor={true}
                            checked={element.isverified}
                            onChange={handleVerification}
                            style={{ userSelect: 'none' }}
                          />
                        </div>
                      </Tooltip> */}
                    </div>
                  )}

                  {!timeCodePreferrence && (
                    <div
                      className="flex items-end gap-2 "
                      style={{ userSelect: 'none' }}
                    >
                      <Typography
                        className="text-xs font-normal text-blue-gray-400 mb-0 metaTimecode"
                        style={{ userSelect: 'none' }}
                        contentEditable={false}
                        onClick={handleParaClick}
                      >
                        {nanoSecondsToFormattedTime(
                          '00:00:00:00',
                          element.startTime,
                          true,
                          false,
                        )}
                      </Typography>
                      {element.confidentialParagraph && (
                        <span className="group flex">
                          <img
                            src={lockIcon}
                            alt="img"
                            className="w-[14px] h-[13px] cursor-pointer mb-[1.5px]"
                          />
                          <span className="text-xs font-normal text-blue-gray-400 hidden group-hover:block ml-2 duration-500 ease-in">
                            Locked content.{' '}
                            <span
                              className="text-lstnGreen-500 cursor-pointer"
                              onClick={handleUpgrade}
                            >
                              Upgrade
                            </span>{' '}
                            to unlock.
                          </span>
                        </span>
                      )}
                      {/* <Tooltip content={element.isverified ? 'Unverify paragraph' : 'Verify paragraph'} className="bg-lstnBlueGray-200 text-xs font-normal text-black">
                        <div className="h-[19px] verifyParagraph" style={{ userSelect: 'none' }}>
                          <Checkbox
                            label={element.isverified === '' ? '' : element.isverified ? `Verified by ${element.VerifiedBy}` : `Edited by ${element.VerifiedBy} since being marked as verified`}
                            name="verification"
                            icon={element.isverified ? undefined : <img src={UnverifyIcon} alt="img" />}
                            color="green"
                            uncheckedColor={true}
                            checked={element.isverified}
                            onChange={handleVerification}
                            style={{ userSelect: 'none' }}
                          />
                        </div>
                      </Tooltip> */}
                    </div>
                  )}
                </div>
                <div
                  className={`paragraph lstn-transcription-text ${
                    element.confidentialParagraph ? 'blur' : ''
                  }`}
                >
                  <Typography {...updatedAttributes}>{children}</Typography>
                </div>
              </div>
            </div>
          )
        }
        default:
          return <Typography {...updatedAttributes}>{children}</Typography>
      }
    }
    return <p></p>
  }
  // let Leafchildren = null

  const Leaf = ({ attributes, children, leaf }: LeafProps) => {
    // const currentTime = currentTime
    let className = ''
    if (leaf.metaData.startTime * 1e-9 <= currentTime) {
      className = 'playing-word'
    } else if (leaf.metaData.endTime * 1e-9 <= currentTime) {
      className = 'played-words'
    }

    if (leaf.bold) {
      children = <strong>{children}</strong>
    }
    if (leaf.highlight) {
      if (leaf.highlight == 'yellow') {
        children = (
          <span className={`word-highlight-yellow ${className}`}>
            {children}
          </span>
        )
      } else if (leaf.highlight == 'blue') {
        children = (
          <span className={`word-highlight-blue ${className}`}>{children}</span>
        )
      } else if (leaf.highlight == 'violet') {
        children = (
          <span className={`word-highlight-violet ${className}`}>
            {children}
          </span>
        )
      } else if (leaf.highlight == 'pink') {
        children = (
          <span className={`word-highlight-pink ${className}`}>{children}</span>
        )
      } else if (leaf.highlight == 'orange') {
        children = (
          <span className={`word-highlight-orange ${className}`}>
            {children}
          </span>
        )
      }
    }

    if (leaf.strike) {
      children = <s>{children}</s>
    }
    const handleClickLeaf = () => {
      if (audioRef.current.isPlaying) {
        try {
          if (audioRef.current) {
            audioRef.current.pause()
          }
          audioRef.current.setTime(leaf.metaData.startTime * 1e-9)
          // props.SetCurrentTimeState(leaf.metaData.startTime * 1e-9)
          customEvent('editor_word_selected', {
            category: 'editor',
            transcriptId: transcript.id,
            user_userId: userData.id,
            accountId: accountId,
          })
          setTimeout(() => {
            Transforms.deselect(editor)
          }, 100)
          setTimeout(() => {
            if (
              audioRef.current &&
              audioRef.current.play &&
              !audioRef.current.isPlaying
            ) {
              audioRef.current.play()
            }
          }, 100)
        } catch (error) {
          console.log('An error handleClickLeaf:', error)
        }
      }
    }
    return (
      <span {...attributes} className={className} onClick={handleClickLeaf}>
        {children}
      </span>
    )
  }

  const togglePlay = (val: boolean) => {
    if (val) {
      setMediaPlaying(true)
    } else {
      setMediaPlaying(false)
    }
    if (videoRef.current) {
      videoRef.current.togglePlay()
    }
  }
  useEffect(() => {
    if (value) {
      const maxEndTimePerpage =
        value[value.length - 1].children[
          value[value.length - 1].children.length - 1
        ].metaData.endTime
      setmaxTimepage(maxEndTimePerpage)
      if (
        maxEndTimePerpage * 1e-9 < currentTime &&
        maxTimepage * 1e-9 < currentTime
      ) {
        setPageLimitReached(true)
      }
    }
  }, [currentTime])

  useEffect(() => {
    if (sidebarSpeakerEdited?.isEdit && sidebarSpeakerEdited?.speakerName) {
      dispatch(setloading(false))
      handleBulkChangeSpeakerName(
        sidebarSpeakerEdited?.indexesParagraph,
        sidebarSpeakerEdited?.speakerName,
      )
    }
  }, [sidebarSpeakerEdited])

  // let pageCount = true
  // const recomputeWords = (time?: number, state?: Node[], returnState?: boolean) => {
  //   const currentTime = time !== undefined ? time : playerTime
  //   // console.log(currentTime, "recom")
  //   if (value && currentTime !== undefined) {
  //     const currentState = state || createDraft(value)

  //     // console.log(currentState.entries(), "currentState")
  //     for (const para of currentState) {
  //       for (const word of para.children) {
  //         word.playStatus = ""
  //       }
  //     }

  //     for (const [paraIndex, para] of currentState.entries()) {
  //       for (const [i, word] of para.children.entries()) {
  //         if (word.metaData.endTime * 1e-9 <= currentTime) {
  //           word.playStatus = "played"

  //           if (paraIndex === currentState.length - 1 && i === para.children.length - 1 && pageCount) {
  //             pageCount = false
  //             setPageLimitReached(true)

  //             if (audioRef.current) {
  //               if (!pageCount && paraIndex === currentState.length - 1) {
  //                 audioRef.current.pause()
  //               }
  //               if (!pageCount && paraIndex === currentState.length - 1) {
  //                 if (audioRef.current && audioRef.current.play) {
  //                   audioRef.current.play()
  //                 } else {
  //                   console.error("Audio element not found or is null.")
  //                 }
  //               }
  //             }
  //           }
  //         } else if (word.metaData.startTime * 1e-9 >= currentTime) {
  //           if (i !== 0) {
  //             para.children[i - 1].playStatus = "played"
  //           }
  //           word.playStatus = "playing"
  //           if (paraIndex === currentState.length - 1 && i === para.children.length - 1 && pageCount) {
  //             pageCount = false
  //             setPageLimitReached(true)

  //             if (audioRef.current) {
  //               if (!pageCount && paraIndex === currentState.length - 1) {
  //                 audioRef.current.pause()
  //               }
  //               if (!pageCount && paraIndex === currentState.length - 1) {
  //                 if (audioRef.current && audioRef.current.play) {
  //                   audioRef.current.play()
  //                 } else {
  //                   console.error("Audio element not found or is null.")
  //                 }
  //               }
  //             }
  //           }

  //           if (!returnState) {
  //             setValue(finishDraft(currentState))
  //             return
  //           } else {
  //             return currentState
  //           }
  //         }
  //       }
  //     }

  //     if (!returnState) {
  //       setValue(finishDraft(currentState))
  //     } else {
  //       return currentState
  //     }
  //   } else if (state) {
  //     return state
  //   }
  // }

  const setPlayingData = (currentState: Node[]) => {
    const focus = editor && editor.selection ? editor.selection.focus : null
    if (focus) {
      const paraIndex = focus.path[0]
      const wordIndex = focus.path[1]
      const word = currentState[paraIndex].children[wordIndex]
      // const playingWord = { paragraph: focus.path[0], word: focus.path[1], startTime: word.metaData.startTime }
      if (
        audioRef.current &&
        word.metaData &&
        word.metaData.startTime !== undefined
      ) {
        // props.SetCurrentTimeState(word.metaData.startTime * 1e-9)
        audioRef.current.setTime(word.metaData.startTime * 1e-9)
        customEvent('editor_word_selected', {
          category: 'editor',
          transcriptId: transcript.id,
          user_userId: userData.id,
          accountId: accountId,
        })
      }
      // if (videoRef.current && word.metaData && word.metaData.startTime !== undefined) {
      //   videoRef.current.setTime(word.metaData.startTime * 1e-9)
      // }
      // setPlayingWord(playingWord)
      if (word.metaData.startTime * 1e-9 !== playerTime) {
        setPlayerTime(word.metaData.startTime * 1e-9)
        // return recomputeWords(word.metaData.startTime * 1e-9, currentState, true)
      }
      return currentState
    }
    return currentState
  }

  const handleEditorChange = (value: Node[]) => {
    const ops = (editor.operations || []).filter((o) => {
      return (
        o &&
        (o.type !== 'set_selection' ||
          (o.newProperties &&
            o.newProperties.focus &&
            o.newProperties.focus.offset))
      )
    })
    if (Array.isArray(ops) && ops.length) {
      const initialState = createDraft(value)
      const state = setPlayingData(initialState)
      setValue(finishDraft(state))
      updateParagraphsData(value)
    }
    if (mediaPlaying) {
      Transforms.deselect(editor)
    }
  }
  async function updateUserData(id: string, data: ITranscript) {
    try {
      const userRef = database.collection(`transcripts`).doc(id)
      await userRef.update({ ...data })
      // console.log('&&&&',userRef);
    } catch (error) {
      console.error('error', error)
    }
  }
  const handleSpace = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { focus } = editor.selection
    // split word logic
    if (
      focus &&
      focus.offset > 0 &&
      focus.offset <
        editor.children[focus.path[0]].children[focus.path[1]].text.length - 1
    ) {
      event.preventDefault()
      const { path, offset } = focus
      const prevNode = editor.children[path[0]].children[path[1]]
      const text = prevNode.text
      const prevMeta = prevNode.metaData
      const prevText = `${text.slice(0, offset)} `
      const nextText = text.slice(offset)

      const timeInNano = (prevMeta.startTime - prevMeta.endTime) / text.length
      const dividedTime = prevMeta.startTime + timeInNano * prevText.length
      Transforms.setNodes(
        editor,
        { metaData: { startTime: prevMeta.startTime, endTime: dividedTime } },
        { at: path },
      )
      Transforms.insertText(editor, prevText, { at: path })
      Transforms.insertNodes(
        editor,
        {
          text: nextText,
          metaData: { startTime: dividedTime, endTime: prevMeta.endTime },
        },
        { at: [path[0], path[1] + 1] },
      )
    }
    return true
  }

  const handleBackspace = (event: React.KeyboardEvent<HTMLDivElement>) => {
    customEvent('editor_words_changed', {
      category: 'editor',
      transcriptId: transcript.id,
      user_userId: userData.id,
      actionMetadata: 'character-removed',
      accountId: accountId,
    })
    const { focus } = editor.selection
    // merge words logic
    if (focus && focus.offset === 0 && focus.path[1] > 0) {
      event.preventDefault()
      const { path } = focus
      const currentNode = editor.children[path[0]].children[path[1]]
      // const nextMeta = currentNode.metaData
      const prevNode = editor.children[path[0]].children[path[1] - 1]

      const newText =
        prevNode.text[prevNode.text.length - 1] === ' '
          ? `${prevNode.text.substring(0, prevNode.text.length - 1)}${
              currentNode.text
            }`
          : `${prevNode.text}${currentNode.text}`

      // console.log("Backspace", focus, editor.children[path[0]].children[path[1]])
      Transforms.setNodes(
        editor,
        {
          metaData: {
            startTime: prevNode.metaData.startTime,
            endTime: currentNode.metaData.endTime,
          },
        },
        { at: [path[0], path[1] - 1] },
      )
      Transforms.insertText(editor, newText, { at: [path[0], path[1] - 1] })
      Transforms.removeNodes(editor, { at: path })
      Transforms.setSelection(editor, {
        focus: {
          path: [path[0], path[1] - 1],
          offset: prevNode.text.length - 1,
        },
        anchor: {
          path: [path[0], path[1] - 1],
          offset: prevNode.text.length - 1,
        },
      })
    } else if (
      focus &&
      focus.offset > 0 &&
      focus.path[1] > 0 &&
      focus.path[1] < editor.children[focus.path[0]].children.length - 1 &&
      focus.offset ===
        editor.children[focus.path[0]].children[focus.path[1]].text.length
    ) {
      event.preventDefault()
      const { path } = focus
      const currentNode = editor.children[path[0]].children[path[1]]
      // const nextMeta = currentNode.metaData
      const nextNode = editor.children[path[0]].children[path[1] + 1]

      const newText =
        currentNode.text[currentNode.text.length - 1] === ' '
          ? `${currentNode.text.substring(0, currentNode.text.length - 1)}${
              nextNode.text
            }`
          : `${currentNode.text}${nextNode.text}`

      // console.log("Backspace", focus, editor.children[path[0]].children[path[1]], currentNode.text[currentNode.text.length - 1] === " ")
      Transforms.setNodes(
        editor,
        {
          metaData: {
            startTime: currentNode.metaData.startTime,
            endTime: nextNode.metaData.endTime,
          },
        },
        { at: path },
      )
      Transforms.insertText(editor, newText, { at: path })
      Transforms.removeNodes(editor, { at: [path[0], path[1] + 1] })
      Transforms.setSelection(editor, {
        focus: {
          path: [path[0], path[1]],
          offset: currentNode.text.length - 1,
        },
        anchor: {
          path: [path[0], path[1]],
          offset: currentNode.text.length - 1,
        },
      })
    }
    return true
  }

  const isMarkActive = (editor: Editor, format: string) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  }
  const isMarkActiveColor = (editor: Editor, format: string, color: string) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] == color : false
  }

  const toggleMark = (editor: Editor, format: string, color?: string) => {
    if (format == 'highlight') {
      const isActive = isMarkActiveColor(editor, format, color)
      if (isActive) {
        Editor.removeMark(editor, format, color)
      } else {
        Editor.addMark(editor, format, color)
      }
    } else {
      const isActive = isMarkActive(editor, format)
      if (isActive) {
        Editor.removeMark(editor, format)
      } else {
        Editor.addMark(editor, format, true)
      }
    }
    setEditStatus(true)
  }
  const handleMarkClick = (event) => {
    event.preventDefault()
    toggleMark(editor, 'highlight', markSelect)
    customEvent('editor_words_highlighted', {
      category: 'editor',
      transcriptId: transcript.id,
      user_userId: userData.id,
      actionMetadata: markSelect, // we will change this when we add more highlight colors
      accountId: accountId,
    })
  }
  const handleStrikeClick = (event) => {
    event.preventDefault()
    toggleMark(editor, 'strike')
    // ReactGA.event({
    //   action: "words deleted",
    //   category: "editor",
    // })
    customEvent('editor_words_strikethrough', {
      category: 'editor',
      transcriptId: transcript.id,
      user_userId: userData.id,
      accountId: accountId,
    })
  }

  const doRedoOrUndo = (operation: 'redo' | 'undo') => {
    setEditStatus(true)
    switch (operation) {
      case 'undo': {
        // ReactGA.event({
        //   action: "undo",
        //   category: "editor",
        // })
        customEvent('editor_action_undo', {
          category: 'editor',
          transcriptId: transcript.id,
          user_userId: userData.id,
          accountId: accountId,
        })
        return editor.undo()
      }
      case 'redo': {
        // ReactGA.event({
        //   action: "redo",
        //   category: "editor",
        // })
        customEvent('editor_action_redo', {
          category: 'editor',
          transcriptId: transcript.id,
          user_userId: userData.id,
          accountId: accountId,
        })
        return editor.redo()
      }
      default:
        return
    }
  }
  const doRedo = () => {
    doRedoOrUndo('redo')
  }
  const doUndo = () => {
    doRedoOrUndo('undo')
  }

  const handleTimeToggle = (status?: boolean) => {
    const transcriptDocumentReference = database.doc(
      `transcripts/${transcriptId}/`,
    )
    if (status) {
      setTimeCodePreferrence(status)
      transcriptDocumentReference.update({
        timeCodePreference: status,
      })
    } else {
      transcriptDocumentReference.update({
        timeCodePreference: !timeCodePreferrence,
      })
      setTimeCodePreferrence(!timeCodePreferrence)
    }
  }
  /* set MetaData */
  const toggleMetaModal = (val: boolean) => {
    setMetaModal(val)
  }
  const openMetaModal = () => {
    setMetaModal(!metaModal)
  }

  const handleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.checkRole(roleType.EDIT)) {
      const prevData = { ...metaData }
      // console.log('&&&&&&&&&', event.target.name, event.target.value)
      if (event.target.name.startsWith('timecode')) {
        const { timecode } = prevData
        const timecodeArr = timecode
          ? timecode.split(':')
          : ['00', '00', '00', '00']
        if (event.target.value === '' || !isNaN(parseInt(event.target.value))) {
          const val = event.target.value !== '' ? event.target.value : '00'
          if (event.target.name === 'timecodeHr') {
            timecodeArr[0] = val.slice(-2)
          } else if (event.target.name === 'timecodeMin') {
            timecodeArr[1] = val.slice(-2)
          } else if (event.target.name === 'timecodeSec') {
            timecodeArr[2] = val.slice(-2)
          } else if (event.target.name === 'timecodeMs') {
            timecodeArr[3] = val.slice(-2)
          }
        }
        prevData.timecode = timecodeArr.join(':')
        // ReactGA.event({
        //   action: "timecode changed",
        //   category: "editor",
        // })
        customEvent('editor_timecode_changed', {
          category: 'editor',
          transcriptId: transcript.id,
          user_userId: userData.id,
          accountId: accountId,
        })
      }
      // else if (event.target.name === "fps") {
      //   prevData.framesPerSecond = event.target.value
      //   // ReactGA.event({
      //   //   action: "fps changed",
      //   //   category: "editor",
      //   // })
      //   customEvent("editor_fps_changed", {
      //     category: "editor",
      //     transcriptId: props.transcript.id,
      //     user_userId: props.userData[0].id,
      //   })
      // }
      setMetaData(prevData)
      handleTimeToggle(true)
      submitData()
    }
  }

  const submitData = async (event?: React.FormEvent<HTMLFormElement>) => {
    handleTimeToggle(true)
    if (event) {
      event.preventDefault()
    }
    const transcriptDocumentReference = database.doc(
      `transcripts/${transcriptId}/`,
    )
    const batch = database.batch()
    const metadata = {
      ...transcript.metadata,
      timecode: metaData.timecode || '00:00:00:00',
      framesPerSecond: metaData.framesPerSecond || 0,
    }
    batch.update(transcriptDocumentReference, { metadata })
    try {
      await batch.commit()
      //We used to update the state first to achieve real-time processing initially, but now we are directly using real-time data
      // props.updateTimecode(metadata.timecode)
      // props.updateFramesPerSecond(metadata.framesPerSecond)
      if (event) {
        toggleMetaModal(false)
      }
    } catch (err) {
      console.error('Error updating MetaData:', err)
    }
  }

  /* Speakers Related */
  const handleAddSpeakerClick = async (index: number, speakerName: string) => {
    // console.log("%%%% add:", transcript)
    if (speakerName) {
      const key = speakerName.replace(' ', '')
      if (value) {
        const state = createDraft(value)
        state[index].speaker = key
        const newState = finishDraft(state)
        UpdateSpeakerName(speakerName, transcriptId)
        // props.updateSpeakerName(key, speakerName, index)
        setValue(newState)
        updateParagraphsData(newState)
        setNeedUpdate(true)
        fetchParagraphs(transcriptId)
      }
      // ReactGA.event({
      //   action: "speaker name set",
      //   category: "editor",
      // })

      customEvent('editor_speaker_name_set', {
        category: 'editor',
        transcriptId: transcript.id,
        user_userId: userData.id,
        accountId: accountId,
      })
      setEditStatus(true)
    }
  }
  // const handlAddverifystatus = (index: number, isverifiedValue: boolean) => {
  //   if (value) {
  //     const state = createDraft(value)
  //     state[index].isverified = isverifiedValue
  //     state[index].VerifiedBy = userData.name
  //     const newState = finishDraft(state)
  //     setValue(newState)
  //     setNeedUpdate(true)
  //     updateParagraphsData(newState)
  //     setEditStatus(true)
  //   }
  // }

  const handleBulkChangeSpeakerName = (
    indexes: number[],
    newSpeakerName: string,
  ) => {
    if (transcript.speakerNames !== undefined) {
      if (newSpeakerName) {
        const key = newSpeakerName.replace(' ', '')
        if (value) {
          const state = createDraft(value)
          for (const itemIndex of indexes) {
            state[itemIndex].speaker = key
          }
          const newState = finishDraft(state)
          console.log('new state', newState)
          setValue(newState)
          updateParagraphsData(newState)
          setNeedUpdate(true)
          fetchParagraphs(transcriptId)
        }

        customEvent('editor_speaker_name_changed', {
          category: 'editor',
          transcriptId: transcript.id,
          user_userId: userData.id,
          accountId: accountId,
        })

        dispatch(setEditSpeakerState({}))
      }
      setEditStatus(true)
    }
  }

  const handleChangeSpeakerName = (index: number, newSpeakerName: string) => {
    // console.log("%%%% change: ", index)
    if (transcript.speakerNames !== undefined) {
      // const currentSpeakerName = transcript.speakerNames[speaker]

      if (newSpeakerName) {
        const key = newSpeakerName.replace(' ', '')
        if (value) {
          const state = createDraft(value)
          state[index].speaker = key
          const newState = finishDraft(state)
          // props.updateSpeakerName(key, newSpeakerName, index)
          setValue(newState)
          updateParagraphsData(newState)
          setNeedUpdate(true)
          fetchParagraphs(transcriptId)
          UpdateSpeakerName(newSpeakerName, transcriptId)
        }

        customEvent('editor_speaker_name_changed', {
          category: 'editor',
          transcriptId: transcript.id,
          user_userId: userData.id,
          accountId: accountId,
        })
      }
      setEditStatus(true)
      // updateDb(true)
    }
  }

  const handleRemoveParagraphSpeaker = (index: number) => {
    // console.log("%%%% remove:", speaker)
    // removeParagraphSpeaker(speaker,transcriptId)
    if (value) {
      const state = createDraft(value)
      state[index].speaker = undefined
      const newState = finishDraft(state)
      setValue(newState)
      updateParagraphsData(newState)
      updateDb(true, newState)
      setEditStatus(true)
    }
    customEvent('editor_speaker_name_removed', {
      category: 'editor',
      transcriptId: transcript.id,
      user_userId: userData.id,
      accountId: accountId,
    })
  }

  const handleEditorBlur = () => {
    updateDb()
    const selection = editor.selection
    editor.blurSelection = selection
  }

  const doNodeSplit = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (editor.selection) {
      const { anchor } = editor.selection
      ev.preventDefault()
      Transforms.splitNodes(editor)
      Transforms.setNodes(
        editor,
        {
          id: database.collection('/dummypath').doc().id,
          speaker: undefined,
          startTime:
            editor.children[anchor.path[0] + 1].children[0].metaData.startTime,
          formattedStartTime: nanoSecondsToFormattedTime(
            metaData.timecode,
            editor.children[anchor.path[0] + 1].children[0].metaData.startTime,
            true,
            false,
          ),
          order: anchor.path[0] + 1,
        },
        { at: [anchor.path[0] + 1] },
      )
      const text = editor.children[anchor.path[0] + 1].children[0].text
      const transformedText =
        text && text.length > 0
          ? `${text[0].toUpperCase()}${text.substring(1)}`
          : ``
      Transforms.insertText(editor, transformedText, {
        at: [anchor.path[0] + 1, 0],
      })
    }
    return true
  }
  const skeleton = () => {
    const skeleton = []
    for (let i = 0; i < 6; i++) {
      skeleton.push(<Skeleton key={i} className="skeleton-editor" />)
    }
    if (!value) {
      return skeleton
    }
    return null
  }

  const handlePlayerSpeedFromAudio = (val: string) => {
    if (videoRef && videoRef.current) {
      videoRef.current.setPlayerSpeed(val)
    }
  }

  const onPageInputChange = (ev: React<HTMLInputElement>) => {
    const val = parseInt(ev.target.value)
    if (pages && !isNaN(val) && val <= Object.keys(pages).length && val > 0) {
      setPageIndex(val - 1)
      setValue(pages[val - 1].elements)
      editor.history = {
        redos: [],
        undos: [],
      }

      customEvent('editor_page_changed', {
        category: 'editor',
        transcriptId: transcript.id,
        user_userId: userData.id,
        actionSource: 'top-page-switcher',
        actionDestination: val <= pageIndex ? 'previous-page' : 'next-page',
        accountId: accountId,
      })
    }
  }

  const updateParagraphsData = (nodes: Node[]) => {
    const pagesData = pages
    pagesData[pageIndex].elements = nodes
    setPages(pagesData)
  }

  const handlePageChange = useCallback(
    (direction: 'forward' | 'back') => {
      switch (direction) {
        case 'forward': {
          if (pages && pageIndex < Object.keys(pages).length - 1) {
            const prev = pageIndex
            setPageIndex(prev + 1)
            setValue(pages[pageIndex + 1].elements)
            editor.history = {
              redos: [],
              undos: [],
            }
          }
          break
        }

        case 'back': {
          if (pages && pageIndex > 0) {
            const prev = pageIndex
            setPageIndex(prev - 1)
            setValue(pages[prev - 1].elements)
            editor.history = {
              redos: [],
              undos: [],
            }
          }
          break
        }

        default:
          break
      }
    },
    [editor.history, pageIndex, pages],
  )

  const handlePageBack = () => {
    handlePageChange('back')
    customEvent('editor_page_changed', {
      category: 'editor',
      transcriptId: transcript.id,
      user_userId: userData.id,
      actionSource: 'bottom-page-switcher',
      actionDestination: 'previous-page',
      accountId: accountId,
    })
  }

  const handlePageForward = () => {
    handlePageChange('forward')
    customEvent('editor_page_changed', {
      category: 'editor',
      transcriptId: transcript.id,
      user_userId: userData.id,
      actionSource: 'bottom-page-switcher',
      actionDestination: 'next-page',
      accountId: accountId,
    })
  }

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault()
        ReactEditor.blur(editor)
        if (audioRef.current) {
          audioRef.current.togglePlay('Tab')
        }
      }

      if (isPageForward(event)) {
        event.preventDefault()
        ReactEditor.blur(editor)
        handlePageChange('forward')
      } else if (isPageBack(event)) {
        event.preventDefault()
        ReactEditor.blur(editor)
        handlePageChange('back')
      }
    },
    [editor, handlePageChange],
  )

  const handleEditorKeyPress = (event: KeyboardEvent) => {
    if (event.key === ' ') {
      setEditStatus(true)
      handleSpace(event)
    } else if (event.key === 'Backspace') {
      setEditStatus(true)
      handleBackspace(event)
    } else if (event.key === 'Enter') {
      setEditStatus(true)
      doNodeSplit(event)
    } else {
      if (!KEYS.includes(event.key)) {
        setEditStatus(true)
      }

      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          event.preventDefault()
          const mark = HOTKEYS[hotkey]
          if (mark == 'highlight') {
            toggleMark(editor, 'highlight', markSelect)
          } else {
            toggleMark(editor, mark)
          }
        }
      }
    }
  }

  useEffect(() => {
    setTimeCodePreferrence(transcript.timeCodePreference)
  }, [transcript.timeCodePreference])

  useEffect(() => {
    // console.log("!!!!!", transcript)
    if (pages) {
      document.addEventListener('keydown', handleKeyPress, false)

      return () => {
        document.removeEventListener('keydown', handleKeyPress, false)
      }
    }
  }, [pages, pageIndex, handleKeyPress])

  useInterval(() => {
    if (
      transcript &&
      transcript.paragraphs &&
      transcript.paragraphsForTranscript === transcript.id
    ) {
      updateDb()
    }
  }, 10000)

  useEffect(() => {
    if (transcriptId) {
      fetchParagraphs(transcriptId)
    }
  }, [transcriptId])

  useEffect(() => {
    if (
      transcript &&
      transcript.paragraphs &&
      (value === null || hasRun) &&
      transcript.paragraphsForTranscript === transcript.id
    ) {
      populateState()
      console.log('State update')
    }
    if (transcript && needUpdate) {
      updateDb(true)
    }
  }, [transcript, needUpdate, hasRun])

  useEffect(() => {
    if (
      mediaPlaying &&
      pageLimitReached &&
      pageIndex < Object.keys(pages).length - 1
    ) {
      handlePageForward()
      setPageLimitReached(false)
    }
  }, [mediaPlaying, pageLimitReached, pageIndex, pages])

  // let allowSharing =
  //   organizations && organizations.features && organizations.features.sharing
  //     ? true
  //     : false
  // if (!allowSharing) {
  //   allowSharing = userFeatures && userFeatures.sharing ? true : false
  // }
  const markOptions = [
    { label: 'Yellow', value: '#FFEB3B', color: 'yellow' },
    { label: 'Blue', value: '#67DFFF', color: 'blue' },
    { label: 'Violet', value: '#E196FF', color: 'violet' },
    { label: 'Pink', value: '#FF659F', color: 'pink' },
    { label: 'Orange', value: '#FF9800', color: 'orange' },
  ]
  const handleMarkChange = (value: string | undefined) => {
    if (value) {
      setMarkSelect(value)
    }
  }
  const handleTranslationChange = async (value: string) => {
    setParagraphToFetch(value)
    setHasRun(true)
  }

  useEffect(() => {
    if (transcriptId && paragraphToFetch && hasRun) {
      fetchParagraphs(transcriptId)
    }
  }, [paragraphToFetch, transcriptId, hasRun])
  // useEffect(() => {
  //   const verifiedCount = transcript.paragraphs && transcript.paragraphs.filter((entry) => entry.VerifiedBy === true).length
  //   setVerifiedPara(verifiedCount)
  // }, [transcript.paragraphs])
  const verifyProjectRole = (roleToSpecify: string) => {
    if (Object.keys(currentProject).length > 0 && roles) {
      const currentUser = currentProject.users[user.uid!]
      const projectRole = roles[currentUser?.role]?.[roleToSpecify]
      return projectRole
    }
  }
  return (
    <>
      <div className="action-panel-grid sticky">
        {(transcript.projectId === '' && props.checkRole(roleType.EDIT)) ||
        verifyProjectRole('transcript')?.includes(roleType.EDIT) ? (
          <div className="flex items-center gap-1.5">
            <CustomTooltip
              content={t('shortcutDialog.undo')}
              className="bg-lstnBlueGray-400"
            >
              <span>
                <IconButton
                  size="md"
                  variant="outlined"
                  className="w-11 h-10 border border-lstnBlueGray-300 rounded-lg "
                  disabled={!editor.history || editor.history.undos.length < 1}
                  onClick={doUndo}
                  data-cy="history.undo-button"
                >
                  <img src={Undo} className="w-4 h-3.5" alt="img" />
                </IconButton>
              </span>
            </CustomTooltip>
            <CustomTooltip
              content={t('shortcutDialog.redo')}
              className="bg-lstnBlueGray-400"
            >
              <span>
                <IconButton
                  size="md"
                  variant="outlined"
                  className="w-11 h-10 border border-lstnBlueGray-300 rounded-lg"
                  disabled={!editor.history || editor.history.redos.length < 1}
                  onClick={doRedo}
                  data-cy="history.redo-button"
                >
                  <img src={Redo} className="w-4 h-3.5" alt="img" />
                </IconButton>
              </span>
            </CustomTooltip>
            {/* <IconButton  size='sm' variant="outlined" className="border border-lstnBlueGray-300 rounded-lg" onMouseDown={handleMarkClick} data-cy="transcript-details-MarkButton"> */}
            {/* onClick={onHighlight} disabled={this.state.markerWordIndexStart === undefined || this.state.markerWordIndexEnd === undefined} */}
            {/* <img src={HighlightIcon} height="20px" alt="" /> */}
            <CustomTooltip
              content={t('shortcutDialog.highlight')}
              className="bg-lstnBlueGray-400"
            >
              <span>
                <SelectColor
                  options={markOptions}
                  onChange={handleMarkChange}
                  handleMarkClick={handleMarkClick}
                  selectedValue={markSelect}
                  className="relative text-sm font-medium w-14 h-[2.3rem] selectorColor text-blue-gray-500  hover:text-lstnBlueGray-900 uppercase bg-white rounded-lg"
                  children={undefined}
                />
              </span>
            </CustomTooltip>
            {/* </IconButton> */}
            <CustomTooltip
              content={t('shortcutDialog.strike')}
              className="bg-lstnBlueGray-400"
            >
              <span>
                <IconButton
                  size="md"
                  variant="outlined"
                  className="w-11 h-10 border border-lstnBlueGray-300 rounded-lg"
                  onMouseDown={handleStrikeClick}
                  data-cy="transcript-details-StrikeButton"
                >
                  {/* onClick={this.deleteWords} disabled={this.state.markerWordIndexStart === undefined || this.state.markerWordIndexEnd === undefined} */}
                  <Strike />
                </IconButton>
              </span>
            </CustomTooltip>
          </div>
        ) : (
          <div className="action-panel-grid-left"></div>
        )}
        <div className="action-panel-grid-right gap-2">
          {/* {transcript.paragraphs && (
            <Typography className="text-xs font-normal text-lstnBlueGray-400 flex items-center px-4 mb-0">
              {verifiedPara} of {transcript.paragraphs?.length} Verified
            </Typography>
          )} */}
          {pages && (
            <CustomTooltip
              content={t('shortcutDialog.changePage')}
              className="bg-lstnBlueGray-400"
            >
              <span>
                <div className="flex items-center justify-center text-sm font-normal h-10 w-24 bg-lstnBlueGray-50 rounded-lg p-2">
                  <Input
                    size="XS"
                    labelProps={{ className: 'hidden' }}
                    containerProps={{
                      className: 'pageIndexInput',
                    }}
                    className="border-none text-lstnGreen-500 action-right-input text-sm"
                    type="number"
                    value={pageIndex + 1}
                    onChange={onPageInputChange}
                  />
                  <span className="mr-2 text-lstnBlueGray-500">/</span>
                  <Typography className="text-lstnBlueGray-500 mb-0 ml-0 mr-2 text-sm">
                    {Object.keys(pages).length}
                  </Typography>
                </div>
              </span>
            </CustomTooltip>
          )}
          {/* {allowSharing && ( */}
          <Fragment>
            {/* {props.checkRole(roleType.SHARE) && ( */}
            <CustomTooltip
              content={'Translate'}
              className="bg-lstnBlueGray-400"
            >
              <span>
                <IconButton
                  size="md"
                  variant="outlined"
                  className="w-11 h-10 border border-lstnBlueGray-300 rounded-lg "
                  onClick={handleOpen}
                >
                  <img src={translation} className="w-4 h-4" alt="img" />
                </IconButton>
              </span>
            </CustomTooltip>
            {/* )} */}
          </Fragment>
          {/* )} */}
          <div className="relative">
            <CustomTooltip
              content={t('exportMenu.export')}
              className="bg-lstnBlueGray-400"
            >
              <span>
                <ExportMenu
                  onSave={props.onExport}
                  userStatus={userStatus ? userStatus : null}
                  disabled={
                    transcript.loading || transcript.projectId === ''
                      ? !props.checkRole(roleType.EXPORT)
                      : !verifyProjectRole('transcript')?.includes(
                          roleType.EXPORT,
                        )
                  }
                  timeCodePreferrence={timeCodePreferrence}
                  userFeatures={userFeatures}
                  languageCode={paragraphToFetch}
                />
              </span>
            </CustomTooltip>
          </div>
          {(transcript.projectId === '' && props.checkRole(roleType.DELETE)) ||
          verifyProjectRole('transcript')?.includes(roleType.DELETE) ? (
            <div className="flex items-center">
              <CustomTooltip
                content={t('transcripts.delete')}
                className="bg-lstnBlueGray-400"
              >
                <span>
                  <Button
                    className="transcript-details-action-delete-btn "
                    data-cy="transcript-details-delete-btn"
                    onClick={props.onDelete}
                  >
                    <img src={Delete} alt="img" className="w-3.5 h-4" />
                  </Button>
                </span>
              </CustomTooltip>
            </div>
          ) : null}
        </div>
      </div>

      <div>
        <>
          <div className="w-full h-9 flex items-end px-4 gap-2 clipContainer">
            {translationLanguages && translationLanguages.length !== 0 ? (
              <ChipIcon
                code={transcript.metadata?.languageCodes[0]?.split('-')[1]}
                handleClick={handleTranslationChange}
                parameterValue={`paragraphs`}
                showCloseIcon={false}
                bgClass={
                  paragraphToFetch == 'paragraphs'
                    ? 'bg-white'
                    : 'bg-blue-gray-50'
                }
                value={
                  Object.entries(translationSourceLanguages).find(
                    ([key]) => key === transcript.metadata?.languageCodes[0],
                  )?.[1]
                }
              />
            ) : null}
            {translationLanguages.map((item, index) => {
              return (
                <ChipIcon
                  key={index}
                  code={item.value}
                  value={item.label}
                  handleClick={handleTranslationChange}
                  parameterValue={`paragraphs-${item.value}`}
                  showCloseIcon={true}
                  bgClass={
                    paragraphToFetch == `paragraphs-${item.value}`
                      ? 'bg-white'
                      : 'bg-blue-gray-50'
                  }
                />
              )
            })}
          </div>
          <div
            data-tut="Paragraph-tour"
            className="transcript-details-grid shadow-md"
          >
            <Dialog
              open={metaModal}
              handler={openMetaModal}
              aria-labelledby="form-dialog-title"
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
              }}
              size="xs"
              placeholder={undefined}
            >
              <form onSubmit={submitData}>
                <Card className="mx-auto w-full">
                  <CardBody className="flex flex-col gap-4">
                    <Typography variant="h6" color="blue-gray-900">
                      Start Timecode
                    </Typography>
                    <div className="timecode-field flex items-start gap-2">
                      <div className="border-b border-black">
                        <Typography
                          className="mb-3 font-normal"
                          variant="paragraph"
                          color="gray"
                        >
                          HR
                        </Typography>
                        <Input
                          // classes={{ root: classes.timecodeText }}
                          name="timecodeHr"
                          placeholder="00"
                          labelProps={{ className: 'hidden' }}
                          containerProps={{ className: 'min-w-[80px]' }}
                          label="HR"
                          value={
                            metaData.timecode
                              ? metaData.timecode.split(':')[0]
                              : '00'
                          }
                          disabled={!props.checkRole(roleType.EDIT)}
                          onChange={handleDataChange}
                        />
                      </div>
                      <div className="border-b border-black">
                        <Typography
                          className="mb-3 font-normal"
                          variant="paragraph"
                          color="gray"
                        >
                          MIN
                        </Typography>
                        <Input
                          classes={{ root: classes.timecodeText }}
                          name="timecodeMin"
                          placeholder="00"
                          labelProps={{ className: 'hidden' }}
                          containerProps={{ className: 'min-w-[80px]' }}
                          label="MIN"
                          value={
                            metaData.timecode
                              ? metaData.timecode.split(':')[1]
                              : '00'
                          }
                          disabled={!props.checkRole(roleType.EDIT)}
                          onChange={handleDataChange}
                        />
                      </div>
                      <div className="border-b border-black">
                        <Typography
                          className="mb-3 font-normal"
                          variant="paragraph"
                          color="gray"
                        >
                          SEC
                        </Typography>
                        <Input
                          classes={{ root: classes.timecodeText }}
                          name="timecodeSec"
                          placeholder="00"
                          labelProps={{ className: 'hidden' }}
                          containerProps={{ className: 'min-w-[80px]' }}
                          label="SEC"
                          value={
                            metaData.timecode
                              ? metaData.timecode.split(':')[2]
                              : '00'
                          }
                          disabled={!props.checkRole(roleType.EDIT)}
                          onChange={handleDataChange}
                        />
                      </div>
                      <div className="border-b border-black">
                        <Typography
                          className="mb-3 font-normal"
                          variant="paragraph"
                          color="gray"
                        >
                          MS
                        </Typography>
                        <Input
                          classes={{ root: classes.timecodeText }}
                          name="timecodeMs"
                          placeholder="00"
                          labelProps={{ className: 'hidden' }}
                          containerProps={{ className: 'min-w-[80px]' }}
                          label="MS"
                          value={
                            metaData.timecode
                              ? metaData.timecode.split(':')[3]
                              : '00'
                          }
                          disabled={!props.checkRole(roleType.EDIT)}
                          onChange={handleDataChange}
                        />
                      </div>
                    </div>
                  </CardBody>
                  {/* <Form.Field> */}
                  {/* <label>FramesPerSecond</label> */}
                  {/* <input name="fps" placeholder="FramesPerSecond" value={metaData.framesPerSecond ? metaData.framesPerSecond : 0} onChange={handleDataChange} /> */}
                  {/* </Form.Field> */}
                  <CardFooter className="pt-0">
                    <Button type="submit" size="small">
                      OK
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </Dialog>

            {value && (
              <Slate
                editor={editor}
                value={value}
                onChange={handleEditorChange}
              >
                <Editable
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                  contentEditable={mediaPlaying ? false : true}
                  placeholder="Enter some rich text…"
                  spellCheck
                  autoFocus
                  style={editorStyle}
                  onBlur={handleEditorBlur}
                  onKeyDown={handleEditorKeyPress}
                  readOnly={
                    (transcript.projectId === '' &&
                      !props.checkRole(roleType.EDIT)) ||
                    (transcript.projectId !== '' &&
                      !verifyProjectRole('transcript')?.includes(roleType.EDIT))
                  }
                />
              </Slate>
            )}

            {!value && skeleton()}

            {pages && (
              <div className="action-panel-page-nav-bottom gap-4 items-center">
                {/* <MuiThemeProvider theme={lstnBasictheme}> */}
                <IconButton
                  variant="outlined"
                  className="border border-lstnBlueGray-50 cursor-pointer shadow-md hover:shadow-lg"
                  size="sm"
                  disabled={pageIndex <= 0}
                  onClick={handlePageBack}
                >
                  <Left className="paginationIcon" />
                </IconButton>
                <Typography className="text-xs font-bold text-lstnBlueGray-300 mb-0">
                  {pageIndex + 1} of {Object.keys(pages).length}
                </Typography>
                <IconButton
                  variant="outlined"
                  className="border border-lstnBlueGray-50 cursor-pointer shadow-md hover:shadow-lg"
                  size="sm"
                  disabled={pageIndex >= Object.keys(pages).length - 1}
                  onClick={handlePageForward}
                >
                  <Right className="paginationIcon" />
                </IconButton>
                {/* </MuiThemeProvider> */}
              </div>
            )}
          </div>
          {transcript.playbackGsUrl && props.checkRole(roleType.PLAYAUDIO) && (
            <AudioPlayer
              ref={audioRef}
              playbackGsUrl={transcript.playbackGsUrl}
              onPlay={togglePlay}
              onPause={togglePlay}
              // handleTimeUpdate={recomputeWords}
              timeCodePreferrence={timeCodePreferrence}
              onEditBtnClick={openMetaModal}
              timecode={metaData.timecode}
              handleTimeToggle={handleTimeToggle}
              handlePlayerSpeedFromAudio={handlePlayerSpeedFromAudio}
              userData={props}
              userDataId={userData.id}
              verifyProjectRole={verifyProjectRole}
            />
          )}
          {transcript.playbackVideoGsUrl && (
            <VideoPlayer
              ref={videoRef}
              playbackVideoGsUrl={transcript.playbackVideoGsUrl}
            />
          )}
        </>
        <UpgradeModal UpgradeOpen={UpgradeOpen} handleUpgrade={handleUpgrade} />
      </div>
      <TranslateDialog
        handleOpen={handleOpen}
        open={open}
        handleTranslationChange={handleTranslationChange}
        fetchParagraphs={fetchParagraphs}
        setHasRun={setHasRun}
      />
    </>
  )
}

// const mapStateToProps = (state: ) => {
//   // let organization: IOrg[] = state.firestore.ordered.organizations
//   // console.log(state.transcript,'transcripteditor');

//   // return {
//     // organizations: organization && organization[0] ? organization[0] : undefined,
//     // userData: state.firestore.ordered.users,
//     // transcript: state.transcript,
//     // videoPlayCurrentTime: state.TimeReducer,
//   // }
// }

// const mapDispatchToProps = (dispatch: ) => {
//   return {
// readParagraphs: (paragraphs: IParagraph[], transcriptId: string) => dispatch(readParagraphs(paragraphs, transcriptId)),
// updateFramesPerSecond: (framesPerSecond: number) => dispatch(updateFramesPerSecond(framesPerSecond)),
// updateSpeaker: (paragraphIndex: number, speaker: number) => dispatch(updateSpeaker(paragraphIndex, speaker)),
// updateSpeakerName: (speaker: string, name: string, paragraphIndex?: number) => dispatch(updateSpeakerName(speaker, name, paragraphIndex)),
// updateTimecode: (timecode: string) => dispatch(updateTimecode(timecode)),
// removeParagraphSpeaker: (paragraphIndex: number) => dispatch(removeParagraphSpeaker(paragraphIndex)),
// updateStatus: (status: ProgressType) => dispatch(updateStatus(status)),
// SetCurrentTimeState: (currentTime: ) => dispatch(SetCurrentTimeState(currentTime)),
//   }
// }

// const enhance = compose(withFirestore, connect( mapDispatchToProps))

export default TranscriptionEditor
