import { AxiosResponse } from 'axios'
import { saveAs } from 'file-saver'
import { customEvent } from './customHooks'
import { IProject } from '../interfaces'

const downloader = (
  response: AxiosResponse<any>,
  fileType: string,
  uid: string,
  transcriptIds: string,
  accountId: string,
  currentProject: IProject,
) => {
  const mimeType = {
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    srt: 'text/plain',
    xmp: 'text/plain',
  }
  const blob = new Blob([response.data], { type: mimeType[fileType] })
  const contentDisposition = response.headers['content-disposition']
  let fileName = 'unknown'
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename=(.+)/)
    if (fileNameMatch.length === 2) fileName = fileNameMatch[1]
  }
  saveAs(blob, fileName)
  customEvent('transcript_export_file_downloaded', {
    category: 'transcript',
    transcriptId: transcriptIds,
    fileType: fileType,
    user_userId: uid,
    accountId: accountId,
    projectId: currentProject && currentProject.id ? currentProject.id : '',
  })
}

export default downloader
