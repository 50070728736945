import React from 'react'
import Tour from 'reactour'
import { useTranslation } from 'react-i18next'
import '../../css/OnBoarding.css'

interface IProps {
  openTourGuide: boolean
  handleCloseTour: () => void
}

export default function TourGuide({ handleCloseTour, openTourGuide }: IProps) {
  const { t } = useTranslation()

  const steps = [
    {
      selector: '[data-tut="tour-add-project-btn"]',
      content: () => {
        return (
          <div>
            <div className="text-2xl text-blue-gray-900 font-normal">
              {' '}
              {t('projectTour.step1')}
            </div>
            <div className="tour-step text-blue-gray-400">
              {t('projectTour.bodyStep1')}
            </div>
          </div>
        )
      },
    },
    {
      selector: '[data-tut="tour-add-project-btn"]',
      content: () => {
        return (
          <div>
            <div className="text-2xl text-blue-gray-900 font-normal">
              {' '}
              {t('projectTour.step2')}
            </div>
            <div className="tour-step text-blue-gray-400">
              {t('projectTour.bodyStep2')}
            </div>
          </div>
        )
      },
    },
    {
      selector: '[data-tut="tour-add-project-btn"]',
      content: () => {
        return (
          <div>
            <div className="text-2xl text-blue-gray-900 font-normal">
              {t('projectTour.step3')}
            </div>
            <div className="tour-step text-blue-gray-400">
              {t('projectTour.bodyStep3')}
            </div>
          </div>
        )
      },
    },
  ]

  return (
    <Tour
      steps={steps}
      isOpen={openTourGuide}
      onRequestClose={handleCloseTour}
      lastStepNextButton={
        <button className="next-btn-tour">{t('uploadScreenTour.done')}</button>
      }
      className="tour-box"
      accentColor={'rgb(37,218,60)'}
      badgeContent={(curr, tot) => `${curr} of ${tot}`}
      // showNumber={false}
    />
  )
}
