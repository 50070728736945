import firebase from 'firebase/compat/app'
import { database } from '../../firebaseApp'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface IReplies {
  userQuestion: 'string'
  aiResponse: 'string'
  createdAt: Date
  id: 'string'
}
interface IState {
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null | undefined
  replies: IReplies[]
}

export const fetchAllConversations = createAsyncThunk(
  'fetchAllConversations',
  async (data: IReplies[]): Promise<any> => {
    try {
      return data
    } catch (error) {
      console.log(error)
    }
  },
)
export const fetchConversations = async (
  { projectId, transcriptId }: any,
  dispatch: any,
) => {
  const unsubscribe = await database
    .collection('conversations')
    .where('projectId', '==', projectId ? projectId : '')
    .where('transcriptId', '==', transcriptId ? transcriptId : '')
    .onSnapshot(async (querySnapshot) => {
      if (querySnapshot.empty) return
      const conversationRef = querySnapshot.docs[0].ref
      const userId = firebase.auth().currentUser?.uid
      const messagesRef = conversationRef
        // @ts-ignore
        .collection(userId)
        .orderBy('createdAt', 'asc')
      messagesRef.onSnapshot(async (SnapShot) => {
        const messages = SnapShot.docs.map((doc) => {
          const Data = doc.data()
          const message = {
            ...Data,
            id: doc.id,
          }
          return message
        })
        dispatch(fetchAllConversations(messages as IReplies[]))
      })
    })
  return unsubscribe
}

const initialState: IState = {
  loading: 'idle',
  error: null,
  replies: [],
}

const conversationSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    resetReplies: (state) => {
      state.replies = []
      state.loading = 'idle'
    },
    setReplies: (state, action) => {
      state.replies = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllConversations.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(fetchAllConversations.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        state.replies = action.payload
      })
      .addCase(fetchAllConversations.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.error.message
      })
  },
})

export const { resetReplies, setReplies } = conversationSlice.actions
export default conversationSlice.reducer

export const createOrUpdateConversation = async (data: {
  userQuestion: string | undefined
  projectId: string
  transcriptId: string | undefined
  userId: string
}) => {
  const conversationQuery = await database
    .collection('conversations')
    .where('projectId', '==', data.projectId)
    .where('transcriptId', '==', data.transcriptId)
    .get()

  let conversationRef
  if (conversationQuery.empty) {
    // If conversation document doesn't exist, create a new one
    conversationRef = database.collection('conversations').doc()
    await conversationRef.set({
      projectId: data.projectId,
      transcriptId: data.transcriptId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      topic: 'transcription',
      engine: process.env.AI_ENGINE || 'gpt-4-turbo',
    })
  } else {
    conversationRef = conversationQuery.docs[0].ref
  }
  const messagesRef = conversationRef.collection(data.userId)
  await messagesRef.add({
    userQuestion: data.userQuestion,
    aiResponse: '',
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  })
}
