import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { database } from '../../firebaseApp'
import { list_to_tree } from '../utils'
import { IFolders } from '../../interfaces'
// import { IFolders } from "../../interfaces"

const initialState = {
  folderHierarchy: [] as IFolders[],
  error: null,
  status: 'idle',
}
const folderHierarchySlice = createSlice({
  name: 'folder',
  initialState,
  reducers: {
    resetFolderHierarchy: (state) => {
      ;(state.folderHierarchy = []), (state.error = null)
      state.status = 'idle'
    },
    getFolderHierarchy: (state, action: PayloadAction) => {
      state.folderHierarchy = list_to_tree(action.payload)
      state.status = 'succeeded'
      // console.log(state.folderHierarchy,'add');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFolderHeirarchy.pending, (state) => {
      state.status = 'pending'
    }),
      builder.addCase(fetchFolderHeirarchy.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export const { resetFolderHierarchy, getFolderHierarchy } =
  folderHierarchySlice.actions
export default folderHierarchySlice.reducer

//------------------------FolderHeirarchy Function----------

export const fetchFolderHeirarchy = createAsyncThunk(
  'folders/getFolderHeirarchy',
  async (userId: string, { dispatch }) => {
    const folders: { id: string }[] = []

    const foldersQuery = database
      .collection('folders')
      .where('userId', '==', userId)
    // console.log(foldersQuery, "userFoldersQuery")
    const folderUnsubscribe = await foldersQuery.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        // console.log("Change Type:", change.type);

        const folderData = change.doc.data()
        // console.log("Folder Data:", folderData);
        if (change.type === 'added') {
          await folders.push({ ...folderData, id: change.doc.id })
        } else if (change.type === 'modified') {
          const FolderIndex = folders.findIndex(
            (v: { id: string }) => v.id === change.doc.id,
          )
          if (FolderIndex !== -1) {
            folders[FolderIndex] = { ...folderData, id: change.doc.id }
          }
        } else if (change.type === 'removed') {
          const removedFolderIndex = folders.findIndex(
            (f: { id: string }) => f.id === change.doc.id,
          )
          if (removedFolderIndex !== -1) {
            folders.splice(removedFolderIndex, 1)
          }
        }
      })
      // @ts-ignore
      dispatch(getFolderHierarchy(folders))
    })

    const sharedFoldersQuery = database
      .collection('folders')
      .where('sharedUserIds', 'array-contains', userId)

    const sharedFoldersUnsubscribe = await sharedFoldersQuery.onSnapshot(
      (snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          // console.log("Change Type:", change.type);

          const folderData = change.doc.data()
          // console.log("Folder Data:", folderData);
          if (change.type === 'added') {
            await folders.push({ ...folderData, id: change.doc.id })
          } else if (change.type === 'modified') {
            const FolderIndex = folders.findIndex(
              (v: { id: string }) => v.id === change.doc.id,
            )
            if (FolderIndex !== -1) {
              folders[FolderIndex] = { ...folderData, id: change.doc.id }
            }
          } else if (change.type === 'removed') {
            const removedFolderIndex = folders.findIndex(
              (f: { id: string }) => f.id === change.doc.id,
            )
            if (removedFolderIndex !== -1) {
              folders.splice(removedFolderIndex, 1)
            }
          }
        })
        // @ts-ignore
        dispatch(getFolderHierarchy(folders))
      },
    )

    return () => {
      folderUnsubscribe()
      sharedFoldersUnsubscribe()
    }
  },
)

//----------folder slice-----------------------
interface FolderState {
  folderData?: IFolders | undefined
  error: null | Error
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
}

const initialStateFolder: FolderState = {
  folderData: undefined,
  error: null,
  status: 'idle',
}

const folderSlice = createSlice({
  name: 'folder',
  initialState: initialStateFolder,
  reducers: {
    resetFolder: (state) => {
      ;(state.folderData = undefined), (state.error = null)
    },
    getFolder: (state, action: PayloadAction<IFolders>) => {
      state.folderData = action.payload
      // console.log(state.folderData,'&&&&&');
      state.status = 'succeeded'
    },
    getFolderError: (state, action: PayloadAction<Error>) => {
      state.folderData = undefined
      state.error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFolderData.pending, (state) => {
      state.status = 'pending'
    }),
      builder.addCase(getFolderData.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export const { getFolder, resetFolder, getFolderError } = folderSlice.actions
export const folderSliceReducer = folderSlice.reducer

export const getFolderData = createAsyncThunk(
  'folder/getFolderData',
  async (id: string | undefined, { dispatch }) => {
    let folderData
    try {
      if (id) {
        database.doc(`folders/${id}`).onSnapshot((snapshot) => {
          // console.log(snapshot.data());
          folderData = snapshot.data() as IFolders
          dispatch(getFolder(folderData))
        })
      } else {
        dispatch(getFolderError(new Error('An error occurred')))
      }
    } catch (error) {
      dispatch(getFolderError(error as Error))
    }
  },
)
