import React from 'react'
import { Card, CardBody, CardFooter, Avatar } from '@material-tailwind/react'
import Typography from '../Typography/Typography'
import { useTranslation } from 'react-i18next'

export default function CardWithLink() {
  const { t } = useTranslation()
  return (
    <Card
      className="w-auto xl:p-0 2xl:p-2 xl:mx-0 2xl:mx-5"
      placeholder={undefined}
    >
      <CardBody placeholder={undefined}>
        <div className="mb-3">
          <img src="/cardicon.svg" alt="img" />
        </div>
        <Typography className="font-normal xl-leading-5 2xl:leading-7 text-sm 2xl:text-base">
          {t && t('auth.sidebar.cardContent')}
        </Typography>
      </CardBody>
      <CardFooter
        color="transparent"
        // floated={false}
        // shadow={false}
        className="mx-0 flex items-center gap-2 2xl:gap-4 pt-0 pb-4 xl:pb-6 2xl:pb-6"
        placeholder={undefined}
      >
        <Avatar
          className="w-12 h-12 2xl:w-14 2xl:h-14"
          variant="circular"
          src="/Avatar.png"
          alt="tania andrew"
          placeholder={undefined}
        />
        <div className="flex w-full flex-col gap-0 2xl:gap-0.5">
          <div className="flex items-center justify-between">
            <Typography color="blue-gray" className="text-base font-semibold">
              Alice Tomassini
            </Typography>
          </div>
          <Typography
            color="blue-gray"
            className="text-sm font-medium text-[#607D8B]"
          >
            {t && t('auth.sidebar.cardJobTitle')}
          </Typography>
        </div>
      </CardFooter>
    </Card>
  )
}
