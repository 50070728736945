import React, { useState } from 'react'
import { IconButton, Drawer } from '@material-tailwind/react'
import MenuIcon from '@material-ui/icons/Menu'
import ClearIcon from '@material-ui/icons/Clear'
import Sidebar from './index'
import '../../css/drawer.css'

const DrawerSidebarMenu = ({
  userId,
}: {
  userId?: string
}): React.JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const openDrawer = (): void => setIsDrawerOpen(true)
  const closeDrawer = (): void => setIsDrawerOpen(false)
  return (
    <div className="drawer-sidebar">
      <IconButton
        variant="text"
        size="lg"
        onClick={openDrawer}
        placeholder={undefined}
        className="w-5"
      >
        {isDrawerOpen ? (
          <ClearIcon className="h-8 w-8 stroke-2" />
        ) : (
          <MenuIcon className="h-8 w-8 stroke-2" />
        )}
      </IconButton>
      <Drawer open={isDrawerOpen} onClose={closeDrawer} placeholder={undefined}>
        <Sidebar userId={userId} />
      </Drawer>
    </div>
  )
}

export default DrawerSidebarMenu
