import React from 'react'
import { Radio } from '@material-tailwind/react'
import { ThemeProvider } from '@material-tailwind/react'

interface IProps {
  name: string
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  label: any
  containerProps: {
    className: string
  }
  crossOrigin: undefined
}
const theme = {
  radio: {
    defaultProps: {
      color: 'green',
      label: undefined,
      icon: undefined,
      ripple: true,
      className: '',
      disabled: false,
      containerProps: undefined,
      labelProps: undefined,
      iconProps: undefined,
    },
    valid: {
      colors: ['green'],
    },
    styles: {
      base: {
        root: {
          display: 'inline-flex',
          alignItems: 'items-center',
        },
        container: {
          position: 'relative',
          display: 'flex',
          alignItems: 'items-center',
          cursor: 'cursor-pointer',
          p: 'p-3',
          borderRadius: 'rounded-full',
        },
        input: {
          peer: 'peer',
          position: 'relative',
          appearance: 'appearance-none',
          width: 'w-5',
          height: 'h-5',
          borderWidth: 'border',
          borderRadius: 'rounded-full',
          borderColor: 'border-blue-gray-200',
          cursor: 'cursor-pointer',
          transition: 'transition-all',
          before: {
            content: "before:content['']",
            display: 'before:block',
            bg: 'before:bg-blue-gray-500',
            width: 'before:w-12',
            height: 'before:h-12',
            borderRadius: 'before:rounded-full',
            position: 'before:absolute',
            top: 'before:top-2/4',
            left: 'before:left-2/4',
            transform: 'before:-translate-y-2/4 before:-translate-x-2/4',
            opacity: 'before:opacity-0 hover:before:opacity-10',
            transition: 'before:transition-opacity',
          },
        },
        label: {
          color: 'text-gray-700',
          fontWeight: 'font-light',
          userSelect: 'select-none',
          cursor: 'cursor-pointer',
          mt: 'mt-px',
        },
        icon: {
          position: 'absolute',
          top: 'top-2/4',
          left: 'left-2/4',
          translate: '-translate-y-2/4 -translate-x-2/4',
          pointerEvents: 'pointer-events-none',
          opacity: 'opacity-0 peer-checked:opacity-100',
          transition: 'transition-opacity',
        },
        disabled: {
          opacity: 'opacity-50',
          pointerEvents: 'pointer-events-none',
        },
      },
      colors: {
        green: {
          color: 'text-lstnGreen-500',
          border: 'checked:border-green-500',
          before: 'checked:before:bg-green-500',
        },
      },
    },
  },
}
function CustomRadio(props: IProps) {
  return (
    <ThemeProvider value={theme}>
      {/* @ts-ignore */}
      <Radio {...props} />
    </ThemeProvider>
  )
}

export default CustomRadio
