import React from 'react'
import { Checkbox } from '@material-tailwind/react'
import type { CheckboxProps } from '@material-tailwind/react'
import { ThemeProvider } from '@material-tailwind/react'

interface CheckboxStylesType {
  checkbox: {
    defaultProps: {
      color: string
    }
    styles: {
      base: {
        label: object
      }
      colors: object
    }
  }
}
interface props {
  checked: boolean
  uncheckedColor?: string
}

export default function CustomCheckbox(props: CheckboxProps & props) {
  const theme: CheckboxStylesType = {
    checkbox: {
      defaultProps: {
        color: 'green',
      },
      styles: {
        base: {
          label: {
            color: 'text-lstnBlueGray-400',
            size: 'text-base',
            weight: 'font-medium',
          },
        },
        colors: {
          green: {
            background: 'checked:bg-lstnGreen-500',
            border: 'checked:border-lstnGreen-500',
          },
          blue: {
            background: 'checked:bg-cyan-500',
            border: 'checked:border-cyan-500',
          },
        },
      },
    },
  }

  return (
    <ThemeProvider value={theme}>
      {/* @ts-ignore */}
      <Checkbox {...props} />
    </ThemeProvider>
  )
}
