export default function nanoSecondsToFormattedTime(
  startTime: string,
  nanoSeconds: number,
  showHours: boolean,
  showMiliSeconds: boolean,
) {
  const parsed = startTime.split(':')
  const converted =
    (parseInt(parsed[0]) * 3600 +
      parseInt(parsed[1]) * 60 +
      parseInt(parsed[2]) +
      parseInt(parsed[3]) * 1e-3) *
    1e9
  const s = (converted + nanoSeconds) * 1e-9

  const hours = Math.floor(s / 3600)
  const minutes = Math.floor((s % 3600) / 60)
  const seconds = Math.floor((s % 3600) % 60)
  const miliSeconds = Math.floor((s % 1) * 1000)

  const hDisplay = showHours ? (hours > 9 ? `${hours}:` : `0${hours}:`) : ''
  const mDisplay = minutes > 9 ? `${minutes}:` : `0${minutes}:`
  const sDisplay = seconds > 9 ? `${seconds}` : `0${seconds}`
  const msDisplay = showMiliSeconds
    ? miliSeconds > 9
      ? `:${miliSeconds}`
      : `:0${miliSeconds}`
    : ''

  return hDisplay + mDisplay + sDisplay + msDisplay
}
