import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { database } from '../../firebaseApp'
import firebase from 'firebase/compat/app'
import { IAccount, IUser } from '../../interfaces'
import { setUserStatus } from './userSlice'
import { Dispatch } from 'redux'
import { AppDispatch } from '../store'

interface AccountsState {
  accounts: IAccount[]
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null | undefined
  currentAccount: IAccount
  accountId: string | null
  members: IUser[]
  teamSwitch: boolean
}
interface FetchAccountsParams {
  dispatch: Dispatch<any>
  id: string
}

export const fetchAllAccounts = createAsyncThunk(
  'accounts/fetchAllAccounts',
  async ({ dispatch }: { dispatch: AppDispatch }) => {
    const currentUserId = firebase.auth().currentUser?.uid
    const accDocQuery = database
      .collection('accounts')
      .where(`users.${currentUserId}.status`, '==', 'active')
    const unsubscribe = accDocQuery.onSnapshot((snapShot) => {
      const accounts = snapShot.docs.map((doc) => {
        const parsedData = JSON.stringify(doc.data())
        const account = {
          ...JSON.parse(parsedData),
          id: doc.id,
        }
        return account
      })
      dispatch(setAccounts(accounts))
    })
    return unsubscribe
  },
)

const initialState: AccountsState = {
  accounts: [],
  loading: 'idle',
  error: null,
  currentAccount: {} as IAccount,
  accountId: null,
  members: [],
  teamSwitch: false,
}

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setCurrentAccount: (state, action) => {
      state.currentAccount = action.payload
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload
    },
    setMembers: (state, action) => {
      state.members = action.payload
    },
    setAccounts: (state, action) => {
      state.accounts = action.payload
    },
    setTeamSwitch: (state, action) => {
      state.teamSwitch = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAccounts.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(fetchAllAccounts.fulfilled, (state) => {
        state.loading = 'succeeded'
        // state.accounts = action.payload
      })
      .addCase(fetchAllAccounts.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.error.message
      })
  },
})

export const { setCurrentAccount, setMembers, setAccounts, setTeamSwitch } =
  accountsSlice.actions
export default accountsSlice.reducer

export const fetchAccounts = ({ dispatch, id }: FetchAccountsParams) => {
  const accDocQuery = database.collection('accounts').doc(id)
  const userId = firebase.auth().currentUser?.uid
  // Subscribe to real-time updates
  const unsubscribe = accDocQuery.onSnapshot(async (SnapShot) => {
    if (SnapShot.exists) {
      const parsedData = JSON.stringify(SnapShot.data())
      const accData = {
        ...JSON.parse(parsedData),
        id: SnapShot.id,
      }
      if (userId) {
        const status = {
          plan: accData?.stripeSubscriptionData?.plan,
          status: accData?.subscriptionStatus,
          remainingSeconds: accData?.remainingSeconds,
          additionalSeconds: accData?.additionalSeconds,
        }
        dispatch(setUserStatus(status))
      }
      // dispatch(setOrganizations(accData))
      dispatch(setCurrentAccount(accData))
      localStorage.setItem(
        'selectedAccount',
        JSON.stringify({ accountId: accData.id }),
      )

      // Fetch members of the account
      const userPromises = Object.keys(accData.users).map((userId) =>
        database.collection('users').doc(userId).get(),
      )
      const userSnapShots = await Promise.all(userPromises)
      const users = userSnapShots
        .map((snap) => (snap.exists ? { ...snap.data(), id: snap.id } : null))
        .filter(Boolean)
      dispatch(setMembers(users))
    }
  })
  return () => unsubscribe()
}

export const updateAccountName = async (
  id: string,
  name: string,
): Promise<void> => {
  const accDocRef = database.collection('accounts').doc(id)
  await accDocRef.update({ name: name })
}

export const updateAccountDescription = async (
  id: string,
  description: string,
): Promise<void> => {
  const accDocRef = database.collection('accounts').doc(id)
  await accDocRef.update({ description: description })
}

export const updateAccountLogo = async (
  id: string,
  logo: string | ArrayBuffer | null,
): Promise<void> => {
  const accDocRef = database.collection('accounts').doc(id)
  await accDocRef.update({ accountLogo: logo })
}

export const updateAccountMemberRole = async (
  accountId: string,
  memberId: string,
  role: string,
): Promise<void> => {
  const accDocRef = database.collection('accounts').doc(accountId)
  await accDocRef.update({
    [`users.${memberId}`]: { role: role, status: 'active' },
  })
  const userDocRef = database.collection('users').doc(memberId)
  await userDocRef.update({
    [`accounts.${accountId}`]: { role: role, status: 'active' },
  })
}

export const removeAccountMember = async (
  accountId: string,
  memberId: string,
): Promise<void> => {
  const accDocRef = database.collection('accounts').doc(accountId)
  await accDocRef.update({
    [`users.${memberId}`]: firebase.firestore.FieldValue.delete(),
  })
  const userDocRef = database.collection('users').doc(memberId)
  await userDocRef.update({
    [`accounts.${accountId}`]: firebase.firestore.FieldValue.delete(),
  })
}
