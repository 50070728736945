import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
} from '@material-tailwind/react'
import Typography from '../../Typography/Typography'

interface IProps {
  open: boolean
  onSubmit: (value?: string) => void
  onClose: () => void
}

export default function AddSpeaker({ open, onClose, onSubmit }: IProps) {
  const { t } = useTranslation()
  const [value, setValue] = useState<string | undefined>('')

  const handleSubmit = () => {
    onSubmit(value)
    setValue('')
  }
  const handleClose = () => {
    onClose()
    setValue('')
  }

  const handleText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  return (
    <Dialog
      open={open}
      handler={handleClose}
      placeholder={undefined}
      size="xs"
      className="p-4"
    >
      <DialogHeader
        id="alert-dialog-slide-title"
        className="py-2 px-4 text-2xl font-semibold text-blue-gray-900"
        placeholder={undefined}
      >
        {t('addpeakerDialog.add')}
      </DialogHeader>
      <DialogBody placeholder={undefined} data-cy="data-project-rename-name">
        <Typography
          variant="h6"
          className="mb-2 text-sm font-medium text-lstnBlueGray-400"
        >
          {t && t('addpeakerDialog.name')}
        </Typography>
        <Input
          autoFocus
          size="lg"
          value={value}
          placeholder="Speaker Name"
          className=" !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100"
          labelProps={{
            className: 'hidden',
          }}
          style={{ borderRadius: '8px' }}
          onChange={handleText}
          crossOrigin={undefined}
        />
      </DialogBody>
      <DialogFooter placeholder={undefined} className="gap-0.5">
        <Button
          onClick={handleClose}
          variant="text"
          className="text-xs font-bold uppercase hover:bg-transparent"
          data-cy="data-project-rename-cancel"
          placeholder={undefined}
        >
          {t('addpeakerDialog.cancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          className="bg-lstnGreen-500 text-white text-xs font-bold uppercase rounded-lg"
          placeholder={undefined}
          variant="filled"
          disabled={!value}
          data-cy="data-project-rename-submit"
        >
          {t('addpeakerDialog.btnAdd')}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
