import React, { useEffect, useState } from 'react'
import { Select, Option, Typography, Spinner } from '@material-tailwind/react'
import type { SelectProps } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'

interface IProps {
  accounts: Array<{
    id: string
    name: string
    users: { [key: string]: { status: string; role: string } }
  }>
  selectedAccount: string
  selectClassName?: string
  onChange: (value: string) => void
  collapsed?: any
}

const Selector: React.FC<IProps & SelectProps> = (props) => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  // Update the key when the selectedAccount changes
  useEffect(() => {
    setValue(props.selectedAccount)
  }, [props.selectedAccount])

  if (!value) {
    return <Spinner />
  }

  return (
    <div className="w-auto">
      <Select
        size="lg"
        onChange={props.onChange}
        className={props.selectClassName}
        labelProps={{
          className: 'hidden',
        }}
        containerProps={{
          className: props.className,
        }}
        placeholder={undefined}
        data-cy="data-account-select"
        // value={value}
        selected={() => {
          const acc = props.accounts.find((a) => a.id === props.selectedAccount)
          return (
            <div className="z-10">
              <Typography
                className={`font-bold text-lstnBlueGray-900 text-lg ${props.collapsed}`}
                placeholder={undefined}
              >
                {acc?.name}
              </Typography>
              <Typography
                className={`font-medium text-lstnBlueGray-500 text-md ${props.collapsed}`}
                placeholder={undefined}
              >
                {Object.keys(acc?.users ?? {}).length}{' '}
                {t('createProject.members')}
              </Typography>
            </div>
          )
        }}
      >
        {props.accounts &&
          props.accounts.map((account) => (
            <Option
              key={account.id}
              value={account.id}
              className={`z-10 ${
                account.id === value ? 'bg-lstnBlueGray-50' : ''
              } `}
            >
              <Typography
                className={`font-bold text-lstnBlueGray-900 text-lg ${props.collapsed}`}
                placeholder={undefined}
              >
                {account.name}
              </Typography>
              <Typography
                className={`font-medium text-lstnBlueGray-500 text-md ${props.collapsed}`}
                placeholder={undefined}
              >
                {Object.keys(account.users).length} {t('createProject.members')}
              </Typography>
            </Option>
          ))}
      </Select>
    </div>
  )
}

export default Selector
