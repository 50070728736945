import React from 'react'
import { Switch } from '@material-tailwind/react'
import type { SwitchProps } from '@material-tailwind/react'
import { ThemeProvider } from '@material-tailwind/react'

interface SwitchStylesType {
  switch: {
    defaultProps: {
      color: string
    }
    valid: {
      colors: string[]
    }
    styles: {
      base: {
        container: object
        input: object
      }
      colors: object
    }
  }
}
const theme: SwitchStylesType = {
  switch: {
    defaultProps: {
      color: 'green',
    },
    valid: {
      colors: ['green'],
    },
    styles: {
      base: {
        container: {
          p: 'p-0',
          borderRadius: 'rounded-md',
        },
        input: {
          before: {
            content: "before:content['']",
            width: 'before:w-0',
            height: 'before:h-0',
          },
        },
      },
      colors: {
        green: {
          input: 'checked:bg-lstnGreen-500',
          circle: 'peer-checked:border-lstnGreen-500',
          before: 'peer-checked:before:bg-lstnGreen-500',
        },
      },
    },
  },
}

export function CustomSwitch(props: SwitchProps) {
  return (
    <ThemeProvider value={theme}>
      {/* @ts-ignore */}
      <Switch {...props} />
    </ThemeProvider>
  )
}
