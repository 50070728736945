// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/compat/app'

// These imports load individual services into the firebase namespace.
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'

// This import loads FirbaseUI
// import "./packages/firebase-ui/index"
// This import loads FirebaseUI React Components
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

// Firebase configuration
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
}
const app = firebase.initializeApp(config)

// https://github.com/firebase/firebase-tools/issues/3537
const database = firebase.firestore()
const functions = app.functions('europe-west1')
const functionsV2 = app.functions('us-central1')
// if (process.env.NODE_ENV === 'development') {
//   functions.useFunctionsEmulator(
//     `${process.env.REACT_APP_FIREBASE_HTTP_CLOUD_FUNCTION_URL}`,
//   )
//   functionsV2.useFunctionsEmulator(
//     `${process.env.REACT_APP_FIREBASE_HTTP_CLOUD_FUNCTION_URL}`,
//   )
// }
const storage = firebase.storage()

export { database, functions, storage, functionsV2 }
export default firebase
