import React, { useCallback, useMemo, useState } from 'react'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Card,
  CardBody,
} from '@material-tailwind/react'
import Typography from '../Typography/Typography'
import Selector from '../SelectInput/Select'
import { translationTargetLanguagesDeepL } from '../../utils/translationLanguages'
import { getCurrentPlan } from '../../utils/customHooks'
import { useAppDispatch, useAppSelector } from '../../store/store'
// import { useTranslation } from 'react-i18next'
import UpgradeModal from '../UpgradeModal'
import '../../css/translation.css'
import firebase from 'firebase/compat/app'
import Toast from '../Toast/Toast'
import { useTranslation } from 'react-i18next'
import { fetchTranslationList } from '../../store/features/transcript/helper'
import { customEvent } from '../../utils/customHooks'
import { database } from '../../firebaseApp'

export function TranslateDialog({
  handleOpen,
  open,
  handleTranslationChange,
  fetchParagraphs,
  setHasRun,
}: any) {
  const [translationTargetLanguage, setTranslationTargetLanguage] =
    useState('none')
  const { plans } = useAppSelector((store) => store.plan)
  const { userStatus, userData } = useAppSelector((store) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)
  const [UpgradeOpen, setUpgradeOpen] = useState(false)
  const transcript = useAppSelector((store) => store.paragraphsSlice)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const currentPlanFeatures = useMemo(() => {
    if (plans.length > 0 && userStatus) {
      const currentPlan = getCurrentPlan(plans, userStatus)
      const planFeatures =
        currentPlan &&
        Object.keys(currentPlan?.metadata)
          .filter((key) => /^feature\d+$/.test(key))
          .map((key) => currentPlan.metadata[key])
      return planFeatures
    }
  }, [plans, userStatus])
  const handleTranslationTargetLanguage = useCallback(
    (value: string | undefined) => {
      if (value) {
        setTranslationTargetLanguage(value)
      }
    },
    [],
  )

  const handleUpgrade = (): void => {
    setUpgradeOpen(!UpgradeOpen)
  }
  const handleCreateCollection = async () => {
    const transcriptQuery = await database
      .collection('transcripts')
      .where(
        'projectId',
        '==',
        transcript.projectId ? transcript.projectId : '',
      )
      .where('id', '==', transcript.id ? transcript.id : '')
      .get()

    if (transcriptQuery.empty) {
      throw new Error('transcript document does not exist.')
    }
    try {
      const transcriptRef = transcriptQuery.docs[0].ref

      // @ts-ignore
      const subCollection = transcriptRef.collection(
        `paragraphs-${translationTargetLanguage}`,
      )
      await subCollection.add({})
      dispatch(fetchTranslationList(transcript?.id!))
      handleTranslationChange(`paragraphs-${translationTargetLanguage}`)
    } catch (error) {
      console.log(error, 'err')
    }
  }
  const handleTranslate = async () => {
    try {
      handleOpen()
      Toast({
        title: t('transcriptDetails.translationStarted'),
        text: t('transcriptDetails.translationDetails'),
        variant: 'success',
        options: { autoClose: 5000 },
      })
      handleCreateCollection()
      const token = await firebase.auth().currentUser?.getIdToken()
      const response = await fetch(
        `${process.env.REACT_APP_FIREBASE_API}/transcription/translate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            transcriptId: transcript.id,
            language: translationTargetLanguage,
          }),
        },
      )
      if (response.status == 200) {
        Toast({
          title: t('transcriptDetails.translationSuccessDetails2'),
          variant: 'success',
          options: { autoClose: 10000 },
        })
        setHasRun(true)
        fetchParagraphs(
          transcript.id,
          `paragraphs-${translationTargetLanguage}`,
        )
        customEvent('transcript_translated', {
          transcriptId: transcript.id,
          projectId: transcript.projectId,
          accountId: currentAccount?.id,
          user_userId: userData.id,
          actionSource: transcript.metadata?.languageCodes[0],
          actionDestination: translationTargetLanguage,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <Dialog
        open={open}
        size="xs"
        handler={handleOpen}
        placeholder={undefined}
        className="p-2"
      >
        {currentPlanFeatures?.includes('translation') ? (
          <>
            <DialogHeader placeholder={undefined}>
              <div className="block gap-4">
                <Typography className="text-2xl font-semibold text-blue-gray-900 my-3">
                  {t('translateFeature.title')}
                </Typography>
                <Typography className="text-lg font-normal text-blue-gray-500">
                  {t('translateFeature.description')}
                </Typography>
              </div>
            </DialogHeader>
            <DialogBody placeholder={undefined}>
              <Selector
                selectClassName="border border-blue-gray-100 rounded-lg"
                className="border-none relative text-xs  font-bold w-auto text-blue-gray-500 rounded-lg "
                options={translationTargetLanguagesDeepL}
                selectedValue={translationTargetLanguage}
                onChange={handleTranslationTargetLanguage}
                children={undefined}
                name="companySize"
              />
            </DialogBody>
            <DialogFooter placeholder={undefined}>
              <Button
                placeholder={undefined}
                variant="text"
                onClick={() => handleOpen()}
                className="mr-1 bg-transparent hover:bg-transparent outline-none text-black"
              >
                <span>{t('translateFeature.cancel')}</span>
              </Button>
              <Button
                onClick={() => handleTranslate()}
                className="bg-lstnGreen-500 text-white text-xs font-bold uppercase rounded-lg"
                placeholder={undefined}
                variant="filled"
              >
                {t('translateFeature.title')?.toUpperCase()}
              </Button>
            </DialogFooter>
          </>
        ) : (
          <>
            <DialogHeader placeholder={undefined}>
              <div className="block gap-4">
                <Typography className="text-2xl font-semibold text-blue-gray-900 my-3">
                  {t('translateFeature.title')}
                </Typography>
              </div>
            </DialogHeader>
            <DialogBody placeholder={undefined} className="translateDialog">
              <div>
                <Card className="bg-lstnGreen-50" placeholder={undefined}>
                  <CardBody placeholder={undefined} className="p-4 pb-0">
                    <Typography
                      color="blue-gray"
                      className="mb-2 text-sm font-bold "
                    >
                      {t('translateFeature.upgradeToTranslate')}
                    </Typography>
                    <Typography className="text-sm font-normal text-blue-gray-900">
                      {t('translateFeature.upgradeDescription')}
                    </Typography>
                  </CardBody>
                  <Button
                    placeholder={undefined}
                    className="bg-transparent outline-none hover:bg-transparent px-4 py-3 w-fit shadow-none hover:shadow-none text-lstnGreen-500 text-sm font-bold uppercase"
                    onClick={handleUpgrade}
                  >
                    {t('translateFeature.upgradeNow')}
                  </Button>
                </Card>
              </div>
            </DialogBody>
          </>
        )}
      </Dialog>
      <UpgradeModal UpgradeOpen={UpgradeOpen} handleUpgrade={handleUpgrade} />
    </>
  )
}
