import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
} from '@material-tailwind/react'
import Typography from '../Typography/Typography'

interface IProps {
  initialValue: string
  open: boolean
  onClose: (status: boolean, value?: string) => void
}

export default function ConfirmationDialog({
  initialValue,
  open,
  onClose,
}: IProps) {
  const { t } = useTranslation()
  const [value, setValue] = useState<string>(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleClose = (status: boolean) => () => {
    onClose(status, value)
  }

  const handleText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  return (
    <Dialog
      open={open}
      handler={handleClose(false)}
      placeholder={undefined}
      size="xs"
      className="p-4"
    >
      <DialogHeader
        id="alert-dialog-slide-title"
        className="py-2 px-4 text-2xl font-semibold text-blue-gray-900"
        placeholder={undefined}
      >
        {t('renameDialog.rename')}
      </DialogHeader>
      <DialogBody placeholder={undefined} data-cy="data-project-rename-name">
        <Typography
          variant="h6"
          className="mb-2 text-sm font-medium text-lstnBlueGray-400"
        >
          {t && t('transcripts.title')}
        </Typography>
        <Input
          autoFocus
          size="lg"
          value={value}
          placeholder="Transcript Name"
          className=" !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100"
          labelProps={{
            className: 'hidden',
          }}
          style={{ borderRadius: '8px' }}
          onChange={handleText}
          crossOrigin={undefined}
        />
      </DialogBody>
      <DialogFooter placeholder={undefined} className="gap-0.5">
        <Button
          onClick={handleClose(false)}
          variant="text"
          className="text-xs font-bold uppercase hover:bg-transparent"
          data-cy="data-project-rename-cancel"
          placeholder={undefined}
        >
          {t('renameDialog.cancel')}
        </Button>
        <Button
          onClick={handleClose(true)}
          className="bg-lstnGreen-500 text-white text-xs font-bold uppercase rounded-lg"
          placeholder={undefined}
          variant="filled"
          disabled={!value || value === initialValue}
          data-cy="data-project-rename-submit"
        >
          {t('renameDialog.btnRename')}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
