import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AIIcon, InputSubmitIcon, SparklesIcon } from '../../SvgIcons'
import { Button, Input, Tooltip } from '@material-tailwind/react'
import { useAppSelector } from '../../../store/store'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useAppDispatch } from '../../../store/store'
import {
  createOrUpdateConversation,
  fetchConversations,
} from '../../../store/features/conversationSlice'
import { customEvent } from '../../../utils/customHooks'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Typography } from '@material-tailwind/react'
import UpgradeModal from '../../UpgradeModal'

const AIAssistant: React.FC = () => {
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const transcript = useAppSelector((store) => store.paragraphsSlice)
  const { currentProject } = useAppSelector((store) => store.project)
  const { replies } = useAppSelector((store) => store.conversations)
  const { user, userStatus } = useAppSelector((store) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { plans } = useAppSelector((store) => store.plan)
  const [UpgradeOpen, setUpgradeOpen] = useState(false)
  // console.log('planSpecificPortal', planSpecificPortal)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const scrollRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (transcript) {
      fetchConversations(
        { projectId: currentProject?.id, transcriptId: transcript.id },
        dispatch,
      )
    }
  }, [dispatch, transcript, currentProject])

  const handleSubmit = useCallback(
    async (e: React.FormEvent, text?: string) => {
      e.preventDefault()

      const messageText = input || text
      const data = {
        userQuestion: messageText,
        projectId: currentProject?.id ? currentProject?.id : '',
        transcriptId: transcript.id,
        userId: user.uid,
      }
      if (data.transcriptId) {
        customEvent('ai_bot_message_sent', {
          accountId: currentAccount?.id,
          user_userId: user.uid,
          actionSource: 'from-user',
          actionMetadata: 'quick-action-example-message',
        })
        setInput('')
        setIsLoading(true)
        await createOrUpdateConversation(data)
      } else {
        throw new Error('transcriptId not found')
      }
    },
    [input, transcript, setIsLoading, setInput],
  )

  const handledefaultText = useCallback(
    (e: React.MouseEvent) => {
      const target = e.target as HTMLElement
      handleSubmit(e, target.innerText)
    },
    [handleSubmit],
  )
  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }
  useEffect(() => {
    scrollToBottom()
  }, [replies])

  const currentUserPlan = useMemo(() => {
    if (plans.length > 0 && userStatus) {
      const userPlan = plans.find(
        (plan) => plan?.planID === userStatus?.plan?.product,
      )
      return userPlan
    }
    return null
  }, [plans, userStatus])
  // console.log(currentUserPlan, 'currentUserPlan')
  // console.log(planSpecificPortal, 'planSpecificPortal')
  const handleUpgrade = (): void => {
    setUpgradeOpen(!UpgradeOpen)
  }

  return (
    <div
      style={{
        overflowY: 'auto',
      }} // this is use for overriding the tailwindcss
      className={`h-5/6 ${
        replies.length > 0
          ? 'pb-4 flex flex-col'
          : `items-center justify-center ${
              replies.length == 0 &&
              currentUserPlan &&
              currentUserPlan?.metadata?.hasAiAssistant == 'false'
                ? 'grid'
                : 'flex flex-col'
            }`
      }`}
    >
      <h2
        className={`text-sm text-blue-gray-900 font-sans text-center ${
          replies.length > 0 ? 'p-8' : 'pb-8'
        } `}
      >
        {t('aiAssistant.hi')}, {user?.displayName} !👋
      </h2>
      <div>
        {replies.length > 0 ? (
          <div>
            {replies.map((value, i) => {
              return (
                <div key={i} className="flex flex-col my-2">
                  <p
                    className={`flex my-1 mx-3 p-3 rounded-lg bg-lstnGreen-500 text-white self-end font-sans text-sm font-normal w-fit max-w-[80%]`}
                  >
                    {value?.userQuestion}
                  </p>
                  {value?.aiResponse ? (
                    <p
                      className={`flex my-1 mx-3 p-3 rounded-lg self-start text-blue-gray-900 bg-blue-gray-50 font-sans text-sm font-normal w-fit max-w-[80%]`}
                    >
                      {value?.aiResponse}
                    </p>
                  ) : (
                    i === replies.length - 1 &&
                    isLoading && (
                      <CircularProgress
                        size={18}
                        color="inherit"
                        className="flex self-start ml-3"
                      />
                    )
                  )}
                </div>
              )
            })}
            <div ref={scrollRef}></div>
          </div>
        ) : (
          <>
            <div className="w-full flex flex-col items-center justify-center">
              <AIIcon />
              <p className="w-44 text-center mt-6 font-semibold text-blue-gray-900 text-xs">
                {t('aiAssistant.aboutTranscript')}
              </p>
              <p className="w-60 text-center text-xs text-blue-gray-500 mt-1">
                {t('aiAssistant.askAIassistantExample')}
              </p>
              {currentUserPlan &&
              currentUserPlan.metadata.hasAiAssistant == 'false' ? (
                <>
                  <Tooltip
                    content="This feature is not available in your LSTN plan"
                    className="bg-blue-gray-300 text-md px-4 py-2 shadow-xl shadow-black/10 rounded-lg"
                  >
                    <p
                      className="flex gap-2 text-lstnGreen-500 text-sm font-medium mt-3 cursor-pointer"
                      aria-disabled
                    >
                      <SparklesIcon strokeColor="#25DA3C" />{' '}
                      {t('aiAssistant.topics')}
                    </p>
                  </Tooltip>
                  <Tooltip
                    content="This feature is not available in your LSTN plan"
                    className="bg-blue-gray-300 text-md px-4 py-2 shadow-xl shadow-black/10 rounded-lg"
                  >
                    <p className="flex gap-2 text-lstnGreen-500 text-sm font-medium mt-3 cursor-pointer">
                      <SparklesIcon strokeColor="#25DA3C" />{' '}
                      {t('aiAssistant.summarize')}
                    </p>
                  </Tooltip>
                  <Tooltip
                    content="This feature is not available in your LSTN plan"
                    className="bg-blue-gray-300 text-md px-4 py-2 shadow-xl shadow-black/10 rounded-lg"
                  >
                    <p className="flex gap-2 text-lstnGreen-500 text-sm font-medium mt-3 cursor-pointer">
                      <SparklesIcon strokeColor="#25DA3C" />{' '}
                      {t('aiAssistant.characterAThink')}
                    </p>
                  </Tooltip>
                </>
              ) : (
                <>
                  <p
                    className="flex gap-2 text-lstnGreen-500 text-sm font-medium mt-3 cursor-pointer"
                    onClick={handledefaultText}
                  >
                    <SparklesIcon strokeColor="#25DA3C" />{' '}
                    {t('aiAssistant.topics')}
                  </p>
                  <p
                    className="flex gap-2 text-lstnGreen-500 text-sm font-medium mt-3 cursor-pointer"
                    onClick={handledefaultText}
                  >
                    <SparklesIcon strokeColor="#25DA3C" />{' '}
                    {t('aiAssistant.summarize')}
                  </p>
                  <p
                    className="flex gap-2 text-lstnGreen-500 text-sm font-medium mt-3 cursor-pointer"
                    onClick={handledefaultText}
                  >
                    <SparklesIcon strokeColor="#25DA3C" />{' '}
                    {t('aiAssistant.characterAThink')}
                  </p>
                </>
              )}
            </div>
          </>
        )}
      </div>
      {replies.length == 0 &&
      currentUserPlan &&
      currentUserPlan?.metadata?.hasAiAssistant == 'false' ? (
        <div>
          <Card className="my-6 mx-4 bg-lstnGreen-50" placeholder={undefined}>
            <CardBody placeholder={undefined} className="p-4 pb-0">
              <Typography
                variant="h5"
                color="blue-gray"
                className="mb-2 text-base "
                placeholder={undefined}
              >
                AI Assistant
              </Typography>
              <Typography
                placeholder={undefined}
                className="text-base font-normal text-blue-gray-500"
              >
                {t('aiAssistant.featureAvailable')}
              </Typography>
            </CardBody>
            <Button
              placeholder={undefined}
              className="bg-transparent outline-none hover:bg-transparent px-4 py-3 w-fit shadow-none hover:shadow-none text-lstnGreen-500 text-sm font-bold uppercase"
              onClick={handleUpgrade}
            >
              {t('modal.upgrade')}
            </Button>
          </Card>
        </div>
      ) : (
        ''
      )}
      <div className="px-4 py-2 pb-4 w-full absolute bottom-0 bg-white">
        {currentUserPlan &&
        currentUserPlan.metadata.hasAiAssistant == 'false' ? (
          <Tooltip
            content="This feature is not available in your LSTN plan"
            className="bg-blue-gray-300 text-md px-4 py-2 shadow-xl shadow-black/10 rounded-lg"
            animate={{
              mount: { scale: 1, y: 0 },
              unmount: { scale: 0, y: 50 },
            }}
          >
            <Input
              placeholder="Your Message"
              className=" !border-blue-gray-100 focus:!border-gray-300 placeholder:text-blue-gray-800 placeholder:opacity-100"
              crossOrigin={undefined}
              labelProps={{
                className: 'hidden m-0 p-0',
              }}
              containerProps={{
                className: 'max-h-[100px] w-full',
              }}
              onChange={(e) => setInput(e.target.value)}
              value={input}
              variant="outlined"
              disabled={true}
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  handleSubmit(event)
                }
              }}
              icon={
                <Button
                  variant="text"
                  className="hover:bg-transparent p-0"
                  onClick={handleSubmit}
                  placeholder={undefined}
                >
                  <InputSubmitIcon />
                </Button>
              }
            />
          </Tooltip>
        ) : (
          <Input
            placeholder="Your Message"
            className=" !border-blue-gray-100 focus:!border-gray-300 placeholder:text-blue-gray-800 placeholder:opacity-100"
            crossOrigin={undefined}
            labelProps={{
              className: 'hidden m-0 p-0',
            }}
            containerProps={{
              className: 'max-h-[100px] w-full',
            }}
            onChange={(e) => setInput(e.target.value)}
            value={input}
            variant="outlined"
            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                event.preventDefault()
                handleSubmit(event)
              }
            }}
            icon={
              <Button
                variant="text"
                className="hover:bg-transparent p-0"
                onClick={handleSubmit}
                placeholder={undefined}
              >
                <InputSubmitIcon />
              </Button>
            }
          />
        )}
      </div>
      <UpgradeModal UpgradeOpen={UpgradeOpen} handleUpgrade={handleUpgrade} />
    </div>
  )
}

export default AIAssistant
