import React from 'react'

import DialogContentText from '@material-ui/core/DialogContentText'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogHeader, DialogBody } from '@material-tailwind/react'

import Typography from '../Typography/Typography'
import '../../css/TranscriptDetails.css'

interface IProps {
  open: boolean
  handleOpen: () => void
}

export default function ShortcutDialog({ open, handleOpen }: IProps) {
  const { t } = useTranslation()

  return (
    <div>
      <Dialog
        open={open}
        handler={handleOpen}
        aria-labelledby="form-dialog-title"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
        placeholder={undefined}
      >
        <DialogHeader
          id="form-dialog-title"
          placeholder={undefined}
          className="flex justify-between items-center pt-4 pb-2 px-6"
        >
          <Typography className="mb-0" variant="h5">
            {t('shortcutDialog.shortcut')}
          </Typography>
        </DialogHeader>
        <DialogBody placeholder={undefined} className="pt-0 px-6 pb-4 text-lg">
          <ul>
            <li>
              <DialogContentText id="shortcut-dialog-undo">
                {t('shortcutDialog.undo')} :{' '}
                <span className="list-item-shortcut text-lstnBlueGray-500">
                  {' '}
                  Ctrl/Cmd + Z{' '}
                </span>
              </DialogContentText>
            </li>
            <li>
              <DialogContentText id="shortcut-dialog-redo">
                {t('shortcutDialog.redo')} :{' '}
                <span className="list-item-shortcut text-lstnBlueGray-500">
                  {' '}
                  Ctrl/Cmd + Shift + Z{' '}
                </span>
              </DialogContentText>
            </li>
            <li>
              <DialogContentText id="shortcut-dialog-strike">
                {t('shortcutDialog.strike')} :{' '}
                <span className="list-item-shortcut text-lstnBlueGray-500">
                  {' '}
                  Ctrl/Cmd + J{' '}
                </span>
              </DialogContentText>
            </li>
            <li>
              <DialogContentText id="shortcut-dialog-play">
                {t('shortcutDialog.play')} :{' '}
                <span className="list-item-shortcut text-lstnBlueGray-500">
                  {' '}
                  Tab{' '}
                </span>
              </DialogContentText>
            </li>
            <li>
              <DialogContentText id="shortcut-dialog-multiple-words">
                {t('shortcutDialog.multipleWords')} :{' '}
                <span className="list-item-shortcut text-lstnBlueGray-500">
                  {' '}
                  Shift + Arrow Key{' '}
                </span>
              </DialogContentText>
            </li>
            <li>
              <DialogContentText id="shortcut-dialog-new-paragraph">
                {t('shortcutDialog.newParagraph')} :{' '}
                <span className="list-item-shortcut text-lstnBlueGray-500">
                  {' '}
                  Enter{' '}
                </span>
              </DialogContentText>
            </li>
            <li>
              <DialogContentText id="shortcut-dialog-next-page">
                {t('shortcutDialog.nextPage')} :
                <span className="list-item-shortcut text-lstnBlueGray-500">
                  {' '}
                  Ctrl/Cmd + Right Arrow{' '}
                </span>
              </DialogContentText>
            </li>
            <li>
              <DialogContentText id="shortcut-dialog-previous-page">
                {t('shortcutDialog.previousPage')} :
                <span className="list-item-shortcut text-lstnBlueGray-500">
                  {' '}
                  Ctrl/Cmd + Left Arrow{' '}
                </span>
              </DialogContentText>
            </li>
            <li>
              <DialogContentText id="shortcut-dialog-highlight-word">
                {t('shortcutDialog.highlightWord')} :
                <span className="list-item-shortcut text-lstnBlueGray-500">
                  {' '}
                  Ctrl/Cmd + H
                </span>
              </DialogContentText>
            </li>
          </ul>
        </DialogBody>
      </Dialog>
    </div>
  )
}
