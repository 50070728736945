import React from 'react'
import { ToastOptions, toast } from 'react-toastify'
import './Toast.css'

interface IProps {
  title?: string
  text?: string
  variant?: 'success' | 'error' | 'warn' | 'info'
  options?: ToastOptions
}

export default ({ title, text, variant, options }: IProps) => {
  const Content = () => (
    <div className="toast-Container">
      <div className="text-base font-semibold text-white">{title}</div>
      <div className="text-sm font-normal text-white" data-cy="toast-text">
        {text}
      </div>
    </div>
  )

  const renderToast = () => {
    switch (variant) {
      case 'success':
        return toast.success(<Content />, options)
      case 'error':
        return toast.error(<Content />, options)
      case 'warn':
        return toast.warn(<Content />, options)
      case 'info':
        return toast.info(<Content />, options)
      default:
        return toast(<Content />, options)
    }
  }

  return renderToast()
}
