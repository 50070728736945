import React from 'react'
import CardWithLink from './Card'
import Typography from '../Typography/Typography'
import { useTranslation } from 'react-i18next'

const Sidebar = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="pt-10 ps-10">
        {/* @ts-ignore */}
        <Typography variant="h3" className="text-lstnGreen-400">
          LSTN.ai
        </Typography>
      </div>
      <div className="w-full flex justify-center">
        <div className="test_div w-full h-64 lg:h-60 xl:h-64 2xl:h-[21rem] bg-cover bg-no-repeat flex ">
          {/* @ts-ignore */}
          <Typography
            variant="h1"
            className="font-extrabold text-left lg:ps-10 md:pl-6 md:text-3xl lg:text-3xl xl:text-4xl 2xl:text-5xl"
          >
            {t('auth.sidebar.title')}
          </Typography>
        </div>
      </div>
      <div className="bg-bottom bg-cover h-auto bg-no-repeat p-6">
        <CardWithLink />
      </div>
    </>
  )
}

export default Sidebar
