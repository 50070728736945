import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { KeyboardBackspace } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
// import Auth from './Auth'
import { Navbar, Button, Avatar } from '@material-tailwind/react'
import type { NavbarProps } from '@material-tailwind/react'
import type { TypographyProps } from '@material-tailwind/react'
// import { useAppSelector } from '../../src/store/store'
// import { ITranscript } from '../interfaces'
import DialogComponent from './Dialog'
import { MenuDefault } from './Menu'
import Drawer from './Sidebar/Drawer'
import Typography from './Typography/Typography'
import UpgradeModal from './UpgradeModal'
import { useAppDispatch, useAppSelector } from '../store/store'
import { helperPlanData } from '../store/features/planSlice'
import UserProfileAvatar from './UserProfileAvatar'
import { IUser } from '../interfaces'
import AddDialog from './Projects/AddDialog'
import { addMember } from '../store/features/projectSlice'
import CustomTooltip from './Tooltip/Tooltip'
import { customEvent } from '../utils/customHooks'

interface IStateProps {
  isEnterprise?: boolean
  userId?: string
}

const Header = ({
  userId,
}: IStateProps & TypographyProps & NavbarProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [UpgradeOpen, setUpgradeOpen] = useState(false)
  const { userStatus, user, userData } = useAppSelector((store) => store.user)
  const { plans } = useAppSelector((store) => store.plan)
  const { projects, currentProject, roles } = useAppSelector(
    (store) => store.project,
  )
  const { members, currentAccount } = useAppSelector((store) => store.account)
  const [filteredMembers, setFilteredMembers] = useState<IUser[]>([])
  const [projectMemberRole, setProjectMemberRole] = useState<string[]>([])
  const [joinedMembers, setJoinedMembers] = useState<
    Array<{ id: string; email: string; name: string; role: string | undefined }>
  >([])
  const [unFilteredMembers, setUnFilteredMembers] = useState<IUser[]>([])
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const location = useLocation()

  // const userAccount = currentAccount.users
  //   ? currentAccount.users[String(userData.id)]
  //   : null

  const handleOpenAdd = (): void => {
    setOpenAddDialog(!openAddDialog)
  }

  const navigate = useNavigate()
  const currentUserPlan = useMemo(() => {
    if (plans.length > 0 && userStatus) {
      const userPlan = plans.find(
        (plan) => plan?.planID === userStatus?.plan?.product,
      )
      return userPlan
    }
    return null
  }, [plans, userStatus])
  const navToDash = (): void => {
    navigate(-1) // This navigates back
    //history.push('/transcripts')
  }
  // const transcript = useAppSelector((store: { paragraphsSlice: ITranscript }) => store.paragraphsSlice)
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  useEffect(() => {
    helperPlanData({ dispatch })
  }, [])

  useEffect(() => {
    if (
      location.pathname.includes('/project/') &&
      Object.keys(currentProject).length !== 0
    ) {
      const project = projects.find((project) => {
        return project.id === currentProject.id
      })
      const users = members.filter((member) => {
        return project?.users[member.id!]
      })
      const unfilteredUsers = members.filter((member) => {
        return !currentProject.users[member.id!]
      })
      const detailedFilteredMembers = users.map((member) => {
        return {
          id: member.id!,
          email: member.email!,
          name: member.name!,
          role: project?.users[member.id!].role,
        }
      })
      const currentProjectUser = currentProject?.users[user.uid!]
      const memberRole = roles[currentProjectUser?.role]?.['member']

      setFilteredMembers(users)
      setUnFilteredMembers(unfilteredUsers)
      setJoinedMembers(detailedFilteredMembers)
      setProjectMemberRole(memberRole)
    }
  }, [currentProject, location.pathname, members, roles, user, projects])

  const handleAddMember = async (
    projectId: string,
    memberId: string,
    role: string,
  ): Promise<void> => {
    await addMember(projectId, memberId, role)
    customEvent('project_collaborator_invited', {
      accountId: currentAccount.id,
      projectId: projectId,
      userRole: role,
      user_userId: userData.id,
    })
  }

  const handleOpen = (): void => {
    if (!open) {
      customEvent('demo_request_modal_opened', {
        category: 'accounts',
        accountId: currentAccount.id,
        user_userId: userId,
        actionSource: 'navbar-cta',
      })
    }
    setOpen(!open)
  }
  const handleUpgrade = (): void => {
    if (!UpgradeOpen) {
      customEvent('billing_upgrade_modal_opened', {
        category: 'accounts',
        accountId: currentAccount.id,
        user_userId: userId,
        actionSource: 'navbar-cta',
      })
    }
    setUpgradeOpen(!UpgradeOpen)
  }

  const _handleNavigate = useCallback(() => {
    navigate('/transcripts')
  }, [navigate])

  return (
    //     <header className="org-color-dark header-green-lstn">
    //       <svg height="17" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 55.9 20" enableBackground="new 0 0 55.9 20" xmlSpace="preserve">
    //         {/* <g>
    //                   <rect x="0" y="0.4" fill="#FFFFFF" width="6.9" height="19.6" />
    //                   <rect x="19.6" y="0.4" fill="#FFFFFF" width="6.9" height="19.6" />
    //                   <rect x="35.1" y="0.4" fill="#FFFFFF" width="6.9" height="19.6" />
    //                   <ellipse fill="#FFFFFF" cx="30.8" cy="3.9" rx="3.9" ry="3.9" />
    //                   <path
    //                     fill="#FFFFFF"
    //                     d="M50.5,11.1c-0.4-0.7-0.4-1.1,0-1.8l5.4-8.9h-7.5c0,0-4.5,7.4-5.2,8.4c-0.6,1-0.6,1.7,0,2.7
    // 		c0.6,1.1,5.1,8.4,5.1,8.4h7.5C55.9,20,50.6,11.2,50.5,11.1z"
    //                   />
    //                   <path fill="#FFFFFF" d="M15.5,3.5c-0.4-1.8-1.9-3.1-3.8-3.1l0,0H7.3L11.7,20h7.5L15.5,3.5z" />
    //                 </g> */}
    //       </svg>
    //       <h1 className="org-text-l logo">
    //         <button onClick ={navToDash} className="lstn-logo-header"> LSTN {process.env.NODE_ENV === "development" ? "(development)" : ""}</button>
    //         {location.pathname.includes('/transcript/') && (
    //           <button className="org-btn transcript-action-btn header-nav-btn" onClick={navToDash}>
    //             <KeyboardBackspace />
    //             {t('header.back')}
    //           </button>
    //         )}
    //       </h1>
    //  {/* @ts-ignore */}
    //       <Auth isEnterprise={isEnterprise} />
    //     </header>
    <>
      <Navbar
        className="sticky top-0 max-w-full px-4 pl-4 md:px-8 shadow py-2 md:py-4 rounded-none md:shadow-none border-b-0 z-50"
        placeholder={undefined}
      >
        <div className="flex items-center justify-between text-blue-gray-900">
          <div className="flex items-center gap-4">
            <div className="flex lg:hidden">
              <Drawer userId={userId} />
            </div>
            <div
              className={`items-center gap-2 flex cursor-pointer ${
                location.pathname.includes('settings') ||
                location.pathname.includes('account-settings')
                  ? 'flex'
                  : 'lg:hidden'
              }`}
              onClick={_handleNavigate}
            >
              <div>
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="26" height="26" rx="4" fill="#25DA3C" />
                  <g clipPath="url(#clip0_2947_5033)">
                    <path
                      d="M5.4501 13.2699C4.81234 12.8668 4.86208 11.9428 5.53964 11.6067L16.5574 6.14242C17.2349 5.80637 18.0321 6.3103 17.9923 7.04947L17.3452 19.0692C17.3054 19.8084 16.4584 20.2284 15.8207 19.8253L5.4501 13.2699Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2947_5033">
                      <rect
                        width="13"
                        height="14"
                        fill="white"
                        transform="translate(5 6)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <Typography className="text-blue-gray-900 text-base font-bold">
                LSTN
              </Typography>
            </div>
            {location.pathname.includes('/transcript/') ||
            location.pathname.includes('/project/folder/') ? (
              <Button
                variant="outlined"
                size="sm"
                className="uppercase font-bold flex items-center h-8"
                onClick={navToDash}
                placeholder={'Back'}
              >
                <span className="mr-1">
                  <KeyboardBackspace />
                </span>
                {t('header.back')}
              </Button>
            ) : (
              ''
            )}
            {location.pathname.includes('/transcript') ? (
              <Typography
                onClick={() => navigate('/transcripts')}
                className="mr-4 py-1.5 text-base font-bold text-blue-gray-900 hidden lg:block hover:text-blue-gray-900 hover:cursor-pointer"
              >
                {t('header.personalSpace')}
              </Typography>
            ) : location.pathname.includes('/projects') ? (
              <Typography className="mr-4 py-1.5 text-base font-bold text-blue-gray-900 hidden lg:block hover:text-blue-gray-900 ">
                {t('header.allProjects')}
              </Typography>
            ) : location.pathname.includes('/project/') ? (
              <>
                <Typography className="mr-4 py-1.5 text-base font-bold text-blue-gray-900 hidden lg:block hover:text-blue-gray-900 ">
                  {t('header.project')}: {currentProject.name}
                </Typography>
                <div className="flex">
                  <div className="flex items-center -space-x-4">
                    {filteredMembers.map((member) => {
                      if (member.profilePicture) {
                        return (
                          <CustomTooltip
                            key={member.id}
                            placement="bottom"
                            content={
                              <div className="items-center justify-center flex flex-col">
                                <Typography
                                  color="white"
                                  className="font-medium"
                                >
                                  {member.name}
                                </Typography>
                                <Typography
                                  variant="small"
                                  color="white"
                                  className="font-normal opacity-80"
                                >
                                  {member.email}
                                </Typography>
                              </div>
                            }
                            className="bg-lstnBlueGray-400"
                          >
                            <Avatar
                              key={member.id}
                              placeholder={undefined}
                              size="sm"
                              variant="circular"
                              alt={member.name}
                              className="ring-2 ring-white hover:z-10 focus:z-10"
                              src={member.profilePicture}
                            />
                          </CustomTooltip>
                        )
                      } else {
                        return (
                          <CustomTooltip
                            key={member.id}
                            placement="bottom"
                            content={
                              <div className="items-center justify-center flex flex-col">
                                <Typography
                                  color="white"
                                  className="font-medium"
                                >
                                  {member.name}
                                </Typography>
                                <Typography
                                  variant="small"
                                  color="white"
                                  className="font-normal opacity-80"
                                >
                                  {member.email}
                                </Typography>
                              </div>
                            }
                            className="bg-lstnBlueGray-400"
                          >
                            <div
                              key={member.id}
                              className="ring-2 ring-white hover:z-10 focus:z-10 rounded-full"
                            >
                              <UserProfileAvatar
                                key={member.id}
                                userName={member.name!}
                                avatarUrl={member.profilePicture}
                                width="36px"
                                height="36px"
                              />
                            </div>
                          </CustomTooltip>
                        )
                      }
                    })}
                  </div>
                  {projectMemberRole?.includes('add') && (
                    <Typography
                      className="text-sm font-medium text-lstnBlue-500 cursor-pointer text-lstnBlueGray-900 flex items-center justify-start"
                      onClick={(event) => {
                        event.stopPropagation()
                        handleOpenAdd()
                      }}
                    >
                      <div className="inline-block justify-center ml-5 bg-lstnBlueGray-500 border-lstnBlueGray-500 text-white w-3 h-3 p-0 rounded-full text-center relative mr-1">
                        <svg width="12" height="12" aria-hidden="true">
                          <use xlinkHref="#icon-pluss" />
                        </svg>
                      </div>
                      {t('header.add')}
                    </Typography>
                  )}
                </div>
              </>
            ) : null}
          </div>
          {location.pathname.includes('/transcript') ||
          location.pathname.includes('/settings') ||
          location.pathname.includes('/project') ||
          location.pathname.includes('/account-settings') ? (
            <div className="space-x-6 flex items-center">
              {/* {transcript && Object.keys(transcript).length > 0 && ( */}
              {userStatus &&
              currentUserPlan?.stripe_metadata_isFreePlan === 'true' ? (
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={handleUpgrade}
                  className="text-lstnGreen-500 border-lstnGreen-500"
                  placeholder={'upgrade'}
                >
                  {t('modal.upgrade')}
                </Button>
              ) : (
                ''
              )}
              <Button
                variant="outlined"
                size="sm"
                className="hidden lg:block"
                onClick={handleOpen}
                placeholder={'Book a demo'}
              >
                {t('bookCall')}
              </Button>
              {/* )} */}
              {/* <div>
                <Auth isEnterprise={isEnterprise} />
              </div> */}
              {/* {transcript && Object.keys(transcript).length > 0 && <Avatar size="sm" variant="circular" src="/Avatar.png" alt="tania " />} */}
              <MenuDefault />
            </div>
          ) : (
            ''
          )}
        </div>
      </Navbar>
      <DialogComponent open={open} handleClose={handleOpen} />
      <UpgradeModal UpgradeOpen={UpgradeOpen} handleUpgrade={handleUpgrade} />
      <AddDialog
        open={openAddDialog}
        handleOpen={handleOpenAdd}
        project={currentProject}
        members={unFilteredMembers}
        joinedMembers={joinedMembers}
        addMember={handleAddMember}
        source="header"
        userId={userData.id!}
      />
    </>
  )
}

export default Header
