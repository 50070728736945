// Reason of this context is to make Popup model open state global because of remounting of MUI table
import React, { createContext, useState } from 'react'
import { IUserEmails } from '../components/SharedList/PopupContainer'

interface RolesContext {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  emails: IUserEmails[]
  setEmails: React.Dispatch<React.SetStateAction<IUserEmails[]>>
  selectedRole: string
  setSelectedRole: React.Dispatch<React.SetStateAction<string>>
  createTranscript: string[]
  setCreateTranscript: React.Dispatch<React.SetStateAction<string[]>>
  loader: boolean
  setLoader: React.Dispatch<React.SetStateAction<boolean>>
  emailValue: { [key: string]: string }
  setEmailValue: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>
}
export const RolesContext = createContext<RolesContext>({
  isOpen: false,
  setIsOpen: () => {},
  emails: [],
  setEmails: () => {},
  selectedRole: 'sharedEdit',
  setSelectedRole: () => {},
  createTranscript: ['*'],
  setCreateTranscript: () => {},
  loader: false,
  setLoader: () => {},
  emailValue: {},
  setEmailValue: () => {},
})

interface Iprops {
  children: React.ReactNode
  // other props...
}

const RolesProvider: React.FC<Iprops> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [emails, setEmails] = useState<IUserEmails[]>([])
  const [createTranscript, setCreateTranscript] = useState<string[]>(['*'])
  const [selectedRole, setSelectedRole] = useState<string>('sharedEdit')
  const [loader, setLoader] = useState(false)
  const [emailValue, setEmailValue] = useState({})

  return (
    <RolesContext.Provider
      value={{
        isOpen,
        setIsOpen,
        emails,
        setEmails,
        selectedRole,
        setSelectedRole,
        createTranscript,
        setCreateTranscript,
        loader,
        setLoader,
        emailValue,
        setEmailValue,
      }}
    >
      {props.children}
    </RolesContext.Provider>
  )
}

export default RolesProvider
